import {Component, Input} from '@angular/core';
import {
  DeclarationStatus,
  DeclarationType,
  HealthDeclarationStatus,
  MessageStatus,
  PortOfCall,
  Visit
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitContext} from '../visit-context';
import {AppContext} from '../../app-context';
import lodash from "lodash";

@Component({
  selector: 'app-visit-status',
  templateUrl: './visit-status.component.html',
  styleUrls: ['./visit-status.component.scss']
})
export class VisitStatusComponent {
  PAX = DeclarationType.PAX;
  MSV = DeclarationType.MSV;

  context = VisitContext;
  appContext = AppContext;

  @Input()
  mobile: boolean;

  get useNewCargoScreen(): boolean {
    const cargoDeclarantShortName = this.appContext.userProfile.organisation.shortName;
    const importDeclaration = VisitContext.visit.importDeclarations
      .find(d => d.cargoDeclarant.shortName === cargoDeclarantShortName);
    return VisitContext.cargoDeclarantUsesNewCargoScreen(cargoDeclarantShortName)
      && (!importDeclaration || (importDeclaration.clearedManifestIds?.length && !importDeclaration.declarations?.length));
  }

  get visit(): Visit {
    return this.context.savedVisit;
  }

  getStatus(type: DeclarationType): DeclarationStatus {
    return this.context.declarationStatusOf(type);
  }

  getSecurityStatus(): DeclarationStatus {
    if (this.context.isVlissingenOrTerneuzenVisit()) {
      return this.context.savedVisit.securityDeclaration?.securityReportRequired ? DeclarationStatus.ACCEPTED : null;
    }
    return this.context.declarationStatusOf(DeclarationType.SECURITY)
  }

  getHamisHealthStatus(): HealthDeclarationStatus {
    if (this.context.visit.healthDeclarationSummary) {
      return this.context.visit.healthDeclarationSummary.healthDeclarationStatus
    }
    return null;
  }

  getVisitStatus(): DeclarationStatus {
    if (!this.context.visit.portOfCall.paDeclarationRequired) {
      return this.context.findLatestDeclaration(DeclarationType.WPCS) ? DeclarationStatus.ACCEPTED : null;
    }
    const visitDeclarationStatus = this.context.declarationStatusOf(DeclarationType.VISIT);
    const declarationTypes = [DeclarationType.VISIT, DeclarationType.NOD, DeclarationType.NOA];
    const isAnyDeclarationRejected = lodash.sortBy((this.context.latestDeclarations || [])
      .filter(d => declarationTypes.includes(d.type)), d => d.timeStamp)
      .some(d => d.status === DeclarationStatus.REJECTED);
    return isAnyDeclarationRejected && visitDeclarationStatus !== DeclarationStatus.DECLARED
      ? DeclarationStatus.REJECTED : visitDeclarationStatus;
  }

  getLeastTerminalMessageStatus(): MessageStatus {
    return this.context.visit.visitDeclaration.portVisit.berthVisits
      .map(bv => VisitContext.getTerminalDeclarationStatus(bv))
      .reduce((a, b) =>
        VisitContext.messageStatusOrdered.indexOf(a) > VisitContext.messageStatusOrdered.indexOf(b) ? a : b, null)
  }

  getAuthoriseStatus() {
    if (this.visit) {
      return this.visit.cargoDeclarants && this.visit.cargoDeclarants.length > 0 ? 'ACCEPTED' : null;
    }
  }

  getFinancialDeclarantStatus() {
    if (this.visit) {
      return this.visit.nominatedFinancialDeclarant ? 'DECLARED' : 'ACCEPTED'
    }
  }

  getDangerousGoodsLink(): string {
    if (VisitContext.isOrganisationOnlyCargoDeclarant()) {
      return '/details/' + this.visit.crn + '/dangerousGoods/' + AppContext.userProfile.organisation?.shortName;
    }
    return '/details/' + this.visit.crn + '/dangerousGoods';
  }

  displayNotificationOfDeparture() {
    if (this.visit) {
      const berthVisits = this.visit.visitDeclaration.portVisit.berthVisits;
      return berthVisits.length >= 1 && !!berthVisits[berthVisits.length - 1].atd;
    }
  }

  portOfCallAllowsSecurity(portOfCall: PortOfCall) {
    return portOfCall.paDeclarationRequired || this.context.isVlissingenOrTerneuzenVisit();
  }
}
