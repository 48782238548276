<ng-container *ngIf="localMovement && (showHeliQuestions || showLoaQuestions)">
  <div class="fieldset" *ngIf="localMovement">
    <ng-container *ngIf="showHeliQuestions">
      <app-form-group>
        <app-info class="formGroupLabel">
          <label [attr.for]="id + '-heliSuitable'">Suitable for helicopter</label>
          <span class="tooltipContent">
              <p>Suitable for ships from 125 meters, between 125 and 150 meters only in daylight.</p>
              <p>Always ask the captain whether the ship is suitable for receiving the pilot by helicopter.</p>
          </span>
        </app-info>
        <app-yes-no [(ngModel)]="localMovement.stormPilotageInformation.heliSuitable" [required]="required"
          [id]="id + '-heliSuitable'"></app-yes-no>
        <div class="invalid-feedback">Please select yes or no</div>
      </app-form-group>
      <ng-container *ngIf="localMovement.stormPilotageInformation.heliSuitable && firstMovement">
        <app-form-group>
          <label [attr.for]="id + '-hoisting'">Select boarding</label>
          <app-yes-no [(ngModel)]="localMovement.stormPilotageInformation.hoisting" [formatter]="hoistingOrLanding"
            [required]="required" [id]="id + '-hoisting'"></app-yes-no>
          <div class="invalid-feedback">Please select boarding</div>
        </app-form-group>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showLoaQuestions">
      <app-form-group>
        <app-info class="formGroupLabel">
        <label [attr.for]="id + '-remotePilotage'">Captain agrees with shore based pilotage</label>
        <span class="tooltipContent">
              <p>Always ask the captain whether he agrees to remote pilotage via VHF.</p>
              <p *ngIf="isTanker">For tankers, remote pilotage depends on the cargo.</p>
          </span>
        </app-info>
        <app-yes-no [(ngModel)]="localMovement.stormPilotageInformation.remotePilotage" [required]="isLoaRequired"
          [id]="id + '-remotePilotage'"></app-yes-no>
        <div class="invalid-feedback">Please select yes or no</div>
      </app-form-group>
      <div *ngIf="falseNotAllowedForFirstRemotePilotage">
        <app-status-alert type="danger" >Please note that during the storm pilotage, it is required to make use of the shore based pilotage.
          If the captain doesn’t accept the shore based pilotage, it is not allowed to enter the Port of Rotterdam.</app-status-alert>
      </div>
      <div *ngIf="falseNotAllowedForLastRemotePilotage">
        <app-status-alert type="danger" >Please note that during the storm pilotage, it is required to make use of the shore based pilotage.
          If the captain doesn’t accept the shore based pilotage, it is not allowed to exit the Port of Rotterdam.</app-status-alert>
      </div>
    </ng-container>
  </div>
</ng-container>
