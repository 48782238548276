import {exportDataAsExcel} from "../../common/upload/excel.utils";
import {IE3ConsignmentProcess, Visit} from "@portbase/bezoekschip-service-typescriptmodels";
import {lodash} from "../../common/utils";
import {formatTimestamp} from "../../common/date/time-utils";
import {ConsignmentUtils} from "./consignment.utils";

export class ConsignmentDownloadUtils {

  static downloadMrns(crn: string, consignments: IE3ConsignmentProcess[]) {
    const data = [["Port of discharge", "Port of loading", "B/L number", "MRN number"]];
    consignments
      .filter(c => c.consignmentMasterLevel?.placeOfLoading
        && c.consignmentMasterLevel?.placeOfUnloading)
      .forEach(c => {
        data.push([
          c.consignmentMasterLevel.placeOfUnloading.locationUnCode,
          c.consignmentMasterLevel.placeOfLoading.locationUnCode,
          c.consignmentMasterLevel.consignmentNumber,
          c.ensMrn || ''
        ]);
      });
    exportDataAsExcel(data, `${crn}_movementReferenceNumbers.xlsx`);
  }

  static downloadClearanceDifferences(visit: Visit, consignments: IE3ConsignmentProcess[]) {
    const data =
      [["Vessel Call", "Vessel", "Voyage nr", "ATA-date", "ATA-time", "POL", "POD", "B/L nr", "PCS colli",
        "PCS weight (KGM)", "Remaining colli", "Remaining weight (KGM)", "Differences report date"]];

    const berthVisits = lodash.keyBy(visit.visitDeclaration.portVisit.berthVisits, v => v.berth.terminalCode);

    consignments.forEach(consignment => {
      const terminal = consignment.consignmentMasterLevel?.dischargeTerminal;
      const placeOfLoading = consignment.consignmentMasterLevel?.placeOfLoading;
      const placeOfUnloading = consignment.consignmentMasterLevel?.placeOfUnloading;
      const berthVisit = berthVisits[terminal.terminalCode];
      const goodsItems = ConsignmentUtils.getGoodsOfConsignmentProcess(consignment);
      const differenceSummary = consignment.status.clearanceStatus.expirySummary;
      const row = [visit.crn, visit.vessel.name,
        visit.visitDeclaration.arrivalVoyage.voyageNumber,
        formatTimestamp(berthVisit && berthVisit.ata, 'D-M-YYYY'),
        formatTimestamp(berthVisit && berthVisit.ata, 'HH:mm'),
        placeOfLoading.locationUnCode, placeOfUnloading.locationUnCode,
        consignment.consignmentMasterLevel.consignmentNumber,
        lodash.sumBy(goodsItems, g => Number(g.goodsItem.outerPackaging.numberOfPackages || 0)),
        lodash.sumBy(goodsItems, g => Number(g.goodsItem.grossWeight || 0)),
        lodash.sumBy(differenceSummary.items, d => Number(d.remainingPackages || 0)),
        lodash.sumBy(differenceSummary.items, d => Number(d.remainingWeight || 0)),
        formatTimestamp(differenceSummary.notificationTime, 'D-M-YYYY'),
      ];
      data.push(row);
    });

    exportDataAsExcel(data, `${visit.crn}_clearanceDifferences.xlsx`);
  }
}
