import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Country} from "@portbase/bezoekschip-service-typescriptmodels";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {Observable, of} from "rxjs";
import {sendQuery} from "../utils";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-country-field',
  templateUrl: './country-field.component.html',
  styleUrls: ['./country-field.component.scss']
})
export class CountryFieldComponent implements OnInit {
  private static allCountries: Map<string, Country>;
  portVisitUtils = PortvisitUtils;

  @Input() required: boolean;
  @Input() includeMargin: boolean = true;
  @Input() renderOptional: boolean;
  @Input() editMode: boolean;

  @Output() countryCodeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() countryChange: EventEmitter<Country> = new EventEmitter<Country>();

  countries: Map<string, Country>;
  useCountryObject: boolean = false;

  countryCode: string;
  country: Country;

  @Input("countryCode")
  set setCountryCode(countryCode: string) {
    this.countryCode = countryCode;
    this.useCountryObject = false;
  }

  @Input("country")
  set setCountry(country: Country) {
    this.country = country;
    this.useCountryObject = true;
  }

  countryFormatter = (value: Country | string) => typeof value === "string"
    ? this.countries?.get(value) ? this.countryFormatter(this.countries.get(value)) : value
    : ((value.name && value.code) ? `${value.name} - ${value.code}` : '');

  ngOnInit(): void {
    CountryFieldComponent.getCountries().subscribe(c => this.countries = c);
  }

  onCountryChange = (country: Country) => {
    this.countryCode = country?.code;
    this.countryCodeChange.emit(this.countryCode);
    this.countryChange.emit(country);
  }

  static getCountries = (): Observable<Map<string, Country>> => CountryFieldComponent.allCountries
    ? of(CountryFieldComponent.allCountries)
    : sendQuery("com.portbase.bezoekschip.common.api.visit.GetCountries", {})
      .pipe(map((c: Country[]) => {
        CountryFieldComponent.allCountries = c.reduce((map, country) => {
          if (country.code) {
            map.set(country.code, country);
          }
          return map;
        }, new Map<string, Country>());
        return CountryFieldComponent.allCountries;
      }));
}
