<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="row narrow-gutters always-enabled mt-3 mb-0">
  <div class="col-auto ms-4 px-2 align-items-center d-flex mb-4">
    <input type="checkbox" class="form-check-input me-1" id="showVerified" [(ngModel)]="showVerified"
           (ngModelChange)="renderFilteredItems()">
    <label for="showVerified">Show completed</label>
  </div>
  <div class="col-auto px-2">
    <input class="form-control" [(ngModel)]="countryFilter" (ngModelChange)="renderFilteredItems()"
           placeholder="Filter by country code">
  </div>
  <div class="col">
    <app-filter class="mb-4" (resultsFound)="renderFilteredItems()" [(ngModel)]="filterTerm" [searchFunction]="reload"
                placeholder="Filter by name or customer reference, etc..." [appAutoFocus]="true"></app-filter>
  </div>
  <div class="col-auto" *ngIf="appContext.isAdmin()">
    <button (click)="add()" class="btn btn-info" tabindex="-1"><span class="pe-2 fa fa-plus-circle"></span>
      New release-to party
    </button>
  </div>
</div>


<div class="row mb-2">
  <div class="col-auto me-auto">
      <span class="dropdown mx-1 always-enabled">
        <button class="btn btn-info border-0 bg-transparent text-info dropdown-toggle py-2"
                style="min-width: 8rem" type="button" data-bs-toggle="dropdown"><span
          class="fa fa-file-download me-1"></span> Downloads
        </button>
        <ul class="dropdown-menu dropdown-menu-start" style="cursor: pointer">
          <li (click)="downloadReleaseToParties()"
              class="dropdown-item">
            Release to parties
          </li>
        </ul>
      </span>

  </div>
</div>

<ng-container>
  <app-pagination #pagination [items]="values" class="accordion" id="releaseAccordion">
    <ng-container *ngFor="let value of pagination.getItemsOnPage(); let index = index; trackBy: trackBy">

      <app-collapse [collapse]="content" [deleteButton]="(appContext.isCommercialReleaseDeclarant() ||
      appContext.isCommercialReleaseEditor()) && deleteToggle" [showOnLoad]="value.new">
        <div class="row py-2 px-3 align-items-center text-dark">
          <div class="col">
            <ng-container *ngIf="value.new; else existing">
              <span class="me-2"><i class="fa fa-users me-1"></i> New party</span>
            </ng-container>
          </div>
          <ng-template #existing>
            <div class="row">
              <div class="col" *ngIf="appContext.isAdmin()">
                <span title="Carrier organisation" class="me-5"><i class="fa fa-user-group-crown me-1"></i>
                  {{value.ownerFullName}}</span>
              </div>
              <div class="col">
                <span title="Customer organisation" class="me-2"><i class="fa fa-users me-1"></i>
                  {{value.customerData?.fullName}}</span>
              </div>
              <div class="col-auto" style="width: 5rem">
                <span title="Country code" class="me-2">{{value.customerData?.countryUnCode}}</span>
              </div>
              <div class="col-auto" style="width: 15rem">
                <span title="Chamber of commerce number" class="ms-4 me-2">
                  {{value.customerData?.chamberOfCommerceNumber}}</span>
              </div>
              <div class="col-1">
                <span *ngIf="allCompleted(value)" class="rounded-pill badge text-bg-success me-1">completed</span>
              </div>
              <div class="col-3 text-truncate" style="width: 12rem">
                <span title="Customer reference codes" class="ms-4 me-2"><i class="fa fa-passport me-1"></i>
                  {{allReferences(value) | join}}
                </span>
              </div>
            </div>
          </ng-template>
        </div>
      </app-collapse>

      <ng-template #content>
        <div class="collapse">
          <div>
            <div class="ps-2 py-3">
              <div class="position-relative">
                <app-tab-panel>
                  <app-tab-item [templateRef]="details">
                    <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!value['ngDetailsInvalid']}">
                      Details</span>
                    <ng-template #details>
                      <div class="nav position-absolute" style="top: 0; right: 0">
                        <div class="row narrow-gutters">
                          <div class="ms-auto col-auto" *ngIf="value['new']">
                            <button (click)="register(value)" class="btn btn-outline-info py-1">Save party</button>
                          </div>
                        </div>
                      </div>
                      <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="value"
                           validatorProperty="ngDetailsInvalid">
                        <app-release-to-party [model]="value"></app-release-to-party>
                      </div>
                    </ng-template>
                  </app-tab-item>
                </app-tab-panel>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #deleteToggle>
        <app-confirmation-toggle>
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Remove release-to party</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to remove this party?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="remove(value)" data-bs-dismiss="modal">Remove party
              </button>
            </div>
          </div>
        </app-confirmation-toggle>
      </ng-template>

    </ng-container>
  </app-pagination>

  <div *ngIf="values.length === 0" class="text-center text-muted py-2">
    <ng-container *ngIf="values.length > 0; else noValueYet">
      No matching parties found.
    </ng-container>
    <ng-template #noValueYet>No parties have been registered yet.</ng-template>
  </div>
</ng-container>
