import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {VisitContext} from "../../visit-details/visit-context";
import {PortvisitUtils} from "../../refdata/portvisit-utils";
import {
  DeclareTransit,
  MergeTransitTemplate,
  SaveTransit,
  TransitDeclaration,
  TransitTemplate,
  TryCancelTransit
} from '@portbase/bezoekschip-service-typescriptmodels';
import {checkValidity, cloneObject, lodash, sendCommand} from '../../common/utils';
import {AppContext} from '../../app-context';
import {
  addEmptyGoodsItem,
  copyHouseConsignmentsGoodsItemsToMasterGoodsItems,
  formatSendDate,
  initializeTransitData,
  removeEmptyBorderTransportAndSuperfluousMasterGoodsItems
} from "../transit.utils";
import moment from "moment";
import {EventGateway} from '../../common/event-gateway';

@Component({
  selector: 'app-transit-declaration',
  templateUrl: './transit-declaration.component.html',
  styleUrls: ['./transit-declaration.component.css']
})
export class TransitDeclarationComponent implements OnInit, OnChanges {
  context = VisitContext;
  appContext = AppContext;
  refData = PortvisitUtils;

  hasHouseConsignment: boolean;
  @Input() declaration: TransitDeclaration;

  constructor(private element: ElementRef, private eventGateway: EventGateway) {
  }

  ngOnInit() {
    this.hasHouseConsignment = copyHouseConsignmentsGoodsItemsToMasterGoodsItems(this.declaration.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasHouseConsignment = copyHouseConsignmentsGoodsItemsToMasterGoodsItems(this.declaration.data);
  }

  findTransitTemplates = term => this.refData.findTransitTemplates(term, this.declaration.declarantShortName);

  addEmptyGoodsItem() {
    addEmptyGoodsItem(this.declaration);
  };

  getAnyRejectReason() {
    const status = this.declaration.status;
    if (status.phase === 'rejected' && status.reject && status.reject.reason) {
      return status.reject.reason;
    }
    if (status.phase === 'releaseRejected' && status.releaseRejected && status.releaseRejected.reason) {
      return status.releaseRejected.reason;
    }
    if (status.phase === 'insufficientGuarantee' && status.insufficientGuarantee && status.insufficientGuarantee.reason) {
      return status.insufficientGuarantee.reason;
    }
    if (status.phase === 'cancelled' && status.cancellation && status.cancellation.reason) {
      return status.cancellation.reason;
    }
  }

  save() {
    sendCommand('com.portbase.bezoekschip.common.api.transit.SaveTransit', <SaveTransit>{
      declarantShortName: this.declaration.declarantShortName,
      data: removeEmptyBorderTransportAndSuperfluousMasterGoodsItems(this.declaration.data),
      lrn: this.declaration.lrn,
      crn: this.declaration.crn,
      sendOnAta: this.declaration.status.sendOnAta,
      sendDate: formatSendDate(this.declaration.status.sendDate, this.declaration.status.sendOnAta)
    }, () => {
      this.declaration['isNewTransitDeclaration'] = false;
      AppContext.registerSuccess('The declaration was saved successfully.');
    });
  }

  send() {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.transit.DeclareTransit', <DeclareTransit>{
        declarantShortName: this.declaration.declarantShortName,
        data: removeEmptyBorderTransportAndSuperfluousMasterGoodsItems(this.declaration.data),
        lrn: this.declaration.lrn,
        crn: this.declaration.crn,
        sendOnAta: this.declaration.status.sendOnAta,
        sendDate: formatSendDate(this.declaration.status.sendDate, this.declaration.status.sendOnAta)
      }, () => {
        this.declaration['isNewTransitDeclaration'] = false;
        AppContext.registerSuccess('The declaration was sent successfully.');
      });
    }
  }

  cancel(reason: string, form: HTMLElement) {
    if (checkValidity(form)) {
      sendCommand('com.portbase.bezoekschip.common.api.transit.TryCancelTransit',
        <TryCancelTransit>{crn: this.declaration.crn, lrn: this.declaration.lrn, reason: reason},
        () => AppContext.registerSuccess('The declaration was cancelled successfully.'));
      const modal = $('#cancelTransit');
      modal.modal('hide')
    }
  }

  saveTemplate() {
    if (this.declaration.data.templateName) {
      const clonedTransitData = cloneObject(this.declaration.data);

      clonedTransitData.containerNumber = null;
      clonedTransitData.goodsItems = null;
      clonedTransitData.houseConsignments = null;

      sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeTransitTemplate', <MergeTransitTemplate>{
        id: this.declaration.data.templateName,
        value: <TransitTemplate>{
          id: this.declaration.data.templateName,
          name: this.declaration.data.templateName,
          templateData: clonedTransitData,
          ownerShortName: AppContext.userProfile.organisation?.shortName
        },
        delete: false
      }, () => {
        AppContext.registerSuccess('The transit template was saved successfully.');
      })
    } else {
      AppContext.registerError("Please fill out the transit template name.")
    }
  }

  useTemplate = ($event: TransitTemplate) => {
    lodash.assign(this.declaration.data, $event.templateData);
    this.declaration.data = initializeTransitData(this.declaration.data);
    this.declaration.requestedTransportExpiryDate = moment().add(this.declaration.data.transportTerm ? this.declaration.data.transportTerm : 0, 'seconds').toISOString();
  }

  isCancellable = (): boolean => {
    switch (this.declaration.status.phase) {
      case 'inspection':
      case 'releaseRequested':
      case 'insufficientGuarantee':
      case 'rejected':
      case 'releaseRejected':
      case 'declared':
      case 'accepted':
        return this.appContext.isTransitEditor();
    }
    return false;
  };

  isEditable = (): boolean => {
    switch (this.declaration.status.phase) {
      case 'undeclared':
      case 'rejected':
      case 'declared':
        return this.appContext.isTransitEditor();
    }
    return !this.declaration.status.phase;
  };

  createNext() {
    this.eventGateway.publish("createNextTransitDeclaration", this.declaration);
  }
}
