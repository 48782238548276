import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  ApiCustomer,
  CustomerReference,
  CustomerReferenceStatus,
  DateTimeRange,
  FindCustomers,
  RegisterCustomer,
  RemoveCustomer
} from '@portbase/bezoekschip-service-typescriptmodels';
import {filterByTerm, removeItem, sendCommand, sendQuery} from '../common/utils';
import {AppContext} from '../app-context';
import {AbstractOverviewComponent} from '../common/abstract-overview.component';
import {PortvisitUtils} from '../refdata/portvisit-utils';
import {Observable} from 'rxjs';
import {ComparatorChain} from "../common/comparator-chain";
import lodash from "lodash";
import {exportDataAsExcel} from "../common/upload/excel.utils";

@Component({
  selector: 'app-release-to-party-overview',
  templateUrl: './release-to-party-overview.component.html',
  styleUrls: ['./release-to-party-overview.component.css']
})
export class ReleaseToPartyOverviewComponent extends AbstractOverviewComponent<ApiCustomer> implements OnInit, OnDestroy {
  appContext = AppContext;
  refData = PortvisitUtils;
  values: ApiCustomer[] = [];
  showVerified: boolean = true;
  countryFilter: string = "";

  doLoad(dateTimeRange: DateTimeRange): Observable<ApiCustomer[]> {
    return sendQuery(
        'com.portbase.bezoekschip.common.api.commercialrelease.FindCustomers',
        <FindCustomers> {
          term: this.filterTerm,
          maxSize: 10000
        },
        {
          caching: false,
          showSpinner: true
        }
      );
  }

  public override filterItems() {
    return this.items.filter(filterByTerm(this.filterTerm, this.excludedFilterFields))
      .filter(value => (!this.showVerified && lodash.values(value.references).some(ref => ref.status === "NEW"))
        || (this.showVerified && lodash.values(value.references)))
      .filter(value => !this.countryFilter || this.countryFilter.toUpperCase() === value.customerData?.countryUnCode)
      .sort(new ComparatorChain('ownerFullName', 'customerData.fullName').compare);
  }

  doRender(entries: ApiCustomer[]) {
    this.values = Array.from(this.filterItems());
  }

  remove(value: ApiCustomer) {
    const removeLocal = () => {
      removeItem(this.values, value);
      removeItem(this.items, value);
    }

    if (value['new']) {
      removeLocal();
    } else {
      sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.RemoveCustomer', <RemoveCustomer>{
        shortName: AppContext.userProfile.organisation?.shortName,
        customerShortName: value.portbaseId
      }, () => {
        removeLocal();
        AppContext.registerSuccess("Release-to party was removed successfully");
      });
    }
  }

  add = () => {
    const item = <ApiCustomer>{
      new: true, ownerPortbaseId: AppContext.isAdmin() ?
        undefined : AppContext.userProfile.organisation?.shortName
    };
    this.values.splice(0, 0, item);
    this.items.splice(0, 0, item);
  };

  register(value: ApiCustomer) {
    if (!!value['new']) {
      value.references = {};
    }
    lodash.values(value['additions']).map(s => value.references[s] = <CustomerReference>{reference: s, status: "NEW"});
    sendCommand('com.portbase.bezoekschip.common.api.accountmanagement.RegisterCustomer', <RegisterCustomer>{
      shortName: value.ownerPortbaseId,
      customerShortName: value.portbaseId,
      customerReferences: lodash.values(value.references).map(value1 => value1.reference)
    }, () => {
      delete value['new'];
      AppContext.registerSuccess("Release-to party was saved successfully");
    });
  }

  trackBy(index: number, obj: ApiCustomer): any {
    return obj['new'] ? obj : obj.ownerPortbaseId + obj.portbaseId;
  }

  allCompleted(value: ApiCustomer): boolean {
    return !lodash.values(value.references).some(customerRef => customerRef.status === "NEW");
  }

  allReferences(value: ApiCustomer): string[] {
    return lodash.values(value.references).map(customerRef => customerRef.reference);
  }

  downloadReleaseToParties() {

    let header = ["Customer organisation", "Address", "ZIP code", "Country",
      "Customer references(verified)", "Customer references(unverified)", "Customer references(rejected)"];

    if (this.appContext.isAdmin()) {
      header.unshift("Carrier organisation");
    }

    const data = [header];

    this.values.forEach(value => {
      const verified = this.filterWithStatus(value, CustomerReferenceStatus.VERIFIED);
      const unverified = this.filterWithStatus(value, CustomerReferenceStatus.NEW);
      const rejected = this.filterWithStatus(value, CustomerReferenceStatus.REJECTED);
      const customerData: string[] = [value.customerData?.fullName, value.customerData?.zipCode,
        value.customerData?.city, value.customerData?.countryName, verified, unverified, rejected];

      if (this.appContext.isAdmin()) {
        customerData.unshift(value.ownerPortbaseId);
      }

      data.push(customerData);
    })

    exportDataAsExcel(data, 'releaseToParties.xlsx');
  }

  filterWithStatus(value: ApiCustomer, status: CustomerReferenceStatus) {
    return lodash.values(value.references)
      .filter(customer => customer.status == status)
      .map(value1 => value1.reference).join(",");
  }
}
