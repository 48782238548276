import {
  AttentionPoint,
  AttentionPointContext,
  BerthVisitSearch,
  CargoAgentStatus,
  Clearance,
  ClearanceStatus,
  DeclarationType,
  TaskMessageStatus,
  VisitSummary
} from "@portbase/bezoekschip-service-typescriptmodels";
import lodash from "lodash";
import {VisitContext} from "../../../visit-details/visit-context";
import {NewVisitSummary} from "./visit-overview-item.component";
import {AppContext} from "../../../app-context";
import {ClearanceService} from "../../../visit-details/visit/clearance/clearance-service";
import {NewPortvisitUtils} from "../../new-portvisit.utils";

export class VisitOverviewUtils {

  static getTaskStatuses = (summary: NewVisitSummary, declarationTypes: DeclarationType[], clearanceService: ClearanceService) =>
    lodash.flatMap(declarationTypes, d => this.declarationTypeToStatus(summary, d, clearanceService)) || []

  static getBunkeringTaskStatuses = (summary: NewVisitSummary, clearanceService: ClearanceService) =>
    [this.defaultMapper(summary, DeclarationType.BUNKERING)];

  static getLeastTaskStatusByDeclarations = (summary: NewVisitSummary, declarationTypes: DeclarationType[], clearanceService: ClearanceService) =>
    this.getLeastTaskMessageStatus(this.getTaskStatuses(summary, declarationTypes, clearanceService))

  static getLeastTerminalStatus = (summary: NewVisitSummary, clearanceService: ClearanceService) => {
    const terminalMessageStatuses = VisitOverviewUtils.sortTaskMessageStatuses(VisitOverviewUtils.getTaskStatuses(summary, [DeclarationType.TERMINAL_PLANNING], clearanceService)).reverse()
      .filter(tms => AppContext.showPortCallOptimization() || tms.taskStatus != TaskMessageStatus.WARNING);  // When not terminal planning, WARNING is only for RTA != ETA; When terminal planning, WARNING is not used
    return this.getLeastTaskMessageStatus(terminalMessageStatuses.concat(this.getCargoHandlingStatus(summary)));
  }

  static getCargoHandlingStatus = (summary: NewVisitSummary): DeclarationMessageStatus => {
    const messageStatus: DeclarationMessageStatus = {
      name: "Cargo handling",
      taskStatus: TaskMessageStatus.DISABLED,
      messages: []
    };
    const cargoAgentStatus = (summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName]);
    const shortLandedContainers: number = AppContext.isAdmin()
      ? Object.values(summary.cargoAgentStatuses).map(a => a.shortlandedContainers)?.reduce((a, b) => a + b, 0)
      : cargoAgentStatus && cargoAgentStatus.shortlandedContainers;
    const overlandedContainers: number = AppContext.isAdmin()
      ? Object.values(summary.cargoAgentStatuses).map(a => a.overlandedContainers)?.reduce((a, b) => a + b, 0)
      : cargoAgentStatus && cargoAgentStatus.overlandedContainers;
    if (shortLandedContainers || overlandedContainers) {
      messageStatus.taskStatus = TaskMessageStatus.ERROR;
      messageStatus.reason = [].concat(shortLandedContainers ? [`${shortLandedContainers} shortlanded container${shortLandedContainers>1 ? "s" : ""}`] : [])
        .concat(overlandedContainers ? [`${overlandedContainers} overlanded container${overlandedContainers>1 ? "s" : ""}`] : []).join(" & ");
      return messageStatus;
    }
    return messageStatus;
  }


  static getLeastTaskMessageStatus = (messageStatuses: DeclarationMessageStatus[]) =>
    VisitOverviewUtils.reduceLeastTaskMessageStatus(messageStatuses.map(m => m.taskStatus))

    static reduceLeastTaskMessageStatus = (statuses: TaskMessageStatus[]) => statuses.reduce((a, b) =>
        VisitContext.taskMessageStatusOrdered.indexOf(a) > VisitContext.taskMessageStatusOrdered.indexOf(b) ? a : b, null) || TaskMessageStatus.DISABLED

  static sortTaskMessageStatuses = (messageStatuses: DeclarationMessageStatus[]) =>
    messageStatuses.sort((a, b) =>
      VisitContext.taskMessageStatusOrdered.indexOf(a.taskStatus) > VisitContext.taskMessageStatusOrdered.indexOf(b.taskStatus) ? 1 : -1);

  static toExtendedSummary = (summary: VisitSummary, clearanceService: ClearanceService): NewVisitSummary => {
    const v: NewVisitSummary = summary;
    v.currentBerthVisit = this.getUpcomingBerthVisit(summary);
    v.lastEditedByUser = summary.lastEditedBy ? summary.lastEditedBy[AppContext.userProfile.organisation?.shortName] : null;
    if (v.lastEditedByUser && !AppContext.isProduction() && !AppContext.isAdmin()) { // Override the username on test environments because multiple customers use the same organisation account
      v.lastEditedByUser.name = v.lastEditedByUser.orgName;
    }
    v.terminalPlanningEnabled = this.getLeastTaskStatusByDeclarations(summary, [DeclarationType.TERMINAL_PLANNING], clearanceService) !== 'DISABLED';
    return v;
  }

  private static getUpcomingBerthVisit = (summary: NewVisitSummary): BerthVisitSearch =>
    summary.berthVisitSearches.find(bv => !bv.ata) || summary.berthVisitSearches[0]

  private static declarationTypeToStatus = (summary: NewVisitSummary, declarationType: DeclarationType, clearanceService: ClearanceService): DeclarationMessageStatus[] => {
    switch (declarationType) {
      case DeclarationType.VISIT:
        return this.clearanceDeclarationTypeToStatus(clearanceService, summary, declarationType, AttentionPointContext.ekh);
      case DeclarationType.SECURITY:
        return this.clearanceDeclarationTypeToStatus(clearanceService, summary, declarationType, AttentionPointContext.sspi);
      case DeclarationType.HEALTH:
        return this.clearanceDeclarationTypeToStatus(clearanceService, summary, declarationType, AttentionPointContext.mdoh);
      case DeclarationType.WASTE:
        return this.clearanceDeclarationTypeToStatus(clearanceService, summary, declarationType, AttentionPointContext.emas);
      case DeclarationType.DANGEROUS_GOODS:
        return this.clearanceDeclarationTypeToStatus(clearanceService, summary, declarationType, AttentionPointContext.emgs);
      case DeclarationType.TERMINAL_PLANNING:
        return this.getTerminalVisitStatuses(summary);
      case DeclarationType.SDT:
        return [this.getCargoDeclarationStatus(summary)]
      default:
        return [this.defaultMapper(summary, declarationType)];
    }
  }

  private static clearanceDeclarationTypeToStatus(clearanceService: ClearanceService, summary: NewVisitSummary, declarationType: DeclarationType, clearanceKey: AttentionPointContext): DeclarationMessageStatus[] {
    const clearance = summary.movementForEkhClearance
      ? clearanceService.getClearance(summary.clearances, clearanceKey, summary.movementForEkhClearance?.from, summary.movementForEkhClearance?.to)
      : null;
    const taskStatus = summary.taskStatuses[declarationType]?.status;
    if (declarationType === DeclarationType.VISIT) {
      if (![TaskMessageStatus.ACCEPTED, TaskMessageStatus.DELIVERED].includes(taskStatus)) {
        return [this.defaultMapper(summary, declarationType)];
      } else if (clearance === null && taskStatus === TaskMessageStatus.DELIVERED) {
        return [{
          name: this.declarationTypeToName(declarationType),
          taskStatus: TaskMessageStatus.ACCEPTED,
          messages: this.getMessagesForDeclarationType(summary, declarationType)
        }]
      }
    }
    return [this.defaultClearanceMapper(clearance, summary, clearanceService, declarationType, clearanceKey)];
  }

  private static defaultClearanceMapper(clearance: Clearance, summary: NewVisitSummary, clearanceService: ClearanceService, declarationType: DeclarationType, clearanceKey: AttentionPointContext) {
    if (clearance) {
      const taskStatus = this.clearanceStatusToTaskStatus(clearance.status);
      const declarationStatus = summary.taskStatuses[declarationType]?.status || TaskMessageStatus.UNKNOWN;
      return {
        name: this.declarationTypeToName(declarationType),
        taskStatus: taskStatus === TaskMessageStatus.PENDING && [TaskMessageStatus.DELIVERED, TaskMessageStatus.UNKNOWN].includes(declarationStatus)
          ? declarationStatus : taskStatus,
        customLabel: taskStatus === TaskMessageStatus.WARNING ? 'Actions required' : null,
        messages: clearance.remarks
          ? [clearance.remarks].concat(clearance.attentionPoints.map(a => this.translateAttentionPoint(clearanceService, clearanceKey, a)))
          : clearance.attentionPoints.map(a => this.translateAttentionPoint(clearanceService, clearanceKey, a))
      };
    } else {
      return this.defaultMapper(summary, declarationType);
    }
  }

  private static translateAttentionPoint(clearanceService: ClearanceService, clearanceKey: AttentionPointContext, attentionPoint: AttentionPoint): string {
    return `${clearanceService.translate(clearanceKey, attentionPoint.type)}${attentionPoint.remarks ? ': ' + attentionPoint.remarks : ''}`;
  }

  private static defaultMapper(summary: NewVisitSummary, declarationType: DeclarationType): DeclarationMessageStatus {
    return {
      name: this.declarationTypeToName(declarationType),
      taskStatus: summary.taskStatuses[declarationType]?.status || TaskMessageStatus.UNKNOWN,
      messages: this.getMessagesForDeclarationType(summary, declarationType),
      reason: this.getReasonForDeclarationType(summary, declarationType)
    };
  }

  private static getReasonForDeclarationType(summary: NewVisitSummary, declarationType: DeclarationType): null | string {
    if (declarationType != DeclarationType.BUNKERING) return null;
    const taskStatus = summary.taskStatuses[declarationType];
    return taskStatus?.rejectReasons.join("; ")
  }

  private static getMessagesForDeclarationType = (summary: NewVisitSummary, declarationType: DeclarationType): string[] => {
    const taskStatus = summary.taskStatuses[declarationType];
    const status: TaskMessageStatus = taskStatus?.status || TaskMessageStatus.UNKNOWN;
    switch (declarationType) {
      case DeclarationType.VISIT:
        return summary.unreadHarbourRemarks && summary.harbourMasterRemarks ? [summary.harbourMasterRemarks] : [];
      default:
        return status === TaskMessageStatus.REJECTED ? taskStatus?.rejectReasons : [];
    }
  }

  private static declarationTypeToName = (declarationType: DeclarationType): string => {
    switch (declarationType) {
      // Port authority
      case DeclarationType.VISIT:
        return "Port visit";
      case DeclarationType.HEALTH:
        return "Health";
      case DeclarationType.SECURITY:
        return "Security";
      case DeclarationType.WASTE:
        return "Waste";
      case DeclarationType.DANGEROUS_GOODS:
        return "Dangerous goods";

      // Customs 'PAX', 'ENS', 'MSV', 'SDT'
      case DeclarationType.PAX:
        return "Crew & passengers";
      case DeclarationType.ENS:
        return "Entry declaration";
      case DeclarationType.MSV:
        return "Ship stores";
      case DeclarationType.SDT:
        return "Cargo";
      case DeclarationType.NOA:
        return "Arrival";
      case DeclarationType.NOD:
        return "Departure";
    }
    return declarationType;
  }

  private static getTerminalVisitStatuses(summary: NewVisitSummary): DeclarationMessageStatus[] {
    return summary.berthVisitSearches.map(bv => (<DeclarationMessageStatus>{
      name: bv.name || bv.terminalName || bv.stevedore,
      taskStatus: bv.terminalStatus?.status || (summary.terminalPlanningEnabled ? TaskMessageStatus.UNKNOWN : TaskMessageStatus.DISABLED),
      messages: bv.terminalStatus?.rejectReasons
    })).reverse();
  }

  private static clearanceStatusToTaskStatus(clearanceStatus: ClearanceStatus): TaskMessageStatus {
    switch (clearanceStatus) {
      case ClearanceStatus.UNKNOWN:
        return TaskMessageStatus.DISABLED;
      case ClearanceStatus.PENDING:
        return TaskMessageStatus.PENDING;
      case ClearanceStatus.APPROVED_WITH_REMARKS:
        return TaskMessageStatus.WARNING;
      case ClearanceStatus.APPROVED:
        return TaskMessageStatus.ACCEPTED;
      case ClearanceStatus.REJECTED:
        return TaskMessageStatus.REJECTED;
    }
  }

  private static getCargoDeclarationStatus(summary: NewVisitSummary): DeclarationMessageStatus {
    const status: CargoAgentStatus = AppContext.isAdmin()
      ? Object.values(summary.cargoAgentStatuses)
        .reduce((a, b) => b.cargoImportStatus === 'REJECTED'
          ? b : a && a.cargoImportStatus === 'REJECTED'
            ? a : b.cargoImportStatus === 'DECLARED'
              ? b : a && a.cargoImportStatus === 'DECLARED' ? a : b, null)
      : summary.cargoAgentStatuses[AppContext.userProfile.organisation?.shortName];
    const taskMessageStatus = NewPortvisitUtils.declarationStatusToTaskMessageStatus(status?.cargoImportStatus);
    return {
      name: this.declarationTypeToName(DeclarationType.SDT),
      taskStatus: taskMessageStatus ? taskMessageStatus
        : status?.cargoImportEnabled ? TaskMessageStatus.UNKNOWN : TaskMessageStatus.DISABLED,
      messages: []
    };
  }
}

export interface DeclarationMessageStatus {
  name: string;
  taskStatus: TaskMessageStatus;
  customLabel?: string;
  reason?: string;
  messages: string[];
  sentenceCase?: boolean;
  asInnerHtml?: boolean;
  hideComment?: boolean;
}
