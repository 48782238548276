import {Component, ElementRef, OnInit} from '@angular/core';
import {
  BezoekschipOrganisation,
  FindOrganisations,
  FindTransitTemplates,
  MergeOrganisationRefdata,
  TransitTemplate
} from '@portbase/bezoekschip-service-typescriptmodels';
import {QueryGateway} from "../../common/query-gateway";
import {checkValidity, removeItem, sendCommand, sendQuery} from "../../common/utils";
import {AppContext} from "../../app-context";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-templates',
  templateUrl: './transit-templates.component.html',
  styleUrls: ['./transit-templates.component.css']
})
export class TransitTemplatesComponent implements OnInit {
  formatter = PortvisitUtils.agentFormatter;
  appContext = AppContext;
  values: TransitTemplate[] = [];
  filterOrganisationShortName: string = '';
  searchTerm: string = '';

  constructor(private element: ElementRef, private queryGateway: QueryGateway) {
  }

  ngOnInit(): void {
    this.reload();
  }

  searchOrganisation = term => sendQuery('com.portbase.bezoekschip.common.api.authorisation.FindOrganisations',
    <FindOrganisations>{term: term});

  setOrganisationName = (organisation: BezoekschipOrganisation) => {
    this.filterOrganisationShortName = organisation ? organisation.shortName : null;
    this.reload();
  };

  reload = () => {
    sendQuery("com.portbase.bezoekschip.common.api.transit.FindTransitTemplates", <FindTransitTemplates>{
      term: this.searchTerm,
      maxHits: 10000,
      ownerShortName: this.filterOrganisationShortName
    }).subscribe(values => this.values = values);
  };

  add() {
    this.values.splice(0, 0, <any>{});
  }

  remove(value) {
    return () => {
      removeItem(this.values, value);

      if (value.id) {
        sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeTransitTemplate', <MergeOrganisationRefdata>{
          value: value,
          id: value.name,
          delete: true
        }, () => {
          AppContext.registerSuccess("Transit template was deleted successfully");
          this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.transit.FindTransitTemplates");
        });
      }
    }
  }

  save(value: TransitTemplate) {
    if (checkValidity(this.element)) {
      sendCommand('com.portbase.bezoekschip.common.api.refdata.MergeTransitTemplate', <MergeOrganisationRefdata>{
        id: value.name,
        value: value,
        delete: false
      }, () => {
        AppContext.registerSuccess("Transit template was saved successfully");
        this.queryGateway.removeFromCache("com.portbase.bezoekschip.common.api.cargo.FindMyTransitTemplates");
      });
      return true;
    }
    return false;
  }

  trackById(index: number, obj: TransitTemplate): any {
    return obj.id;
  }
}
