import {Injectable} from '@angular/core';
import {QueryHandler} from '../common/query-handler';
import stevedores from '@portbase/bezoekschip-service-typescriptmodels/refdata/stevedores.json';
import {Observable, of} from 'rxjs';
import {
  BerthVisit,
  BerthVisitSearch,
  BezoekschipOrganisation,
  CargoAgentStatus,
  CollectorSummary,
  CommercialReleaseLogItem,
  Consignment,
  DeclarationStatus,
  DeclarationType,
  FindCommercialReleases,
  FindConsignments,
  FindCustomers,
  FindMidUpdates,
  FindTransitDeclarations,
  FindVisits,
  FindVisitsResult,
  GetCollectorOverview,
  GetOrganisationByShortName,
  GetStevedores,
  GetTransitGuaranteeLog,
  GetTransitLog,
  GetVisits,
  IE3ConsignmentsResult,
  IE3FindConsignments,
  IE3GetTranshipmentLog,
  IE3Transhipment,
  MessageStatus,
  NextDeparture,
  PassageCode,
  PendingDeclaration,
  PlanningVisit,
  SearchCollectorOverview,
  SearchVisits,
  Stevedore,
  TaskMessageStatus,
  TaskStatus,
  TranshipmentLogItem,
  TransitGuaranteeLogItem,
  TransitLogItem,
  UserProfile,
  Visit,
  VisitLogItem,
  VisitOverview,
  VisitSummary,
  VisitSummaryStatus,
  WasteCollector
} from '@portbase/bezoekschip-service-typescriptmodels';
import {VisitMock} from './visit-details.mock';
import {WasteCollectionMock} from './waste-collection.mock';
import lodash from 'lodash';
import {$try, filterByTerm} from '../common/utils';
import {delay, map} from 'rxjs/operators';
import {midMock, TransitMock} from "./transit-declarations.mock";
import vaartijden from "./vaartijden.json";
import {consignment2, consignments} from "./transit-consignments.mock";
import {CommercialReleaseMock} from "./commercial-release.mock";
import {TranshipmentMock, transhipmentToSummary} from "./transhipment.mock";
import {VisitContext} from "../visit-details/visit-context";

@Injectable()
export class BezoekschipQueryHandlerMock extends QueryHandler {
  static organisationPreferences = {};
  static userPreferences = {};
  private visitCounter = 2;
  private callIdCounter = 1;
  'com.portbase.bezoekschip.common.api.commercialrelease.GetReleaseId' = (query) => {
    return null;
  }

  'com.portbase.bezoekschip.common.api.bunkering.SearchVisitsForBunkering' = (query) => {
    return [
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.VisitForBunkering",
        "crn": "NLRTM22902232",
        "imo": "9269403",
        "start": "2022-08-31T20:00:00Z",
        "end": "2022-09-04T10:00:00Z",
        "vesselName": "BIANCA AMORETTI",
        "visitStatus": "EXPECTED",
        "portUnCode": "NLRTM",
        "berthVisits": [
          {
            "berthVisitId": "9bd1f99e-15c2-4389-a20f-31f12d06c8df",
            "berthName": "AMAZONEH ECT DDE",
            "eta": "2022-08-31T21:30:00Z",
            "ata": null,
            "etd": null,
            "atd": null
          }
        ]
      }
    ]
  }
  "com.portbase.bezoekschip.common.api.refdata.GetBunkerProductSuppliers" = (query) => {
    return [
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProductSupplier",
        "id": "9afe8503-98bb-4f47-bf1b-e360ba6d0849",
        "name": "A. Nobel en Zn Beheer BV",
        "eori": "NL800747902",
        "contact": "H.C. Nobel",
        "email": "huibertjr.nobel@anobel.nl",
        "phone": "+31786250976"
      }]
  }

  "com.portbase.bezoekschip.common.api.bunkering.GetBunkering" = (query) => {
    return {
      "bunkeringId": "D3A4SKSF9F94Q1QZE78EX361UBO74P73",
      "cancelled": false,
      "berthVisitSelection": {
        "crn": "NLRTM23901721",
        "berthVisitId": "c8d15421-2d2f-4e07-89e3-1b1819da4cc8",
        "berthName": "WAALH 4 HBR B 27",
        "startTime": "2023-06-27T13:15:00Z",
        "endTime": "2023-06-27T21:00:00Z",
        "imo": "9305312",
        "vesselName": "MAERSK MONTANA"
      },
      "sendProToCustoms": false,
      "input": {
        "ets": "2023-06-27T14:00:00Z",
        "etc": "2023-06-27T20:00:00Z",
        "license": {
          "id": "cdd40fa8-fb92-11ed-be56-0242ac120002",
          "portUnCode": "NLRTM",
          "organisationShortName": "BOBRAVO",
          "bunkerVessel": {
            "imo": null,
            "eni": "02327381",
            "name": "ATLANTIC PRIDE"
          }
        },
        "supplier": {
          "id": "5f88be2c-e131-4031-bbf4-7964aa9a3fd4",
          "name": "Atlantic Aardolieproductenmaatschappij BV",
          "eori": "NL004391799",
          "contact": "Remco van Driel",
          "email": "bunkers@atlantic-aardolie.nl",
          "phone": "+31102314420"
        },
        "items": [
          {
            "product": {
              "id": "f4e9f101-c424-4595-80fd-ce3d1f4db33c",
              "type": "Bio LUB",
              "provisionType": "lubricant",
              "unitOfMeasure": "ltr",
              "density": 900
            },
            "quantity": 900
          },
          {
            "product": {
              "id": "7d65657f-bef0-448f-a75d-58ec49b8fd2d",
              "type": "Caustic soda",
              "provisionType": "other",
              "unitOfMeasure": "MT",
              "density": 1530
            },
            "quantity": 12
          }
        ]
      }
    }
  }
  "com.portbase.bezoekschip.common.api.bunkering.SearchBunkerings" = (query) => {
    return [
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkeringSummary",
        "crn": "NLRTM23901721",
        "berthVisitId": "c8d15421-2d2f-4e07-89e3-1b1819da4cc8",
        "bunkeringId": "D3A4SKSF9F94Q1QZE78EX361UBO74P73",
        "userName": "sd@BOBRAVO",
        "organisationShortName": "BOBRAVO",
        "cancelled": false,
        "ets": "2023-06-27T14:00:00Z",
        "etc": "2023-06-27T20:00:00Z",
        "vesselName": "MAERSK MONTANA",
        "berthName": "WAALH 4 HBR B 27",
        "harbourMasterDeclarationStatus": {
          "status": "REJECTED",
          "rejectionReason": "Opslaan van bunkering D3A4SKSF9F94Q1QZE78EX361UBO74P73 is niet gelukt"
        },
        "customsDeclarationStatus": {
          "status": "REJECTED",
          "rejectionReason": "Visit_ID is NOT known for reported stores / Identification code of reporting party is NOT valid"
        },
        "items": [
          {
            "productType": "Bio LUB",
            "unitOfMeasure": "ltr",
            "quantity": 900
          },
          {
            "productType": "Caustic soda",
            "unitOfMeasure": "MT",
            "quantity": 12
          }
        ],
        "sendProToCustoms": true
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkeringSummary",
        "crn": "NLRTM23901116",
        "berthVisitId": "061ba765-e56f-4445-8a74-56f140f4c239",
        "bunkeringId": "M2YOGSWXE4ESPRUYHDXGJ2ZEU9P4LUJZ",
        "userName": "sd@BOBRAVO",
        "organisationShortName": "BOBRAVO",
        "cancelled": false,
        "ets": "2023-05-04T22:00:00Z",
        "etc": "2023-05-05T22:00:00Z",
        "atc": "2023-05-05T22:15:00Z",
        "vesselName": "ATLANTIC POLARIS",
        "berthName": "LAURENSH EBS 1",
        "harbourMasterDeclarationStatus": {
          "status": "REJECTED",
          "rejectionReason": "Opslaan van bunkering M2YOGSWXE4ESPRUYHDXGJ2ZEU9P4LUJZ is niet gelukt"
        },
        "customsDeclarationStatus": {
          "status": "DECLARED",
          "rejectionReason": null
        },
        "items": [
          {
            "productType": "Bio Methanol",
            "unitOfMeasure": "MT",
            "quantity": 792
          }
        ],
        "sendProToCustoms": false
      }
    ]
  }
  'com.portbase.bezoekschip.common.api.refdata.GetBunkerProducts' = (query) => {
    if (query.term == 'no') {
      return [];
    }
    return [
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "7b64ad83-350a-403b-aeab-735c2652c508",
        "type": "Bio GTL",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 785
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "d7883c07-b6c3-4a48-824f-c3b36093ab1c",
        "type": "Bio HSFO < 3,5%S",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 1000
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "344e2645-4a18-40a5-ba30-2fd28533c0d2",
        "type": "Bio LNG",
        "provisionType": "fuel",
        "unitOfMeasure": "m3",
        "density": 450
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "f4e9f101-c424-4595-80fd-ce3d1f4db33c",
        "type": "Bio LUB",
        "provisionType": "lubricant",
        "unitOfMeasure": "ltr",
        "density": 900
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "5603ae11-8118-492a-87e6-30bfe8cc97af",
        "type": "Bio MDO",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 840
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "aff616c4-ac9f-457e-8b6e-e42ac0a4655a",
        "type": "Bio MGO",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 890
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "7b68dd7a-558b-4f73-b488-75a9dc25520e",
        "type": "Bio Methanol",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 792
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "7b6a2304-8a71-4331-8fe1-bfba30e76949",
        "type": "Bio ULSFO < 0.1%S",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 850
      },
      {
        "@class": "com.portbase.bezoekschip.common.api.bunkering.BunkerProduct",
        "id": "4f54c9f9-7857-419d-9aa0-d3477a28a5a3",
        "type": "Bio VLSFO < 0.5%S",
        "provisionType": "fuel",
        "unitOfMeasure": "MT",
        "density": 850
      }
    ]
  }

  'com.portbase.bezoekschip.common.api.commercialrelease.FindCustomers' = (query: FindCustomers) => {
    return ([
      {
        ownerPortbaseId: "AGENT16",
        ownerFullName: "Agent 16",
        portbaseId: "KUEHNENAGEL",
        verified: false,
        references: {
          kuehnenagel: {"reference": "kuehnenagel", status: "VERIFIED", "verified": true},
          xxx1: {"reference": "xxx1", status: "VERIFIED", "verified": true},
          xxx2: {"reference": "xxx2", status: "VERIFIED", "verified": true},
          xxx3: {"reference": "xxx3", status: "VERIFIED", "verified": true},
          zzz1: {"reference": "zzz1", status: "REJECTED", "verified": false},
          zzz2: {"reference": "zzz2", status: "REJECTED", "verified": false},
          zzz3: {"reference": "zzz3", status: "REJECTED", "verified": false},
          kuehne1: {"reference": "kuehne1", status: "NEW", "verified": false},
          kuehne2: {"reference": "kuehne2", status: "NEW", "verified": false},
          kuehne3: {"reference": "kuehne3", status: "NEW", "verified": false}
        },
        customerData: {
          fullName: "Kuehne Nagel",
          address: "Test adres 2",
          zipCode: "3036 AA",
          city: "Rotterdam",
          chamberOfCommerceNumber: "47987492"
        }
      }, {
        ownerPortbaseId: "SO1",
        ownerFullName: "Ship Operator 1",
        portbaseId: "SO1",
        verified: true,
        references: {
          so1: {
            "reference": "so1", "verified": false, status: "REJECTED", rejectReason: "foutje gemaakt" +
                " in klantcode"
          }
        },
        customerData: {
          fullName: "Ship Operator 1",
          address: "Test adres 2",
          zipCode: "3085 WL",
          city: "Rotterdam",
          chamberOfCommerceNumber: "234234"
        },
        chamberOfCommerceNumber: "47987493"
      }
    ]).filter(filterByTerm(query.term));
  }

  "com.portbase.bezoekschip.common.api.visit.GetSailingTimesTables" = (query) => {
    return of(vaartijden);
  };

  "com.portbase.bezoekschip.common.api.consignments.queries.SearchTranshipments" = (query) => {
    return of(TranshipmentMock.map(t => transhipmentToSummary(t as IE3Transhipment)));
  }

  "com.portbase.bezoekschip.common.api.consignments.queries.GetTranshipmentLog" = (query: IE3GetTranshipmentLog) => {
    return <TranshipmentLogItem[]>[
      {
        "action": "Transhipment information declared",
        "allowedViewers": [
          "AAP"
        ],
        "eventId": "9a28cd83-0da6-444d-99a9-a912ed7ed387",
        "fullName": "Test Agent",
        "shortName": "MSCRTM",
        "timestamp": "2019-06-07T09:41:52.300Z",
        "type": "com.portbase.bezoekschip.common.api.consignments.commands.DeclareTranshipment"
      },
      {
        "action": "Transhipment information sent",
        "allowedViewers": [
          "AAP"
        ],
        "crn": "NLRTM20535627",
        "eventId": "9a28cd83-0da6-444d-99a9-a912ed7ed387",
        "fullName": "Test Agent",
        "shortName": "MSCRTM",
        "timestamp": "2019-06-07T09:41:52.300Z",
        "type": "com.portbase.bezoekschip.common.api.consignments.commands.DeclareTranshipment"
      }
    ]
  };

  "com.portbase.bezoekschip.common.api.transit.FindTransitDeclarations" = (query: FindTransitDeclarations) => {
    return of(TransitMock)
      .pipe(map(declarations => declarations.filter(d => !query.crn || d.crn === query.crn).filter(filterByTerm(query.term))))
      .pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.GetStevedores" = (query: GetStevedores) => {
    return of(stevedores.default.map(s => {
      const stevedore: Stevedore = s;
      stevedore.multiQuay = stevedore.quaysForPlanning?.length > 1;
      return stevedore;
    }));
  }

  "com.portbase.bezoekschip.common.api.transit.FindMidUpdates" = (query: FindMidUpdates) => {
    return of(midMock)
      .pipe(map(declarations => declarations.filter(filterByTerm(query.term))))
      .pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.FindCommercialReleases" = (query: FindCommercialReleases) => {
    return of(CommercialReleaseMock)
      .pipe(map(declarations => declarations.filter(d => !query.crn || d.crn === query.crn).filter(filterByTerm(query.term))))
      .pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.FindAllConsignments" = (query: any) => {
    return of(consignments)
      .pipe(map(declarations => declarations.filter(filterByTerm(query.term))))
      .pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.GetConsignment" = (query: any) => {
    return of(consignment2)
      .pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.history.GetTransitLog" = (query: GetTransitLog) => {
    return <TransitLogItem[]>[
      {
        user: "aap@wasser.com",
        description: "Transit declared",
        lrn: "dfg4gS4tRsDG33tgdgrdrfgGgdf",
        timestamp: "2021-09-03T09:19:20.156Z",
        eventId: "123",
        fullName: "Apenwasser",
        shortName: "AAP"
      },
      {
        user: "aap@wasser.com",
        description: "Transit accepted on 2021-09-03",
        lrn: "dfg4gS4tRsDG33tgdgrdrfgGgdf",
        timestamp: "2021-09-03T10:19:20.156Z",
        eventId: "123",
        shortName: "AAP"
      }
    ]
  };

  "com.portbase.bezoekschip.common.api.commercialrelease.GetCommercialReleaseHistoryLog" = (query: GetTransitLog) => {
    return <CommercialReleaseLogItem[]>[
      {
        description: "Release declared",
        timestamp: "2021-09-03T09:19:20.156Z",
        eventId: "123",
        fullName: "Aap en wasser",
        shortName: "AAP",
        user: "mr aap"
      },
      {
        description: "Release accepted",
        timestamp: "2021-09-03T10:19:20.156Z",
        eventId: "123",
        shortName: "RWG",
        fullName: "Rotterdam World Gateway (RWG)",
      }
    ]
  };

  "com.portbase.bezoekschip.common.api.history.GetTransitGuaranteeLog" = (query: GetTransitGuaranteeLog) => {
    return <TransitGuaranteeLogItem[]>[
      {
        user: "aap@wasser.com",
        description: "Transit declared",
        referenceNumber: "GRN1",
        id: "1",
        timestamp: "2021-09-03T09:19:20.156Z",
        eventId: "123",
        fullName: "Apenwasser",
        shortName: "AAP"
      },
      {
        user: "douane@customs.com",
        description: "Transit accepted on 2021-09-03",
        referenceNumber: "GRN1",
        id: "1",
        timestamp: "2021-09-03T10:18:20.156Z",
        eventId: "123",
        shortName: "AAP"
      }
    ]
  };


  "com.portbase.bezoekschip.common.api.authorisation.GetOrganisationByShortName" = (query: GetOrganisationByShortName) => {
    return of({
      "fullName": "ATM",
      "portAuthorityId": null,
      "shortName": query.shortName,
      "emailAddress": "info@atmmoerdijk.nl",
      "address": "Vlasweg 12",
      "city": "Moerdijk",
      "countryUnCode": "NL",
      "countryName": "Netherlands",
      "zipCode": "4785 PW",
      "phoneNumber": "+31-168-389289",
      "faxNumber": "+31-168-389270",
      "contact": "Danny van Leeuwen",
      "customsEORINumber": null,
      "ean": null,
      "chamberOfCommerceNumber": null,
      "customsZipCode": '1111 ZZ',
      "arrivalLocationHouseNumber": '1',
      "cargoImportEnabled": false,
      "cargoImportEnabledByDefault": false,
      "ensEnabled": false,
      "scacCode": "KAK"
    });
  }


  "com.portbase.bezoekschip.common.api.waste.GetWasteCollector" = (query) => {
    return of(<WasteCollector>{
      "organisation": {
        "fullName": "ATM",
        "portAuthorityId": null,
        "shortName": "ATM1",
        "emailAddress": "info@atmmoerdijk.nl",
        "address": "Vlasweg 12",
        "city": "Moerdijk",
        "countryUnCode": "NL",
        "countryName": "Netherlands",
        "zipCode": "4785 PW",
        "phoneNumber": "+31-168-389289",
        "faxNumber": "+31-168-389270",
        "contact": "Danny van Leeuwen",
        "customsEORINumber": null,
        "ean": null,
        "chamberOfCommerceNumber": null,
        "cargoImportEnabled": false,
        "cargoImportEnabledByDefault": false,
        "ensEnabled": false,
        "scacCode": "KAK"
      },
      "vihbNumber": "501809",
      "collectorNumber": "ATM01",
      "annexPerPort": {
        "NLRTM": [
          1,
          2,
          4,
          5,
          6
        ]
      },
      "authorityIdPerPort": {
        "NLRTM": "ATM"
      }
    })
  }

  "com.portbase.bezoekschip.common.api.authorisation.GetUserProfile" = (query) => {
    return of(<UserProfile>{
      organisation: {
        fullName: "Ship Operator 1",
        portAuthorityId: "pid",
        shortName: "BO1",
        emailAddress: "l.paalvast@portbase.com",
        address: "Koudwatervreesstraat 12",
        city: "Rotterdam",
        countryUnCode: "NL",
        countryName: "Nederland",
        zipCode: "3000AA",
        phoneNumber: "-",
        faxNumber: undefined,
        contact: "john smith",
        customsEORINumber: "custardpie",
        ean: "ean"
      },
      userName: "Len de Men",
      customsResponsibleForOrganisations: ["BO1"],
      roles: ["VisitDeclarant", "PaxDeclarant", "ShipSuppliesDeclarant",
        "DangerousGoodsDeclarantCargoHandlingAgent", "VisitPortProcessAdministrator"]
    }).pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.GetVisit" = (query) => {
    return of(VisitMock[query.crn]).pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.history.GetHistoricalVisit" = (query) => {
    return of(VisitMock[query.crn]).pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.FindVisits" = (query: FindVisits): Observable<FindVisitsResult> => {
    const result: FindVisitsResult = {
      visits: [], facets: {
        "ownerShortName": [
          {
            "value": "KUHLMAN",
            "count": 1
          }
        ],
        "unreadHarbourRemarks": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "visitStatus": [
          {
            "value": "ARRIVED",
            "count": 1
          }
        ],
        "allowedViewers": [
          {
            "value": "KUHLMAN",
            "count": 1
          },
          {
            "value": "HBHHAR",
            "count": 1
          }
        ],
        "count": [
          {
            "value": "1",
            "count": 1
          }
        ],
        "tidalShip": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "portName": [
          {
            "value": "Harlingen",
            "count": 1
          }
        ],
        "declarantShortName": [
          {
            "value": "KUHLMAN",
            "count": 1
          }
        ]
      },
    };
    for (let crn in VisitMock) {
      const visit = this.toVisitSummary(<Visit>VisitMock[crn]);
      if (query.term && !JSON.stringify(visit).toLocaleLowerCase().includes(query.term.toLocaleLowerCase())) {
        continue;
      }
      const facetMatch = query.facetFilters.find(f => f.facetName === "visitStatus");
      if (!facetMatch || facetMatch.values.includes(visit.visitStatus)) {
        result.visits.push(visit);
      }
    }
    return of(result).pipe(delay(100));
  }

  "com.portbase.bezoekschip.common.api.visit.GetVisits" = (query: GetVisits) => {
    const result = <VisitOverview>{
      plannedVisits: [],
      expectedVisits: [],
      arrivedVisits: [],
      departedVisits: [],
      cancelledVisits: []
    };
    let i = 0;
    for (let crn in VisitMock) {
      const visit = <Visit>VisitMock[crn];
      this.assignVisitSummaryToCategory(visit, result);
    }
    return of(result).pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.SearchVisits" = (query: SearchVisits) => {
    const result = <VisitOverview>{
      plannedVisits: [],
      expectedVisits: [],
      arrivedVisits: [],
      departedVisits: [],
      cancelledVisits: []
    };
    for (let crn in VisitMock) {
      const visit = <Visit>VisitMock[crn];
      if (!JSON.stringify(visit).toLocaleLowerCase().includes(query.term.toLocaleLowerCase())) {
        continue;
      }
      this.assignVisitSummaryToCategory(visit, result);
    }
    return of(result).pipe(delay(100));
  };


  "com.portbase.bezoekschip.common.api.waste.collector.GetCollectorOverview" = (query: GetCollectorOverview) => {
    return of(WasteCollectionMock).pipe(delay(100));
  }


  "com.portbase.bezoekschip.common.api.waste.collector.SearchCollectorOverview" = (query: SearchCollectorOverview) => {
    return of([WasteCollectionMock[0]]);
  }

  "com.portbase.bezoekschip.common.api.cargo.GetDischargeListAsCsv" = query => {
    return '"Call reference nr";"Operator voyage nr";"Agent voyage nr";"Carrier code";"Carrier name";"Call sign";"Vessel name";"PoD";"Arrival date";"Arrival time";"Departure date";"Departure time";"Status";"Container nr";"BL1";"BL2";"BL3";"BL4";"BL5";"BL6";"BL7";"BL8";"Size type";"Full/empty";"Eq. status";"Cust. status";"Movement type";"PoL";"Destination";"Dest. address";"Nett weight";"Tare weight";"Gross weight";"Overlength";"Overheight";"Overwidth";"Remarks";"Temp. set";"Temp. unit";"Shipper seal nr";"Carrier seal nr";"Customs seal nr";"Operator";"Booking ref nr";"SC mode";"SC name";"SC date";"SC time";"SC voyage";"Goods descr1";"Goods descr2";"Goods descr3";"Goods descr4";"Goods descr5";"Goods descr6";"Goods descr7";"UNDG-1";"IMO class-1";"Flashpoint-1";"Flashpoint unit-1";"UNDG-2";"IMO class-2";"Flashpoint-2";"Flashpoint unit-2";"UNDG-3";"IMO class-3";"Flashpoint-3";"Flashpoint unit-3";"UNDG-4";"IMO class-4";"Flashpoint-4";"Flashpoint unit-4";"UNDG-5";"IMO class-5";"Flashpoint-5";"Flashpoint unit-5"\n' +
      '"NLRTM20535882";"IP025A";"00017";"MSCU";"MSC , S.A.";"3EOB8";"MSC ASYA";"NLRTM";"17-06-2020";"05:00";"18-06-2020";"11:00";"NW";"IRNU2020150";"OPDR20OGB005650";"";"";"";"";"";"";"";"22G1";"5";"3";"";"2";"GBSOU";"NOBVK";"name";"22074";"2185";"24259";"";"";"";"";"";"CEL";"";"9293060";"";"OPDR";"OGB027798";"1";"ENERGIZER";"";"";"";"GRANULAR FURNACE SLAG";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";""\n' +
      '"NLRTM20535882";"IP025A";"00017";"MSCU";"MSC , S.A.";"3EOB8";"MSC ASYA";"NLRTM";"17-06-2020";"05:00";"18-06-2020";"11:00";"NW";"RFCU2246489";"OPDR20OGB005650";"";"";"";"";"";"";"";"22G1";"5";"3";"";"2";"GBSOU";"NOBVK";"name";"22346";"2200";"24546";"";"";"";"";"";"CEL";"";"9333967";"";"OPDR";"OGB027798";"1";"ENERGIZER";"";"";"";"GRANULAR FURNACE SLAG";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";""\n' +
      '"NLRTM20535882";"IP025A";"00017";"MSCU";"MSC , S.A.";"3EOB8";"MSC ASYA";"NLRTM";"17-06-2020";"05:00";"18-06-2020";"11:00";"NW";"CMAU3217226";"OPDR20OGB005650";"";"";"";"";"";"";"";"22G1";"5";"3";"";"2";"GBSOU";"NOBVK";"name";"22146";"2100";"24246";"";"";"";"";"";"CEL";"";"9333970";"";"OPDR";"OGB027798";"1";"ENERGIZER";"";"";"";"GRANULAR FURNACE SLAG";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";""\n' +
      '"NLRTM20535882";"IP025A";"00017";"MSCU";"MSC , S.A.";"3EOB8";"MSC ASYA";"NLRTM";"17-06-2020";"05:00";"18-06-2020";"11:00";"NW";"CRSU1097078";"OPDR20OGB005650";"";"";"";"";"";"";"";"22G1";"5";"3";"";"2";"GBSOU";"NOBVK";"name";"22208";"2250";"24458";"";"";"";"";"";"CEL";"";"9293059";"";"OPDR";"OGB027798";"1";"ENERGIZER";"";"";"";"GRANULAR FURNACE SLAG";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";"";""\n';
  };

  "com.portbase.bezoekschip.common.api.visit.FindConsignments" = (query: FindConsignments) => {
    return <Consignment[]>[<any>{
      "declarations": [],
      "partiesToNotify": [],
      "goodsItems": [{
        "placements": [{
          "equipmentNumber": "ABCD1234567",
          "numberOfPackages": "1",
          "grossWeight": 1000
        }],
        "producedDocuments": [{"type": "N703", "id": "209021", "description": "Live animalsss"}],
        "marksAndNumbers": ["90210"],
        "itemNumber": 1,
        "description": "Cows and other animals like pigs and even more stuff like donkeys",
        "classification": {"code": "0100", "description": "Live animals"},
        "minimumTemperature": 10,
        "maximumTemperature": 25,
        "grossWeight": 1000,
        "numberOfOuterPackages": "1",
        "outerPackageType": {"code": "BK", "name": "Basket "}
      }],
      "partShipment": false,
      "consignmentNumber": "7687",
      "movementType": "DOOR_TO_DOOR",
      "customsProcess": "SEA_IN_SEA_OUT",
      "movementReferenceNumbers": ["219820198"],
      "consignor": {
        "id": "test",
        "name": "Crazy Pete",
        "address": "partyStreet 123",
        "city": "Party Ville",
        "countryCode": "FR",
        "eoriNumber": "eoriForParty",
        "zipCode": "party321"
      },
      "consignee": {"eoriNumber": "209201", "name": "Pickle Rick"},
      "customsStatus": "EU_COMMUNITY_GOODS",
      "terminal": {
        "terminalName": "HOLLAND AMERIKAKADE",
        "terminalCode": "52090469",
        "organisationShortName": "ECTDELTA"
      }
    }];
  }

  "com.portbase.bezoekschip.common.api.consignments.queries.FindConsignments" = (query: IE3FindConsignments) => {
    return <IE3ConsignmentsResult>{
      "consignments": [
        {
          "consignmentProcessId": "69569d1e-7987-11ea-b477-2f1fc4483b1d_EIMU2850144",
          "iamConnectedId": "69569d1e-7987-11ea-b477-2f1fc4483b1d",
          "cancelled": false,
          "declared": true,
          "crn": "NLRTM24026524",
          "etaFirstEntryEu": null,
          "etaPort": "2024-12-01T20:00:00Z",
          "ataPort": "2024-12-01T23:38:00Z",
          "masterConsignment": {
            "consignmentNumber": "EIMU2850144",
            "goodsItems": [],
            "equipments": [
              {
                "equipmentNumber": "CXRU1282183",
                "empty": false,
                "sizeType": {
                  "code": "45R1",
                  "name": "40'x8'x9'6\" Refrigerated Container",
                  "isoCode": "44R1",
                  "uneceSizeType": null,
                  "containerType": true
                }
              }
            ],
            "dischargeTerminal": {
              "terminalName": "ALEXANDERH RST ZONE 10",
              "terminalCode": "8410",
              "organisationShortName": "RST",
              "organisationName": "Rotterdam Shortsea Terminal",
              "deprecatedSwLocationCode": false,
              "rollOnRollOff": false
            },
            "placeOfAcceptance": null,
            "placeOfDelivery": null,
            "placeOfLoading": {
              "name": "Reykjavík",
              "locationUnCode": "ISREY",
              "countryUnCode": "IS",
              "euLocation": false
            },
            "placeOfUnloading": {
              "name": "Rotterdam",
              "locationUnCode": "NLRTM",
              "countryUnCode": "NL",
              "euLocation": true
            },
            "voyageNumber": "448",
            "houseConsignments": [
              {
                "consignmentNumber": "EIMU2850144",
                "goodsItems": [
                  {
                    "goodsItemNumber": 1,
                    "grossWeight": 28350,
                    "netWeight": null,
                    "numberOfOuterPackages": 1000,
                    "commodity": {
                      "descriptionOfGoods": "FROZEN MACKEREL",
                      "cusCode": null,
                      "commodityCode": {
                        "harmonizedSystemSubHeadingCode": "030354",
                        "combinedNomenclatureCode": null
                      },
                      "dangerousGoods": null
                    },
                    "placements": [
                      {
                        "containerIdentificationNumber": "CXRU1282183",
                        "numberOfPackages": 1000,
                        "grossWeight": null
                      }
                    ],
                    "bulkDischarge": null
                  }
                ],
                "equipments": [
                  {
                    "equipmentNumber": "CXRU1282183",
                    "empty": false,
                    "sizeType": {
                      "code": "45R1",
                      "name": "40'x8'x9'6\" Refrigerated Container",
                      "isoCode": "44R1",
                      "uneceSizeType": null,
                      "containerType": true
                    }
                  }
                ],
                "consignee": {
                  "identificationNumber": "NL820468356",
                  "typeOfPerson": "LEGAL_PERSON",
                  "name": "Daalimpex Velsen",
                  "address": {
                    "city": "Velsen-Noord",
                    "country": "NL",
                    "subDivision": null,
                    "street": "Velserkade",
                    "postCode": "1951 NK",
                    "additionalLine": null,
                    "number": "1",
                    "poBox": null
                  },
                  "communications": []
                },
                "consignor": {
                  "identificationNumber": null,
                  "typeOfPerson": "LEGAL_PERSON",
                  "name": "VARDIN PELAGIC P/F",
                  "address": {
                    "city": "Tvoroyri",
                    "country": "FO",
                    "subDivision": null,
                    "street": "Havnalagid",
                    "postCode": "800",
                    "additionalLine": null,
                    "number": "1",
                    "poBox": null
                  },
                  "communications": []
                }
              }
            ],
            "customsProcess": null,
            "customsStatus": null,
            "vehicle": {
              "identificationNumber": "9333383",
              "type": "N10",
              "name": "JONNI RITSCHER"
            }
          },
          "allowedViewers": [
            "69569d1e-7987-11ea-b477-2f1fc4483b1d"
          ],
          "status": {
            "filingStatusENS": {
              "filingTime": "2024-12-01T16:25:42.575Z",
              "ensRegistrationResponse": {
                "mrn": "24NL01000INTBG9AT2",
                "registrationTime": "2024-11-25T15:29:42.376305065Z"
              },
              "amendmentNotification": {
                "completionTime": "2024-12-01T16:25:44Z"
              },
              "lifecycleValidationErrorNotification": null,
              "errornotification": null
            },
            "filingStatusTemporaryStorage": {
              "registrationTime": "2024-12-03T13:08:16.080Z",
              "registrationResponse": {
                "latestAcceptedTime": "2024-12-03T13:08:54.472Z",
                "latestNotificationTime": "2024-12-03T13:08:54.472Z",
                "errors": [],
                "responseCode": "CODE_44",
                "mrn": null
              },
              "status": "ACCEPTED"
            },
            "assessmentCompleteNotification": null,
            "ensNotCompleteNotification": [],
            "doNotLoadRequest": null,
            "controlNotification": null,
            "advanceControlNotification": null,
            "houseConsignmentInIncorrectStateNotification": null,
            "ensPendingNotification": null,
            "invalidationRequest": null,
            "invalidationAcceptanceResponse": null,
            "additionalInformationRequest": null,
            "additionalInformationRequestResponse": null,
            "additionalInformationRequestNotification": null,
            "arrivalRegistrationResponse": {
              "registrationDate": "2024-12-01T23:38:17Z"
            },
            "clearanceStatus": null,
            "latestEnsStatus": "AMENDMENT_COMPLETE_NOTIFICATION",
            "notificationOfArrivalStatus": "ACCEPTED"
          },
          "functionalReferenceId": "38Vkwrev9Nv7gqfVnvMPPM",
          "filingType": "F10",
          "straight": true,
          "dischargedContainers": [],
          "temporaryStorageStatus": "ACCEPTED",
          "activeCrns": [
            "NLRTM24026524"
          ],
          "fromVisit": false,
          "temporaryStorageTaskStatus": "ACCEPTED",
          "ensTaskStatus": "DELIVERED",
          "equipmentCount": 1,
          "houseConsignmentsCount": 1,
          "totalGrossWeight": 28350,
          "ensStatus": "AMENDMENT_COMPLETE_NOTIFICATION",
          "count": 1
        }
      ],
      "facets": {
        "count": [
          {
            "value": "1",
            "count": 1
          }
        ],
        "dischargeTerminalName": [
          {
            "value": "ALEXANDERH RST ZONE 10",
            "count": 1
          }
        ],
        "fromVisit": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "houseConsignmentsCount": [
          {
            "value": "1",
            "count": 1
          }
        ],
        "equipmentCount": [
          {
            "value": "1",
            "count": 1
          }
        ],
        "totalGrossWeight": [
          {
            "value": "28350",
            "count": 1
          }
        ],
        "activeCrns": [
          {
            "value": "NLRTM24026524",
            "count": 1
          }
        ],
        "allowedViewers": [
          {
            "value": "69569d1e-7987-11ea-b477-2f1fc4483b1d",
            "count": 1
          }
        ],
        "placeOfLoading": [
          {
            "value": "Reykjavík",
            "count": 1
          }
        ],
        "draft": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "actionRequired": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "cancelled": [
          {
            "value": "false",
            "count": 1
          }
        ],
        "filingType": [
          {
            "value": "F10",
            "count": 1
          }
        ],
        "ensStatus": [
          {
            "value": "AMENDMENT_COMPLETE_NOTIFICATION",
            "count": 1
          }
        ],
        "iamConnectedId": [
          {
            "value": "69569d1e-7987-11ea-b477-2f1fc4483b1d",
            "count": 1
          }
        ],
        "temporaryStorageStatus": [
          {
            "value": "ACCEPTED",
            "count": 1
          }
        ]
      }
    };
  }

  "com.portbase.bezoekschip.common.api.admin.GetPendingDeclarations" = (query) => {
    const result = [
      <PendingDeclaration>{crn: 'NLRTM18000001', type: "NOA", timestamp: '2018-11-08T11:00:00Z'},
      <PendingDeclaration>{crn: 'NLRTM18000001', type: "NOD", timestamp: '2019-02-18T18:15:00Z'},
      <PendingDeclaration>{crn: 'NLRTM18000001', type: "SDT", timestamp: '2020-02-18T18:15:00Z', ids: ['DEBRE']},
      <PendingDeclaration>{
        crn: 'NLRTM18000001',
        type: "ENS",
        timestamp: '2020-02-18T18:15:00Z',
        ids: ['belachelijklangconsignmentnummerjonguhhhh']
      },
      <PendingDeclaration>{crn: 'NLRTM18000001', type: "COPRAR", timestamp: '2020-02-18T18:15:00Z', ids: ['19054563']}];
    return of(result).pipe(delay(100));
  };

  "com.portbase.bezoekschip.common.api.visit.GetNextCrn" = (query) => {
    const crn = "NLRTM1800000" + this.visitCounter;
    this.visitCounter = this.visitCounter + 1;
    return of(crn);
  };

  "com.portbase.bezoekschip.common.api.visit.GetVesselImageUrls" = (query) => {
    return of({"9226372": "https://static.vesselfinder.net/ship-photo/9226372-636092635-f05620d686eb86cf92e98b8d5292a06c/1?v1"})
  }

  "com.portbase.bezoekschip.common.api.visit.GetNextCallId" = (query) => {
    const callId = "" + this.callIdCounter;
    this.callIdCounter = this.callIdCounter + 1;
    return of(callId);
  };

  "com.portbase.bezoekschip.common.api.waste.collector.FindMyVehicles" = (query) => {
    return of(["ABC-12-3", "1-BCD-23"]);
  };

  "com.portbase.bezoekschip.common.api.authorisation.GetBackOfficeForOrganisation" = (query) => {
    switch (query.organisationShortName) {
      case 'SO2' :
        return of(<BezoekschipOrganisation>{
          fullName: "Back Office 1",
          shortName: "BO1",
          portAuthorityId: "PAIdBO1"
        });
      default:
        return of(null);
    }
  };

  "com.portbase.bezoekschip.common.api.visit.IsAllowedToRemovePreviousPort" = (query) => {
    return of(true);
  };

  "com.portbase.bezoekschip.common.api.visit.IsAllowedToRemoveNextPort" = (query) => {
    return of(true);
  };

  "com.portbase.bezoekschip.common.api.visit.IsAllowedToRemoveBerth" = (query) => {
    return of(true);
  };

  "com.portbase.bezoekschip.common.api.visit.GetPlanningVisitsAtBerthGroup" = (query) => {
    return of(<PlanningVisit[]>[
      {
        eta: "2019-11-26T11:15:00Z",
        etd: "2019-11-26T15:15:00Z",
        vesselName: 'MIGHTY PIPO',
        vesselImoCode: '1233456',
        berthCode: "bertie",
        berthGroupCode: "groupie",
        berthName: "bertie 1", crn: 'NLRTM20201234',
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        berthVisitId: "bla",
        ata: null,
        movementOnHold: false,
      },
      {
        eta: "2019-11-26T11:15:00Z",
        etd: "2019-11-26T15:15:00Z",
        vesselName: 'CRAZY BABA',
        vesselImoCode: '1233456', berthCode: "bertie",
        berthGroupCode: "groupie",
        berthName: "bertie 1",
        crn: 'NLRTM20201234',
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        berthVisitId: "bla",
        ata: null,
        movementOnHold: false,
        firstBerthVisit: true
      },
      {
        eta: "2019-11-26T11:30:00Z",
        etd: "2019-11-26T15:15:00Z",
        vesselName: 'KRAKEN',
        vesselImoCode: '1233456',
        berthCode: "hee, bert",
        berthGroupCode: "groupie",
        berthName: "bertie en ernie", crn: 'NLRTM20201234',
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: "NLRTM0123123",
        berthVisitId: "bla",
        ata: null,
        movementOnHold: false,
        firstBerthVisit: true
      },
      {
        eta: "2019-11-26T11:00:00Z",
        etd: "2019-11-26T15:00:00Z",
        vesselName: 'MIGHTY PIPO',
        vesselImoCode: '1233456',
        crn: 'NLRTM_INCOMING',
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        berthVisitId: "bla",
        ata: null,
        movementOnHold: false,
        firstBerthVisit: true
      },
      {
        eta: "2019-11-26T11:15:00Z",
        etd: null,
        vesselName: 'MIGHTY LEN',
        vesselImoCode: '1233456',
        crn: 'NLRTM20201234',
        orderStatusDeparture: "ORDERED",
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        berthVisitId: "bla",
        ata: "2019-11-26T11:30:00Z",
        movementOnHold: false,
        firstBerthVisit: true
      },
      {
        eta: "2019-11-26T11:30:00Z",
        etd: "2019-11-26T15:30:00Z",
        vesselName: 'LAZY PONE',
        vesselImoCode: '1233456',
        crn: 'NLRTM20201234',
        berthVisitId: "bla",
        ata: "2019-11-27T03:30:00Z",
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        movementOnHold: false,
        firstBerthVisit: true
      },
      {
        eta: null,
        etd: null,
        vesselName: 'KRAKEN-2',
        vesselImoCode: '1233456',
        crn: 'NLRTM20201234',
        orderStatusDeparture: null,
        orderStatusArrival: null,
        crnEntryAfter: null,
        crnExchange: null,
        berthVisitId: "bla",
        ata: null,
        movementOnHold: false,
        firstBerthVisit: true
      }
    ]);
  };

  "com.portbase.bezoekschip.common.api.authorisation.GetCurrentOrganisationPreferences" = (query) => {
    return of(BezoekschipQueryHandlerMock.organisationPreferences);
  };

  "com.portbase.bezoekschip.common.api.authorisation.GetCurrentUserPreferences" = (query) => {
    return of(BezoekschipQueryHandlerMock.userPreferences);
  };

  'com.portbase.bezoekschip.common.api.history.GetVisitLog' = query => {
    return <VisitLogItem[]>[
      {
        user: "aap@wasser.com",
        action: "Visit created",
        crn: "departedFromFirstBerth",
        timestamp: "2019-11-22T09:19:20.156Z",
        eventId: "123"
      },
      {
        user: "aap@wasser.com",
        action: "Visit information declared",
        crn: "departedFromFirstBerth",
        timestamp: "2019-11-22T09:19:30.156Z",
        eventId: "1234"
      },
      {
        user: "aap@wasser.com",
        action: "Visit information accepted",
        crn: "departedFromFirstBerth",
        timestamp: "2019-11-22T09:19:40.156Z",
        eventId: "12345"
      },
      {
        user: "aap@wasser.com",
        action: "Movement ordered from berth R1234 by phone",
        crn: "departedFromFirstBerth",
        timestamp: "2019-11-22T09:19:40.156Z",
        eventId: "123456"
      },
      {
        user: "aap@wasser.com",
        action: "Visit information declared\nMovement ordered from berth R1244\nMovement ordered from berth R1245\nMovement ordered from berth R1246",
        crn: "departedFromFirstBerth",
        timestamp: "2019-11-22T09:19:40.156Z",
        eventId: "123457"
      }
    ]
  };

  'com.portbase.bezoekschip.common.api.waste.collector.FindTemplateCollectorSummary' = query => {
    return of(<CollectorSummary []>[
      {
        "crn": "NLRTM21901232",
        "portUnCode": "NLRTM",
        "portName": "Rotterdam",
        "vesselName": "PRIDE OF ROTTERDAM",
        "vesselImoCode": "9208617",
        "vesselFlagCode": "NLD",
        "vesselCallSign": "PBAJ",
        "shipOperatorName": "P&O North Sea Ferries B.V.",
        "shipOperatorZipCode": "3198 LG",
        "shipOperatorAddress": "Luxemburgweg 2",
        "collectorShortName": null,
        "startTime": null,
        "endTime": null,
        "etaPickup": "2021-07-02T09:00:00Z",
        "visitStatus": "EXPECTED",
        "assignedCollectors": [
          "CIMS",
          "DUTCHWASTE",
          "ATM1"
        ],
        "expectedItems": [
          {
            "ssn": {
              code: "FOOD_WASTE",
              description: "FOOD_WASTE description",
              marpolCategory: "Annex I",
              specificationRequired: false
            },
            "berthIdForCollection": "6569f0c0-da7f-4d12-8947-80759b0aebc9",
            "quantityToBeCollected": 20,
            "specification": null
          },
          {
            "ssn": {
              code: "PLASTIC_CLEAN",
              description: "PLASTIC_CLEAN description",
              marpolCategory: "Annex I",
              specificationRequired: false
            },
            "berthIdForCollection": "6569f0c0-da7f-4d12-8947-80759b0aebc9",
            "quantityToBeCollected": 20,
            "specification": null
          },
          {
            "ssn": {
              code: "PLASTIC_CONTAMINATED",
              description: "PLASTIC_CONTAMINATED description",
              marpolCategory: "Annex I",
              specificationRequired: false
            },
            "berthIdForCollection": "6569f0c0-da7f-4d12-8947-80759b0aebc9",
            "quantityToBeCollected": 3,
            "specification": null
          }
        ],
        "collections": [],
        "berthVisits": [
          {
            "id": "6569f0c0-da7f-4d12-8947-80759b0aebc9",
            "code": "R5807",
            "name": "BENELUXH P&O OZ Z",
            "eta": "2021-07-02T09:00:00Z",
            "etd": "2021-07-02T20:30:00Z",
            "ata": null,
            "atd": null
          }
        ],
        "template": true
      },
      {
        "crn": "NLAMS21900301",
        "portUnCode": "NLAMS",
        "portName": "Amsterdam",
        "vesselName": "RED BARONESS",
        "vesselImoCode": "7802392",
        "vesselFlagCode": "BOL",
        "vesselCallSign": "CPB886",
        "shipOperatorName": "POAVTMS",
        "shipOperatorZipCode": "0200 AM",
        "shipOperatorAddress": "De Ruyterkade 7",
        "collectorShortName": null,
        "startTime": null,
        "endTime": null,
        "etaPickup": "2021-07-13T07:00:00Z",
        "visitStatus": "EXPECTED",
        "assignedCollectors": [
          "ATM1",
          "CIMS"
        ],
        "expectedItems": [
          {
            "wasteType": "SLUDGE",
            "berthIdForCollection": "08589da0-d1cb-4a12-9a31-e9eacc2bc642",
            "quantityToBeCollected": 1,
            "specification": null
          }
        ],
        "collections": [],
        "berthVisits": [
          {
            "id": "08589da0-d1cb-4a12-9a31-e9eacc2bc642",
            "code": "WHAW2",
            "name": "Westhaven Waterland Terminal 2",
            "eta": "2021-07-13T07:00:00Z",
            "etd": "2021-07-14T10:00:00Z",
            "ata": null,
            "atd": null
          },
          {
            "id": "23f95177-79d1-4510-9ad6-b9799da38ea0",
            "code": "MIWZZ",
            "name": "Minervahaven Wachtsteiger Zeevaart Zuidzijde",
            "eta": "2021-07-14T16:00:00Z",
            "etd": "2021-07-15T16:00:00Z",
            "ata": null,
            "atd": null
          }
        ],
        "template": true
      },
      {
        "crn": "NLRTM21901230",
        "portUnCode": "NLRTM",
        "portName": "Rotterdam",
        "vesselName": "COSCO JAPAN",
        "vesselImoCode": "9448748",
        "vesselFlagCode": "HKG",
        "vesselCallSign": "VRFX5",
        "shipOperatorName": "Cross-Ocean B.V.",
        "shipOperatorZipCode": "3013 AP",
        "shipOperatorAddress": "Conradstraat 39",
        "collectorShortName": null,
        "startTime": null,
        "endTime": null,
        "etaPickup": "2021-07-02T09:30:00Z",
        "visitStatus": "EXPECTED",
        "assignedCollectors": [
          "ATM1",
          "SWOC"
        ],
        "expectedItems": [
          {
            "wasteType": "FOOD_WASTE",
            "berthIdForCollection": "97c4b256-4d37-4d42-9928-b7a7730a1486",
            "quantityToBeCollected": 1,
            "specification": null
          }
        ],
        "collections": [],
        "berthVisits": [
          {
            "id": "97c4b256-4d37-4d42-9928-b7a7730a1486",
            "code": "R8150",
            "name": "AMAZONEH ECT DDE",
            "eta": "2021-07-02T09:30:00Z",
            "etd": null,
            "ata": null,
            "atd": null
          }
        ],
        "template": true
      },
      {
        "crn": "NLRTM21901234",
        "portUnCode": "NLRTM",
        "portName": "Rotterdam",
        "vesselName": "MSC VIRGO",
        "vesselImoCode": "9857171",
        "vesselFlagCode": "PRT",
        "vesselCallSign": "CQEB9",
        "shipOperatorName": "Cross-Ocean B.V.",
        "shipOperatorZipCode": "3013 AP",
        "shipOperatorAddress": "Conradstraat 39",
        "collectorShortName": null,
        "startTime": null,
        "endTime": null,
        "etaPickup": "2021-07-02T12:30:00Z",
        "visitStatus": "EXPECTED",
        "assignedCollectors": [
          "ATM1",
          "OMEGA"
        ],
        "expectedItems": [
          {
            "wasteType": "FOOD_WASTE",
            "berthIdForCollection": "99fbd340-0f3b-46ec-b4f8-df11d6dd53a0",
            "quantityToBeCollected": 20,
            "specification": null
          }
        ],
        "collections": [],
        "berthVisits": [
          {
            "id": "99fbd340-0f3b-46ec-b4f8-df11d6dd53a0",
            "code": "R8162",
            "name": "AMAZONEH ECT DDE",
            "eta": "2021-07-02T12:30:00Z",
            "etd": "2021-07-02T13:00:00Z",
            "ata": null,
            "atd": null
          },
          {
            "id": "6cc8a83b-e4df-4509-8c76-8ee6d89d731d",
            "code": "R8153",
            "name": "AMAZONEH ECT DDE",
            "eta": "2021-07-02T13:30:00Z",
            "etd": "2021-07-02T14:00:00Z",
            "ata": null,
            "atd": null
          }
        ],
        "template": true
      }
    ]);
  }
  'com.portbase.bezoekschip.common.api.admin.SearchFlux' = query => {
    return {
      "hits": [
        {
          "metadata": {
            "$clientName": "bezoekschip-adapter",
            "$SENDER": "{\"userName\":\"portvisit@RWG\",\"roles\":[\"HinterlandTerminal\",\"SystemUsers\"],\"organisationShortName\":\"RWG\",\"coc\":\"24420010\",\"ean\":\"9847458294857\",\"eori\":\"NL818363915\",\"scac\":null,\"authorisingOrganisations\":[],\"givenAuthorisations\":{},\"impersonator\":null,\"name\":\"portvisit@RWG\",\"customs\":false,\"readerAdmin\":false,\"admin\":false}",
            "$clientId": "3d9c3354-ec43-486d-87c9-cb78b0466b22"
          },
          "@timestamp": "2021-10-25T18:13:50.324Z",
          "messageIndex": 107163525469175808,
          "messageType": "COMMAND",
          "payload": {
            "rawXmlPayload": "<?xml version=\"1.0\" encoding=\"UTF-8\"?><terminalPlanning/>"
          },
          "created": "2021-10-25T18:13:50.324Z",
          "requestId": 0,
          "segment": 49,
          "messageId": "c3f31fc8-cfed-4bb9-ab2e-bcc0c6b45d83",
          "source": "3d9c3354-ec43-486d-87c9-cb78b0466b22",
          "type": "com.portbase.bezoekschip.common.api.terminal.rwg.ProcessRwgTerminalPlanning",
          "revision": 0
        }
      ],
      "total": 25,
      "histogram": {
        "since": "2021-10-24T20:08:31.692Z",
        "before": null,
        "counts": [0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 0, 0, 5, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  };

  private assignVisitSummaryToCategory(visit, visitOverview) {
    let visitSummary = this.toVisitSummary(visit);
    if (visitSummary.cancelled) {
      visitOverview.cancelledVisits.push(visitSummary);
    } else if (visitSummary.planned) {
      visitOverview.plannedVisits.push(visitSummary);
    } else {
      if (!visitSummary.ataPort && !visitSummary.atdPort) {
        visitOverview.expectedVisits.push(visitSummary);
      }
      if (visitSummary.ataPort && !visitSummary.atdPort) {
        visitOverview.arrivedVisits.push(visitSummary);
      }
      if (visitSummary.atdPort) {
        visitOverview.departedVisits.push(visitSummary);
      }
    }
  }

  toVisitSummary(visit: Visit): VisitSummary {
    return <VisitSummary>{
      crn: visit.crn,
      portName: visit.portOfCall.port.name,
      vesselName: visit.vessel.name,
      ataPPA: visit.visitDeclaration.portVisit.passages?.find(p => p.code === PassageCode.PPA)?.ata,
      imoCode: visit.vessel.imoCode,
      owner: visit.owner.fullName,
      ownerShortName: visit.owner.shortName,
      declarant: visit.declarant.fullName,
      etaPort: visit.visitDeclaration.portVisit.etaPort,
      etaPortAis: visit.etaPortAis,
      etdPort: visit.visitDeclaration.portVisit.etdPort,
      ataPort: $try(() => visit.visitDeclaration.portVisit.berthVisits[0].ata),
      atdPort: visit.visitDeclaration.portVisit.atdPort,
      ignoreEtaPortAis: visit.ignoreEtaPortAis,
      nextBerthDeparture: this.getBerthVisitSummary(visit.visitDeclaration.portVisit.berthVisits),
      clientReferenceNumber: visit.visitDeclaration.clientReferenceNumber,
      allowedViewers: [visit.owner.shortName, visit.declarant.shortName, "BO1"],
      orderStatus: this.getOrderStatus(visit),
      harbourMasterRemarks: this.getHarbourMasterInfo(visit)?.remarks?.text,
      oldVisit: false,
      cancelled: visit.cancelled,
      planned: visit.planned,
      openTasks: this.getOpenTasks(visit),
      rejectedTasks: this.getRejectedTasks(visit),
      conditionallyApprovedTasks: this.getConditionallyApprovedTasks(),
      cargoAgentStatuses: this.getCargoAgentStatuses(visit),
      visitStatus: this.calculateVisitStatus(visit),
      clearances: visit.clearances,
      movementForEkhClearance: this.getMovementForEkhClearance(visit),
      tidalShip: (visit.incomingTidalWindowStatus && visit.incomingTidalWindowStatus.tidalShip)
        || lodash.values(visit.berthVisitInfos).some(info => info && info.harbourMasterInfo &&
          info.harbourMasterInfo.tidalWindowStatus && info.harbourMasterInfo.tidalWindowStatus.tidalShip),
      entryPoint: visit.visitDeclaration.portVisit.entryPoint,
      exitPoint: visit.visitDeclaration.portVisit.exitPoint,
      taskStatuses: this.getAllTasks(visit),
      berthVisitSearches: visit.visitDeclaration.portVisit.berthVisits
        .map(bv => {
          const terminalVisit = VisitContext.getTerminalVisit(bv, visit);
          return <BerthVisitSearch>{
            code: bv.berth?.code,
            name: bv.berth?.name,
            terminalName: bv.terminal?.terminalName || bv.berth?.terminalName,
            stevedore: bv.stevedore?.fullName,
            eta: bv.eta,
            etd: bv.etd,
            ata: bv.ata,
            atd: bv.atd,
            terminalStatus: {
              status: this.messageStatusToTaskStatus(VisitContext.getTerminalDeclarationStatus(bv, visit)),
              rejectReasons: terminalVisit?.status?.rejectReasons
            }
          };
        })
        .reverse(),
      vessel: {
        name: visit.vessel.name,
        length: visit.vessel.fullLength,
        width: visit.vessel.maxWidth,
        grossTonnage: visit.vessel.grossTonnage
      },
      lastEditedBy: {
        [visit.owner.shortName]: {
          name: visit.owner.fullName,
          timestamp: visit.visitDeclaration.portVisit.etaPort
        },
        [visit.declarant.shortName]: {
          name: visit.declarant.fullName,
          timestamp: visit.visitDeclaration.portVisit.etdPort
        }
      }
    };
  }

  private messageStatusToTaskStatus = (messageStatus: MessageStatus): TaskMessageStatus => {
    switch(messageStatus) {
      case MessageStatus.UNKNOWN: return TaskMessageStatus.DISABLED;
      case MessageStatus.PENDING: return TaskMessageStatus.PENDING;
      case MessageStatus.DELIVERED: return TaskMessageStatus.DELIVERED;
      case MessageStatus.WARNING: return TaskMessageStatus.WARNING;
      case MessageStatus.ACCEPTED: return TaskMessageStatus.ACCEPTED;
      case MessageStatus.REJECTED: return TaskMessageStatus.REJECTED;
    }
  }

  private calculateVisitStatus = (visit: Visit): VisitSummaryStatus => {
    const berthVisits = visit.visitDeclaration.portVisit.berthVisits;
    if (visit.cancelled) {
      return VisitSummaryStatus.CANCELLED;
    }
    if (visit.terminalPlanningEnabled && !visit.terminalPlanningApproved) {
      return VisitSummaryStatus.PLANNED;
    }
    if (berthVisits.every(bv => !bv.ata)) {
      return VisitSummaryStatus.EXPECTED;
    }
    else if (berthVisits.some(bv => bv.ata) && !berthVisits.every(bv => bv.atd)) {
      return VisitSummaryStatus.ARRIVED;
    }
    return VisitSummaryStatus.DEPARTED;
  }

  private getMovementForEkhClearance(visit: Visit) {
    const berthVisits = visit.visitDeclaration.portVisit.berthVisits;
    switch (visit.visitStatus) {
      case 'EXPECTED':
        return this.incomingMovement(berthVisits.length == 0 ? null : berthVisits[0].id);
      case 'ARRIVED':
        return this.nextOrCurrentMovement(berthVisits);
      case 'DEPARTED':
        return null;
    }
  }

  private incomingMovement(berthVisitId: String) {
    return {from: null, to: berthVisitId}
  }

  private nextOrCurrentMovement(berthVisits: BerthVisit[]) {
    const berthVisitsReversed = berthVisits.reverse();
    let fromId = berthVisitsReversed.find(value => !!value.ata)?.id; // last having ATA
    let toId = berthVisits.find(value => !value.ata && !value.atd)?.id; // first expected
    return {from: fromId, to: toId}
  }

  private getHarbourMasterInfo(visit: Visit) {
    if (!visit.visitDeclaration.portVisit.ataPort) {
      return visit.incomingMovementHarbourMasterInfo ? visit.incomingMovementHarbourMasterInfo : null;
    } else {
      return visit.berthVisitInfos && visit.berthVisitInfos[visit.visitDeclaration.portVisit.berthVisits[0].id] ?
        visit.berthVisitInfos[visit.visitDeclaration.portVisit.berthVisits[0].id].harbourMasterInfo : null;
    }
  }

  private getRejectedTasks(visit: Visit) {
    let declarationTypes = visit.declarations.filter(declaration => declaration.status === "REJECTED")
      .map(declaration => declaration.type);
    return lodash.uniq(declarationTypes);
  }

  private getAllTasks = (visit: Visit) => {
    const tasks: { [P in DeclarationType]?: TaskStatus } = {};
    const allTypes = <DeclarationType[]>["VISIT","SECURITY","HEALTH","WASTE","DANGEROUS_GOODS",
      "BUNKERING","TERMINAL_PLANNING","WPCS","NOA", "NOD", "PAX", "MSV", "ATA", "ATD", "COA", "EXP", "SEC", "WAR",
      "WAS", "HZA", "HZD", "ENS", "SDT","COPRAR"];
    const latestDeclarations = allTypes.map(type => {
      const declarations = visit.declarations.filter(d => d.type === type);
      return declarations.length > 0 ? declarations[declarations.length - 1] : null;
    }).filter(value => value != null);

    allTypes.forEach(t => {
      var latestDeclaration = latestDeclarations.find(d => d.type === t);
      if (latestDeclaration) {
        tasks[t] = {
          status: latestDeclaration.status === DeclarationStatus.DECLARED
            ? TaskMessageStatus.PENDING
            : (latestDeclaration.status === DeclarationStatus.ACCEPTED ? TaskMessageStatus.DELIVERED : TaskMessageStatus.REJECTED),
          rejectReasons: latestDeclaration.status === DeclarationStatus.REJECTED ? [latestDeclaration.rejectReasons] : null
        }
      }
    })
    return tasks;
  }

  private getOpenTasks(visit: Visit) {
    const allTasks = <DeclarationType[]>["VISIT", "MSV", "SECURITY", "HEALTH", "WASTE", "PAX", "DANGEROUS_GOODS"];
    const notOpenTasks = visit.declarations.map(declaration => declaration.type);
    return allTasks.filter(declarationType => !notOpenTasks.find(x => x === declarationType));
  }

  private getConditionallyApprovedTasks() {
    return <DeclarationType[]>[];
  }

  private getCargoAgentStatuses(visit: Visit): { [index: string]: CargoAgentStatus } {
    const result: { [index: string]: CargoAgentStatus } = {};
    visit.cargoDeclarants.forEach(c => {
      if (c.shortName === visit.declarant.shortName || c.shortName === visit.owner.shortName) {
        return;
      }
      const d = visit.dangerousGoodsDeclarations
        && visit.dangerousGoodsDeclarations.find(d => d.cargoDeclarant.shortName === c.shortName);
      if (d && d.completed) {
        result[c.shortName] = {
          dangerousGoodsCompleted: true,
          cargoImportEnabled: true,
          cargoImportStatus: null,
          cargoSearchTerms: null
          , inspectionsStarted: true, inspectionsCompleted: true,
          shortlandedContainers: 3,
          overlandedContainers: 1
        };
      } else {
        result[c.shortName] = {
          dangerousGoodsCompleted: false,
          cargoImportEnabled: true,
          cargoImportStatus: null,
          cargoSearchTerms: null
          , inspectionsStarted: true, inspectionsCompleted: false,
          shortlandedContainers: 1,
          overlandedContainers: 0
        };
      }
    });
    return result;
  }

  getBerthVisitSummary(berthVisits: BerthVisit[]): NextDeparture {
    for (let berthVisit of berthVisits) {
      if (!berthVisit.atd) {
        if (berthVisit.berth) {
          return <NextDeparture>{
            name: berthVisit.berth.name,
            code: berthVisit.berth.code,
            etd: berthVisit.etd
          }
        }
      }
    }
    return null;
  }

  private getOrderStatus(visit: Visit) {
    if (visit.crn === 'NLRTM18000001') {
      return 'ETD_CHANGED';
    }
    if (visit.crn === 'fullNota') {
      return 'CONFIRMED';
    }
    if (visit.crn === 'arrivedDepartsLaterVisit') {
      return 'REJECTED';
    }
    if (visit.crn === 'departedFromFirstBerth') {
      return 'ORDERED';
    }
    return undefined;
  }
}
