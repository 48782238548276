import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PortvisitUtils} from '../../../refdata/portvisit-utils';
import {
  BezoekschipOrganisation,
  GuaranteeData,
  ModeOfTransport,
  SecurityDetails,
  Terminal,
  TransitDeclaration
} from '@portbase/bezoekschip-service-typescriptmodels';
import {map} from "rxjs/operators";
import {lodash, removeItem} from '../../../common/utils';
import {addEmptyGuarantee} from '../../transit.utils';
import moment from 'moment';
import {
  TransitDeclarationSendTriggerInfoComponent
} from "../../transit-declaration-send-trigger-info/transit-declaration-send-trigger-info.component";
import {AppContext} from "../../../app-context";
import {DaysComponent} from "../../../common/duration/days.component";

@Component({
  selector: 'app-transit-details',
  templateUrl: './transit-details.component.html',
  styleUrls: ['./transit-details.component.css']
})
export class TransitDetailsComponent implements OnInit {
  appContext = AppContext;
  refData = PortvisitUtils;
  allTransitGuarantees: GuaranteeData[] = [];
  @Input() declaration: TransitDeclaration;
  @Input() isTemplate: boolean = false;
  @Input() isGeneric: boolean = false;
  @Input() displaySendDate: boolean = true;
  @Input() isSimplifiedProcedure: boolean = true;

  @ViewChild(TransitDeclarationSendTriggerInfoComponent) sendTriggerInfoComponent: TransitDeclarationSendTriggerInfoComponent;

  ngOnInit(): void {
    this.refData.getGuarantees(this.declaration.declarantShortName).subscribe(values => this.allTransitGuarantees = lodash.map(values, value => {
      const result : any = value.guaranteeData;
      result.balance = value.balance;
      return result;
    }));
    this.recalculateExpiryDate();
  }

  findTransitParties = term => this.refData.findTransitParties(term, this.declaration.declarantShortName);

  findContainerOperators = term => this.refData.findContainerOperators(term)
    .pipe(map(carriers => lodash.filter(carriers, carrier => carrier && carrier.customsId)));

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  transitGuaranteeFormatter = (g: GuaranteeData) => {
    if (g.guaranteeReferenceNumber) {
      const balance: string = g['balance'] && (g['balance'].amount + ' ' + g['balance'].currency.unCode);
      return g.guaranteeReferenceNumber + ' - ' + g.accessCode + (balance ? ' (balance: ' + balance + ')' : '');
    } else if (g.guaranteeReferenceNumber) {
      return g.otherReference;
    } else {
      return g.type.code + " (" + g.type.description + ")";
    }
  }

  addGuarantee = () => {
    addEmptyGuarantee(this.declaration);
  };

  removeGuarantee = (guarantee: GuaranteeData) => () => removeItem(this.declaration.data.guarantees, guarantee);

  setTerminal = (terminal: Terminal) => {
    let currentTerminal = this.declaration.data.dischargeTerminal;
    if (!(terminal && terminal.organisationShortName) && currentTerminal && currentTerminal.organisationShortName) {
      this.declaration.data.placeOfLoading = null;
      this.declaration.data.customsSubPlace = null;
      this.declaration.data.authorizedLocation = null;
      this.declaration.data.declarationPlace = null;
    }
    this.declaration.data.dischargeTerminal = terminal;
    if (terminal && terminal.organisationShortName) {
      PortvisitUtils.getOrganisationByShortName(terminal.organisationShortName)
        .subscribe((organisation: BezoekschipOrganisation) => {
          if (terminal && terminal.organisationShortName) {
            let houseNumber = organisation.arrivalLocationHouseNumber;
            if (!houseNumber) {
              let addressParts = organisation.address.split(' ');
              houseNumber = addressParts.length > 0 ? addressParts[addressParts.length - 1] : '';
            }
            let zipCode = organisation.customsZipCode ? organisation.customsZipCode : organisation.zipCode;
            this.declaration.data.placeOfLoading = zipCode + ' ' + houseNumber;
            this.declaration.data.customsSubPlace = zipCode;
            this.declaration.data.authorizedLocation = this.isSimplifiedProcedure ? this.declaration.data.placeOfLoading : null;
            this.declaration.data.declarationPlace = organisation.city;
          }
        });
    }
  }

  setTransitGuarantee = (value: GuaranteeData, index: number) => {
    console.log("value : ", value);
    this.declaration.data.guarantees[index] = value;
  };

  get today(): string {
    return moment().startOf("day").toISOString()
  };

  updateSecurityAndItinerary() {
    if (!this.declaration.data.securityActivated) {
      this.declaration.data.securityDetails = <SecurityDetails>{};
    }
    if (!this.isItineraryAllowed()) {
      this.declaration.data.itinerary = [];
    }
  }

  isItineraryAllowed() {
    return this.declaration.data.securityActivated || this.declaration.data.bindingItinerary;
  }

  formatProcedure(value: boolean): string {
    return value ? "simplified" : "normal"
  }

  changeProcedure = () => {
    this.declaration.data.transportTerm = null;
    this.declaration.requestedTransportExpiryDate = undefined;
    if (!this.isSimplifiedProcedure) {
      this.declaration.data.authorizedLocation = null;
    }
  }

  recalculateExpiryDate() {
    this.declaration.requestedTransportExpiryDate = moment(this.declaration.status.sent?.date || this.declaration.status.sendDate)
        .add(DaysComponent.getDays(this.declaration.data.transportTerm), 'days')
        .toISOString();
  }

  isEditable = (): boolean => {
    switch (this.declaration.status.phase) {
      case 'undeclared':
      case 'rejected':
      case 'declared':
        return this.appContext.isTransitEditor();
    }
    return !this.declaration.status.phase;
  };

  isContainerDeclaration(): boolean {
    return this.declaration.data.containerNumber || this.declaration["isContainerDeclaration"];
  }

  showModeOfTransportWarning(modeOfTransport: ModeOfTransport): boolean {
    return modeOfTransport === "NOT_SPECIFIED";
  }

  get requestedTransportExpiryDate() {
    const transportExpiryDate = this.declaration.transportExpiryDate;

    if (!transportExpiryDate) {
      return null;
    } else {
      return moment(transportExpiryDate).format('YYYY-MM-DD');
    }
  }

  get transportExpiryDate() {
    const requestedTransportExpiryDate = this.declaration.requestedTransportExpiryDate;

    if (!requestedTransportExpiryDate) {
      return null;
    } else {
      return moment(requestedTransportExpiryDate).format('YYYY-MM-DD');
    }
  }

  transitOfficesRequired(): boolean {
    let destinationCountry = this.declaration.data?.destinationsOffice?.unCode?.substring(0, 2);
    return this.isTemplate == false &&
      this.declaration.data?.type?.code != 'TIR' &&
      this.declaration.data?.type?.code != 'T2SM' &&
      ['CH', 'GB', 'IS', 'MK', 'NO', 'RS', 'TR', 'UA'].includes(destinationCountry);
  }
}
