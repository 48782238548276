<div class="row sticky-top bg-white" style="top:6.125rem">
  <div class="col-md-12">
    <app-alerting></app-alerting>
  </div>
</div>

<div class="row mt-3 mb-3 justify-content-between align-items-end align-items-center">
  <div class="col" *ngIf="appContext.isAdmin()">
    <div class="row">
      <div class="col-7" style="height: 38px">
        <app-form-group label="Short name">
          <app-search [ngModel]="filterOrganisationShortName" [searchFunction]="searchOrganisation"
                      (ngModelChange)="setOrganisationName($event)" [resultFormatter]="formatter"
                      placeholder="Find by organisation">
          </app-search>
        </app-form-group>
      </div>
    </div>
  </div>
  <div class="col" style="height: 38px">
    <app-form-group label="Search">
      <input [(ngModel)]="searchTerm" (change)="reload()" placeholder="search term">
    </app-form-group>
  </div>

  <div class="col text-end d-flex align-items-center" *ngIf="appContext.isTransitEditor()">
    <a href="#" class="nav-link p-0" (click)="$event.preventDefault(); add()" style="width: 100%;">
      <span class="pe-2 fa fa-plus-circle"></span>Add transit template</a>
  </div>
</div>

<app-pagination #pagination [items]="values" class="accordion" id="releaseAccordion">
  <div *ngFor="let value of pagination.getItemsOnPage(); let index = index; trackBy: trackById" class="my-2"
       [ngClass]="{'fieldset disabled' : !appContext.isTransitEditor()}">
    <app-collapse [collapse]="valueTemplate" [showOnLoad]="!value.id" [deleteHandle]="remove(value)">
      <div class="row g-0 align-items-center">
        <div class="col-auto mx-2 p-2">
          <span class="fas fa-copy text-dark"></span>
        </div>
        <div class="col text-dark">
          <span class="d-inline-block align-top text-truncate">{{value.name || 'New transit template'}}</span>
        </div>
        <div class="col-auto d-none d-md-block small text-secondary" style="min-width: 12rem"
             *ngIf="appContext.isAdmin()">
          <span class="fa fa-fw fa-users"></span>
          <span class="ms-1">{{value.ownerShortName}}</span>
        </div>
      </div>
    </app-collapse>

    <ng-template #valueTemplate>
      <div class="collapse">
        <div class="ms-4 position-relative">
          <form (ngSubmit)="save(value) ? null : $event.stopPropagation();">
            <div class="row mb-2">
              <div class="col-auto ms-auto mt-2">
                <div class="btn-group">
                  <button class="btn btn-outline-info" type="submit" tabindex="-1">Save</button>
                </div>
              </div>
            </div>
            <app-tab-panel>
              <app-tab-item [templateRef]="detailsRef">
                <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!value['ngDetailsInvalid']}">Details</span>
                <ng-template #detailsRef>
                  <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="value"
                       validatorProperty="ngDetailsInvalid">
                    <app-transit-template [model]="value"></app-transit-template>
                  </div>
                </ng-template>
              </app-tab-item>
            </app-tab-panel>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</app-pagination>

<div *ngIf="values.length === 0" class="text-center text-muted" style="padding: 20px 0">
  You haven’t added any transit templates yet.
</div>
