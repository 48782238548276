import {Component, Input} from '@angular/core';
import {IE3GoodsItemSummary} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "../../../consignment.utils";

@Component({
  selector: 'app-consignment-goods-column',
  templateUrl: './consignment-goods-column.component.html',
  styleUrls: ['./consignment-goods-column.component.scss']
})
export class ConsignmentGoodsColumnComponent {
  @Input() term: string;
  @Input() goods: IE3GoodsItemSummary[];
  @Input() ellipsisCharacterCount: number = undefined;

  trackByGoodsItem = (index: number, record: IE3GoodsItemSummary) => record.goodsItemNumber;

  goodsAsText = (goodsItem: IE3GoodsItemSummary) => ConsignmentUtils.goodsItemsAsText(goodsItem);
}
