import {
  AdditionalIncomingMovementInfo,
  BaseForPlanning,
  BerthVisit,
  BunkerOperatorInfo, CargoDescription,
  Declaration,
  DeclarationStatus,
  DeclarationType,
  EntryDependency,
  GetTravelTimeToFirstBerth,
  HarbourMasterInfo,
  ImportDeclaration,
  IncomingOrder,
  IsAllowedToRemoveNextPort,
  IsAllowedToRemovePreviousPort,
  LoadingDeclaration,
  LocalMovement,
  MessageStatus,
  NextPort,
  Port,
  PortVisit,
  PreviousPort,
  SeaEntry,
  StormPilotageInformation,
  TaskMessageStatus,
  TerminalVisit,
  Visit,
  VisitStatus
} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {cloneObject, dispatchChangeEvent, lodash, removeAll, scrollToTop, sendQuery} from '../common/utils';
import {environment} from '../../environments/environment';
import {VisitMock} from '../mocking/visit-details.mock';
import {AppContext} from '../app-context';
import {v4 as uuid} from 'uuid';
import {DangerousGoodsDeclarationModel} from './dangerous-goods/dangerous-goods.model';
import {Observable} from "rxjs";
import {CargoImportModel} from './cargo-import/cargo-import.model';
import {CargoLoadingModel} from "./cargo-loading/cargo-loading.model";
import {LoadDischargeInfo} from "./visit/berth-visit/berth-visit-details/multi-berth/multi-berth.component";
import {cloneDeep} from "lodash";
import newCargoScreenSettings from "./new-cargo-screen-settings.json";

declare var $: any;

export class VisitContext {
  static savedVisit: Visit;
  static visit: Visit;
  static eventId: string;
  static dangerousGoodsDeclaration: DangerousGoodsDeclarationModel;
  static savedImportDeclaration: ImportDeclaration;
  static cargoImportModel: CargoImportModel;
  static savedLoadingDeclaration: LoadingDeclaration;
  static cargoLoadingModel: CargoLoadingModel;
  static shiftTimes: Boolean = null;
  static latestDeclarations: Declaration[] = [];
  static paxDeclarations: Declaration[] = [];
  static healthDeclarations: Declaration[] = [];
  static multiBerthLoadDischargeInfo: Map<string, LoadDischargeInfo[]> = new Map<string, LoadDischargeInfo[]>();
  static maxVesselDraughtInHarbour: number = 25;
  static maximumTankerLengthLOA: number = 125;
  static messageStatusOrdered: MessageStatus[] = [
    MessageStatus.DISABLED, MessageStatus.UNKNOWN, MessageStatus.ACCEPTED, MessageStatus.DELIVERED,
    MessageStatus.PENDING, MessageStatus.WARNING, MessageStatus.REJECTED
  ];
  static taskMessageStatusOrdered: TaskMessageStatus[] = [
    TaskMessageStatus.ACCEPTED, TaskMessageStatus.ACCEPTED_WITH_CONDITIONS,
    TaskMessageStatus.PENDING, TaskMessageStatus.DELIVERED,
    TaskMessageStatus.UNKNOWN, TaskMessageStatus.SAVED,
    TaskMessageStatus.WARNING, TaskMessageStatus.DEADLINE_CLOSE,
    TaskMessageStatus.DEADLINE_MISSED, TaskMessageStatus.REJECTED, TaskMessageStatus.ERROR
  ];

  static planningBasedOnPilotBoarding() {
    let portVisitDecl = this.visit.visitDeclaration.portVisit;
    return portVisitDecl.portEntry && portVisitDecl.portEntry.baseForPlanning === 'PILOT_BOARDING_PLACE';
  }

  static replaceVisit(visit: Visit, eventId?: string) {
    if (!visit) {
      delete this.visit;
      delete this.savedVisit;
      this.shiftTimes = null;
      return;
    }
    visit.dangerousGoodsDeclarations = visit.dangerousGoodsDeclarations || [];
    visit.visitDeclaration.portVisit.firstMovement = visit.visitDeclaration.portVisit.firstMovement || <LocalMovement>{};
    visit.additionalIncomingMovementInfo = visit.additionalIncomingMovementInfo || <AdditionalIncomingMovementInfo>{};
    visit.berthVisitInfos = visit.berthVisitInfos || {};
    visit.incomingMovementHarbourMasterInfo = visit.incomingMovementHarbourMasterInfo || <HarbourMasterInfo>{};
    visit.importDeclarations = visit.importDeclarations || [];
    visit.loadingDeclarations = visit.loadingDeclarations || [];

    if (this.isStormPilotageInfoRequiredForPortEntry(visit)) {
      visit.visitDeclaration.portVisit.firstMovement.stormPilotageInformation =
        visit.visitDeclaration.portVisit.firstMovement.stormPilotageInformation || <StormPilotageInformation>{};
    }

    this.savedVisit = visit;
    const clonedVisit = cloneObject(visit);
    this.visit = clonedVisit;
    const cargoImportModel = this.cargoImportModel;
    if (cargoImportModel) {
      const declarations = cargoImportModel.declarations;
      if (declarations) {
        const declaration = clonedVisit.importDeclarations.find(
          d => d.cargoDeclarant.shortName === cargoImportModel.cargoDeclarant.shortName);
        declarations.splice(0, declarations.length);
        declaration?.declarations?.forEach(d => declarations.push(d));
      }
      dispatchChangeEvent(document.body);
    }
    if (this.cargoLoadingModel) {
      dispatchChangeEvent(document.body);
    }
    this.latestDeclarations = computeDeclarations();

    this.paxDeclarations = VisitContext.savedVisit.declarations.filter(d => d.type === 'PAX');
    this.healthDeclarations = VisitContext.savedVisit.declarations.filter(d => d.type === 'HEALTH');

    if (!environment.production) {
      VisitMock[visit.crn] = visit;
    }
    this.eventId = eventId;

    function computeDeclarations(): Declaration[] {
      const result = ['VISIT', 'TERMINAL_PLANNING', 'SECURITY', 'WASTE', 'HEALTH', 'PAX', 'MSV', 'WPCS', 'DANGEROUS_GOODS', 'NOA', 'NOD']
        .map(type => {
          const declarations = VisitContext.savedVisit.declarations.filter(d => d.type === type);
          return declarations.length > 0 ? declarations[declarations.length - 1] : null;
        }).filter(value => value != null);

      if (visit.cargoImportEnabled) {
        const cargoDeclaration = reduceCargoDeclaration(visit.importDeclarations
          .filter(d => d.cargoDeclarant.cargoImportEnabled));
        if (cargoDeclaration) {
          result.push(cargoDeclaration);
        }
      }
      return result;
    }
  }

	static findLatestDeclaration(type: DeclarationType) {
		return this.latestDeclarations.find(d => d.type === type);
	}

	static hasBackOffice() {
		return this.visit.declarant.shortName !== this.visit.owner.shortName;
	}

	static hasNextBackOffice() {
    if (!this.visit.nextDeclarant || !this.visit.nextOwner) {
      return false;
    }
    return this.visit.nextDeclarant.shortName !== this.visit.nextOwner.shortName;
  }

  static isAtaAtdModifiable() {
    return VisitContext.peerPartiesHaveBeenInformed()
      && (AppContext.adminAllowedToUpdateAtaAtd(VisitContext.visit.portOfCall.port.locationUnCode)
        || AppContext.isDouaneHaven(VisitContext.visit.portOfCall)
        || VisitContext.isOrganisationPortAuthority());
  }

  static startsHandlingBeforeArrivalBerthOrPort(startTime: string, berthVisit: BerthVisit): boolean {
    if (this.visitIsDeparted()) return false;
    if (!berthVisit) return false;
    let berthArrival = berthVisit.ata || berthVisit.eta;
    return berthArrival ? moment(startTime).isBefore(berthArrival) : this.startsHandlingBeforeArrivalAtPort(startTime);
  }

  static startsHandlingBeforeArrivalAtPort(startTime: string) {
    let arrivalAtPort = this.visit.visitDeclaration.portVisit.ataPort || this.visit.visitDeclaration.portVisit.etaPort;
    return moment(startTime).isBefore(arrivalAtPort);
  }

  static startsHandlingAfterDepartureBerthOrPort(startTime: string, etdBerth: string): boolean {
    return this.visitIsDeparted() ? false : !startTime ? false : etdBerth ? moment(startTime).isAfter(etdBerth) : moment(startTime).isAfter(this.visit.visitDeclaration.portVisit.etdPort);
  }
  static berthVisitById = (berthVisitId:string) => {
    return VisitContext.berthVisitInVisitById(VisitContext.savedVisit, berthVisitId);
  }

  static berthVisitInVisitById = (visit: Visit, berthVisitId: string) => {
    return visit.visitDeclaration.portVisit.berthVisits.find(value => value.id === berthVisitId);
  }

  static anyCargoDeclarantUsesNewCargoScreen() {
    return this.visit?.cargoDeclarants.map(c => c.shortName)
      .some(s => this.cargoDeclarantUsesNewCargoScreen(s));
  }

  static getCargoDeclarantsUsingNewCargoScreen() {
    return this.visit?.cargoDeclarants.map(c => c.shortName)
      .filter(s => this.cargoDeclarantUsesNewCargoScreen(s))
      .filter(s => AppContext.isAdminOrCustoms());
  }

  static cargoDeclarantUsesNewCargoScreen(cargoDeclarantShortName: string): boolean {
    const environmentSettings: { [key: string]: string[] } = newCargoScreenSettings[AppContext.environment] || {};
    const crns = environmentSettings[cargoDeclarantShortName];
    return crns != null && (crns.includes(this.visit?.crn) || crns.length === 0);
  }

  static isAmsterdamVisit() {
    return this.visit.portOfCall.port.locationUnCode === 'NLAMS';
  }

  static isRotterdamVisit() {
    return this.visit.portOfCall.port.locationUnCode === 'NLRTM';
  }

  static isVlissingenOrTerneuzenVisit() {
    return AppContext.isVlissingenOrTerneuzenVisit(this.visit.portOfCall.port.locationUnCode);
  }

  static getVisitStatus(): VisitStatus {
    if (this.savedVisit.visitDeclaration.portVisit.atdPort) {
      return VisitStatus.DEPARTED;
    }
    if (this.savedVisit.visitDeclaration.portVisit.berthVisits.find(berthVisit => !!(berthVisit.ata || berthVisit.atd))) {
      return VisitStatus.ARRIVED;
    }
    return VisitStatus.EXPECTED;
  }

	static hasPreviousPorts(): boolean {
		return VisitContext.visit.visitDeclaration.previousPorts.length > 0
			&& !!VisitContext.visit.visitDeclaration.previousPorts[0].port;
	}

	static hasNextPorts(): boolean {
		return VisitContext.visit.visitDeclaration.nextPorts.length > 0
			&& !!VisitContext.visit.visitDeclaration.nextPorts[0].port;
	}

	static hasDeparted(): boolean {
		return this.getVisitStatus() === 'DEPARTED';
	}

	static isArrived(): boolean {
		return this.getVisitStatus() === 'ARRIVED';
	}

	static isExpected(): boolean {
		return this.getVisitStatus() === 'EXPECTED';
	}

  static berthVisitsInChronologicalOrder(visit: Visit) {
    const berthVisits = cloneDeep(visit.visitDeclaration.portVisit.berthVisits);
    const berthVisitsSorted = lodash.sortBy(berthVisits.filter(bv => bv.eta), bv => bv.ata || bv.eta);
    berthVisits.filter(bv => !berthVisitsSorted.includes(bv))
      .forEach(bv => berthVisitsSorted.splice(berthVisits.indexOf(bv), 0, bv));
    return berthVisitsSorted;
  }

	static isPassingThrough(visit?: Visit) {
    const portVisit = (visit || this.savedVisit).visitDeclaration.portVisit;
    return portVisit.berthVisits.length == 0 ||
      (portVisit.entryPoint && portVisit.entryPoint.atSea && portVisit.exitPoint && !portVisit.exitPoint.atSea);
  }

  static declarationStatusOf(type: DeclarationType) {
    const declaration = this.findLatestDeclaration(type);
    return declaration ? declaration.status : null;
  }

  static isWaitingForOrders(): boolean {
    return this.visit.visitDeclaration.portVisit.portEntry && "WAITING_FOR_ORDERS" === this.visit.visitDeclaration.portVisit.portEntry.intention;
  }

  static isRequestingEntry(): boolean {
    return this.visit.visitDeclaration.portVisit.portEntry && "REQUEST_FOR_ENTRY" === this.visit.visitDeclaration.portVisit.portEntry.intention;
  }

  static peerPartiesHaveBeenInformed(): boolean {
    if (!this.visit) {
      return false;
    } else if (!this.visit.portOfCall.paDeclarationRequired) {
      return this.findLatestDeclaration(DeclarationType.WPCS) != null;
    } else {
      return this.hasBeenAcceptedAtLeastOnce(DeclarationType.VISIT);
    }
  }

  static hasEntryDependency(): boolean {
    return !!this.getEntryDependency();
  }

  static isAutoOrdered(): boolean {
    let entryDependency = this.getEntryDependency();
    if(entryDependency != null) {
      return entryDependency.autoOrder;
    }
  }

  static getEntryDependency(): EntryDependency | null {
    if(this.visit.orderIncomingMovement && this.visit.visitDeclaration.portVisit.portEntry.origin === 'SEA') {
      return (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry).entryDependency;
    } else {
      return null;
    }
  }

  static hasBeenAcceptedAtLeastOnce(type: DeclarationType): boolean {
    return !!this.visit.declarations.find(d => d.type === type && d.status === 'ACCEPTED');
  }

	static hasBeenDeclared() : boolean {
		return !!this.findLatestDeclaration(DeclarationType.VISIT) || !!this.findLatestDeclaration(DeclarationType.WPCS);
	}

  static incomingMovementRelevantForAgent(): boolean {
    return !this.visit.visitDeclaration.portVisit.berthVisits.find(v => !!v.ata)
      && (!this.hasBeenDeclared() || (this.visit.orderIncomingMovement && !this.visit.visitDeclaration.portVisit.firstMovement.order))
  }

  static shiftTime = (host: any, property: string, newTime: string) => {
    const currentTime = host[property];
    if (!currentTime || !newTime || moment(currentTime).isSame(moment(newTime))) {
      host[property] = newTime;
      return;
    }
    if (VisitContext.shiftTimes === null) {
      showShiftTimesModal(() => VisitContext.shiftTime(host, property, newTime));
    } else if (!VisitContext.shiftTimes) {
      host[property] = newTime;
    } else {
      const delta = moment(newTime).diff(moment(currentTime));
      shiftAllTimes(new Shifter(host, property, delta));
    }

    if (!VisitContext.isArrived()) {
      VisitContext.updateCalculatedEtas();
    }

    function showShiftTimesModal(callback) {
      const modal = $('#shiftVisitTimesModal');
      modal.modal('show').on('hidden.bs.modal', () => {
        modal.off('hidden.bs.modal');
        callback();
      });
    }

    function shiftAllTimes(shifter: Shifter) {
      const portVisit = VisitContext.visit.visitDeclaration.portVisit;
      if (VisitContext.visit.visitStatus === 'EXPECTED') {
        if (VisitContext.visit.orderIncomingMovement && !!portVisit.portEntry.etaPilotBoardingPlace) {
          shifter.shiftTime(VisitContext.visit.visitDeclaration.portVisit.portEntry, 'etaPilotBoardingPlace');
        } else {
          shifter.shiftTime(VisitContext.visit.visitDeclaration.portVisit, 'etaPort');
        }
      }
      VisitContext.visit.visitDeclaration.portVisit.berthVisits.filter(v => (!v.ata && !v.atd) || !v.atd)
        .forEach(v => {
          if (!v.ata && !v.atd) {
            shifter.shiftTime(v, 'eta');
          }
          if (!v.atd) {
            shifter.shiftTime(v, 'etd');
          }
        });

      if (VisitContext.visit.visitStatus !== 'DEPARTED') {
        shifter.shiftTime(VisitContext.visit.visitDeclaration.portVisit, 'etdPort');
      }
      VisitContext.visit.visitDeclaration.nextPorts.forEach(p => {
        shifter.shiftTime(p, 'arrival');
        shifter.shiftTime(p, 'departure');
      });
    }
  };

  static deletePreviousPort = (previousPort: PreviousPort) => {

    const backup: PreviousPort[] = cloneObject(VisitContext.visit.visitDeclaration.previousPorts);
    VisitContext.visit.visitDeclaration.previousPorts.splice(VisitContext.visit.visitDeclaration.previousPorts.indexOf(previousPort), 1);

    if (VisitContext.declarationStatusOf(DeclarationType.WPCS) != null && previousPort?.port != null) {
      const errorText = 'Port ' + previousPort.port.name + '  could not be deleted, because cargo is registered and/or reported for the port';
      const removedId = previousPort.id;
      sendQuery("com.portbase.bezoekschip.common.api.visit.IsAllowedToRemovePreviousPort",
        <IsAllowedToRemovePreviousPort>{crn: VisitContext.visit.crn, port: previousPort})
        .subscribe(result => {
          if (!result && VisitContext.visit.visitDeclaration.previousPorts.filter(port => port.id === removedId).length == 0) {
            VisitContext.visit.visitDeclaration.previousPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        }, () => {
          if (VisitContext.visit.visitDeclaration.previousPorts.filter(port => port.id === removedId).length == 0) {
            VisitContext.visit.visitDeclaration.previousPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        })
    }
  };

  static deleteNextPort = (nextPort: NextPort) => {
    const backup: NextPort[] = cloneObject(VisitContext.visit.visitDeclaration.nextPorts);
    VisitContext.visit.visitDeclaration.nextPorts.splice(VisitContext.visit.visitDeclaration.nextPorts.indexOf(nextPort), 1);

    if (VisitContext.declarationStatusOf(DeclarationType.WPCS) != null && nextPort.port != null) {
      const errorText = 'Port ' + nextPort.port.name + '  could not be deleted, because cargo is registered and/or reported for the port';
      const removedId = nextPort.id;
      sendQuery("com.portbase.bezoekschip.common.api.visit.IsAllowedToRemoveNextPort",
        <IsAllowedToRemoveNextPort>{crn: VisitContext.visit.crn, port: nextPort})
        .subscribe(result => {
          if (!result && VisitContext.visit.visitDeclaration.nextPorts.filter(port => port.id === removedId).length == 0) {
            VisitContext.visit.visitDeclaration.nextPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        }, () => {
          if (VisitContext.visit.visitDeclaration.nextPorts.filter(port => port.id === removedId).length == 0) {
            VisitContext.visit.visitDeclaration.nextPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        })
    }
  };

  static selectPreviousPort = (portVisit: PreviousPort, port: Port) => {
    const backup: PreviousPort[] = cloneObject(VisitContext.visit.visitDeclaration.previousPorts);
    const removedPort: PreviousPort = cloneObject(portVisit);

    const facilities = port && portVisit && portVisit.port
    && portVisit.port.locationUnCode === port.locationUnCode ? portVisit.portFacilityVisits : [];
    VisitContext.visit.visitDeclaration.previousPorts[VisitContext.visit.visitDeclaration.previousPorts.indexOf(portVisit)] =
      {
        id: uuid(),
        port: port,
        arrival: portVisit.arrival,
        departure: portVisit.departure,
        portFacilityVisits: facilities
      };

    if (VisitContext.declarationStatusOf(DeclarationType.WPCS) != null && removedPort.port != null) {
      const errorText = 'The location of previous port ' + removedPort.port.name + ' could not be changed, because cargo is registered and/or reported for the port';
      sendQuery("com.portbase.bezoekschip.common.api.visit.IsAllowedToRemovePreviousPort", <IsAllowedToRemovePreviousPort>{
        crn: VisitContext.visit.crn,
        port: removedPort
      })
        .subscribe(result => {
          if (!result && VisitContext.visit.visitDeclaration.previousPorts.filter(port => port.id === removedPort.id).length == 0) {
            VisitContext.visit.visitDeclaration.previousPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        }, () => {
          if (VisitContext.visit.visitDeclaration.previousPorts.filter(port => port.id === removedPort.id).length == 0) {
            VisitContext.visit.visitDeclaration.previousPorts = backup;
            AppContext.registerError(errorText);
            scrollToTop();
          }
        })
    }
  };

  static selectNextPort = (nextPort: NextPort, port: Port) => {
    const backup: NextPort[] = cloneObject(VisitContext.visit.visitDeclaration.nextPorts);
    const removedPort: NextPort = cloneObject(nextPort);
    VisitContext.visit.visitDeclaration.nextPorts[VisitContext.visit.visitDeclaration.nextPorts.indexOf(nextPort)] =
      {
        id: uuid(),
        port: port,
        arrival: nextPort.arrival,
        departure: nextPort.departure,
        customsOffice: nextPort.customsOffice
      };

    if (VisitContext.declarationStatusOf(DeclarationType.WPCS) != null && removedPort.port != null) {
      const errorText = 'The location of next port ' + removedPort.port.name + ' could not be changed, because cargo is registered and/or reported for the port';
      sendQuery("com.portbase.bezoekschip.common.api.visit.IsAllowedToRemoveNextPort", <IsAllowedToRemoveNextPort>{
        crn: VisitContext.visit.crn,
        port: removedPort
      }).subscribe(result => {
        if (!result && VisitContext.visit.visitDeclaration.nextPorts.filter(port => port.id === removedPort.id).length == 0) {
          VisitContext.visit.visitDeclaration.nextPorts = backup;
          AppContext.registerError(errorText);
          scrollToTop();
        }
      }, () => {
        if (VisitContext.visit.visitDeclaration.nextPorts.filter(port => port.id === removedPort.id).length == 0) {
          VisitContext.visit.visitDeclaration.nextPorts = backup;
          AppContext.registerError(errorText);
          scrollToTop();
        }
      })
    }
  };

  static organisationMayUpdateVisit(): boolean {
    if (!!VisitContext.eventId) {
      return false;
    }
    return VisitContext.isOrganisationCurrentVisitDeclarant() || VisitContext.isOrganisationCargoDeclarant();
  }

  static isOrganisationCurrentVisitDeclarant(): boolean {
    return AppContext.isAdmin()
      || VisitContext.savedVisit.declarant.shortName === AppContext.userProfile.organisation?.shortName
      || VisitContext.savedVisit.owner.shortName === AppContext.userProfile.organisation?.shortName;
  }

  static isOrganisationOnlyCargoDeclarant(): boolean {
    return this.isOrganisationCargoDeclarant()
      && !AppContext.isAdmin()
      && !this.organisationIsShipOperator()
      && !this.organisationIsAdditionalViewer();
  }

  static isUserOnlyCargoDeclarant(): boolean {
    return this.isOrganisationCargoDeclarant() && !AppContext.isAdmin() && !AppContext.hasRole("VisitDeclarant");
  }

  private static organisationIsAdditionalViewer() {
    return VisitContext.savedVisit.additionalViewers.some(so =>
      so.shortName === AppContext.userProfile.organisation?.shortName);
  }

  private static organisationIsShipOperator() {
    return VisitContext.savedVisit.shipOperators?.some(so =>
      so.shortName === AppContext.userProfile.organisation?.shortName);
  }

  static isOrganisationCargoDeclarant(): boolean {
    return AppContext.isAdmin() || VisitContext.savedVisit &&
      !!VisitContext.savedVisit.cargoDeclarants.find(d =>
        d?.shortName === AppContext.userProfile.organisation?.shortName);
  }

  static isUserCargoImportViewer(): boolean {
    return AppContext.isAdmin() || (AppContext.isCargoImportViewer() &&
      !!VisitContext.savedVisit.cargoDeclarants.find(d => d?.cargoImportEnabled
        && d?.shortName === AppContext.userProfile.organisation?.shortName));
  }

  static isOrganisationPortAuthority() {
    return VisitContext.savedVisit.portOfCall.portAuthority.shortName ===
      AppContext.userProfile.organisation?.shortName;
  }

  static isOrganisationNextDeclarant(): boolean {
    if(!VisitContext.visit){
      return false;
    }
    const userShortName = AppContext.userProfile.organisation?.shortName;
    let isNextOwner = !!VisitContext.visit.nextOwner && userShortName === VisitContext.visit.nextOwner.shortName;
    let isNextDeclarant = !!VisitContext.visit.nextDeclarant && userShortName === VisitContext.visit.nextDeclarant.shortName;
    return isNextOwner || isNextDeclarant;
  }

  static hasNextOwnerOrDeclarant(): boolean {
    if(!VisitContext.visit) return false;
    let nextOwner = VisitContext.visit.nextOwner;
    let nextDeclarant = VisitContext.visit.nextDeclarant;
    return (!!nextOwner && !!nextOwner.shortName) || (!!nextDeclarant && !!nextDeclarant.shortName);
  }

  static visitIsDeparted() {
    return VisitContext.savedVisit && !!VisitContext.savedVisit.visitDeclaration.portVisit.atdPort;
  }

  static isVisitReadonly = (): boolean => {
    return VisitContext.isVisitReadonlyIgnoringDeparture() || !!VisitContext.savedVisit.visitDeclaration.portVisit.atdPort;
  };

  static isVisitReadonlyIgnoringDeparture = (): boolean => {
    if (!VisitContext.organisationMayUpdateVisit()) {
      return true;
    }
    return !!VisitContext.eventId || VisitContext.savedVisit.cancelled;
  };

  static clearDependency() {
    if (VisitContext.visit.orderIncomingMovement && VisitContext.visit.visitDeclaration.portVisit.portEntry.origin === "SEA") {
      (<SeaEntry>VisitContext.visit.visitDeclaration.portVisit.portEntry).entryDependency = null;
    }
  }

  static visitHasBeenTransferred() {
    let berthVisits = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits;
    return berthVisits && berthVisits.length > 0 && VisitContext.savedVisit.berthVisitInfos[berthVisits[0].id] && VisitContext.savedVisit.berthVisitInfos[berthVisits[0].id].originalEditors;

  }

  static canEditFirstBerthVisit = () => {
    const berthVisits = VisitContext.savedVisit.visitDeclaration.portVisit.berthVisits;

    return berthVisits.length < 1 || VisitContext
      .canEditBerthVisit(berthVisits[0].id, VisitContext.dangerousGoodsDeclaration?.cargoDeclarant?.shortName);
  }

  static canEditBerthVisit = (berthVisitId, cargoAgent) => {
    const status = VisitContext.savedVisit.berthVisitInfos[berthVisitId];

    return !cargoAgent || !status || !status.originalEditors || status.originalEditors.length == 0
      || status.originalEditors.indexOf(cargoAgent) != -1;
  }

  static visitInTerminalPlanning(): boolean {
    return this.visit.terminalPlanningEnabled && !this.visit.terminalPlanningApproved;
  }

  static terminalsThatProvidePlanning = ["RWG", "APMII", "APMRTM", "ECTDELTA", "EUROMAX"];

  static terminalProvidesPlanning(firstBerthVisit: BerthVisit) {
    return VisitContext.terminalsThatProvidePlanning.indexOf(firstBerthVisit?.berth?.organisationShortName) >= 0;
  }

  static isMultiBerthTerminal(berthVisit: BerthVisit): boolean {
    return !!berthVisit.stevedore && !berthVisit.quay && berthVisit.stevedore.multiQuay;
  }

  static berthVisitInTerminalPlanningMode(berthVisit: BerthVisit): boolean {
    if (!this.visit.terminalPlanningEnabled || !berthVisit.terminalPlanningEnabled) {
      return false;
    }
    const terminalVisit: TerminalVisit = this.getTerminalVisit(berthVisit);
    return !terminalVisit || !terminalVisit.status || terminalVisit.status.status !== DeclarationStatus.ACCEPTED || !terminalVisit?.status?.acceptedByAgent;
  }

  static getTerminalVisit(berthVisit: BerthVisit, visit: Visit = this.visit): TerminalVisit | null {
    if (!visit) return null;
    if (!visit.terminalVisits) return null;

    if (visit.terminalPlanningEnabled && visit.terminalPlanningApproved) {
      return visit.terminalVisits['TerminalVisit-' + berthVisit.callId];
    } else {
      if (!berthVisit.berth) return null;

      const berthVisitInfo = visit.berthVisitInfos ? visit.berthVisitInfos[berthVisit.id] : null;
      if (!berthVisitInfo?.terminalInfo) return null;
      return <TerminalVisit>{organisationShortName: berthVisit.berth.organisationShortName, info: berthVisitInfo.terminalInfo};
    }
  }

  static visitHasTerminalPlannings(): boolean {
    return this.visit?.terminalVisits && Object.entries(this.visit.terminalVisits).length > 0 ;
  }

  static getTerminalDeclarationStatus(berthVisit: BerthVisit, visit: Visit = this.visit): MessageStatus {
    if (!berthVisit.terminalPlanningEnabled) {
      return MessageStatus.DISABLED;
    }
    const terminalVisit = this.getTerminalVisit(berthVisit, visit);
    if (!terminalVisit || !terminalVisit.status) {
      return MessageStatus.UNKNOWN;
    }
    if (terminalVisit.status.cancelled) {
      return MessageStatus.REJECTED;
    }
    if (terminalVisit.status.status === DeclarationStatus.DECLARED) {
      return MessageStatus.PENDING;
    }
    if (terminalVisit.status.status === DeclarationStatus.REJECTED) {
      return MessageStatus.REJECTED;
    }
    if (terminalVisit.status.status === DeclarationStatus.ACCEPTED) {
      if (!terminalVisit.status.acknowledged) {
        return MessageStatus.DELIVERED;
      }
      return this.terminalDataIsTheSameAsRequested(berthVisit, terminalVisit) ? MessageStatus.ACCEPTED : MessageStatus.WARNING;
    }
  }

  static terminalDataIsTheSameAsRequested(berthVisit: BerthVisit, terminalVisit: TerminalVisit) {
    return terminalVisit.info.expectedDischarge === berthVisit.discharge
      && terminalVisit.info.expectedLoad === berthVisit.load
      && terminalVisit.info.expectedRestow === berthVisit.restow
      && this.rtaMatchesEta(berthVisit, terminalVisit)
      && this.etcMatchesEtd(berthVisit, terminalVisit);
  }

  static rtaMatchesEta(berthVisit: BerthVisit, terminalVisit: TerminalVisit) {
    return this.isBetweenQuarterRange(berthVisit.eta, terminalVisit?.info?.rta);
  }

  static etcMatchesEtd(berthVisit: BerthVisit, terminalVisit: TerminalVisit) {
    return this.isBetweenQuarterRange(berthVisit.etd, terminalVisit?.info?.etc);
  }

  static getBunkerVisits(berthVisit: BerthVisit, visit: Visit = this.visit): BunkerOperatorInfo[] {
    if (!visit) return [];
    return visit.berthVisitInfos[berthVisit.id]?.bunkerOperatorInfos || [];
  }

  static isBetweenQuarterRange(agentTime: string, terminalTime: string): boolean {
    const time = moment(terminalTime);
    const minutesAfterQuarter = time.minute() % 15;
    const previousQuarter = time.clone().subtract(minutesAfterQuarter, 'minutes').seconds(0);
    const minutesBeforeQuarter = minutesAfterQuarter == 0 ? 0 : 15 - minutesAfterQuarter;
    const nextQuarter = time.clone().add(minutesBeforeQuarter, 'minutes').seconds(0);
    return moment(agentTime).isBetween(previousQuarter, nextQuarter, undefined, "[]");
  }

  static getNextCallId(): Observable<string> {
    return sendQuery('com.portbase.bezoekschip.common.api.visit.GetNextCallId', {},
      {caching: false, showSpinner: true});
  }

  static showETACalculationModal(callback) {
    const modal = $('#ETACalculationModal');
    modal.modal('show').on('hidden.bs.modal', () => {
      modal.off('hidden.bs.modal');
      callback();
    });
  }

  static removeConfirmedEtas() {
    console.log('removed confirmed etas');

    const berthVisits = this.visit.visitDeclaration.portVisit.berthVisits;

    if (berthVisits.length > 0) {
      const harbourMasterInfo = this.visit.berthVisitInfos[berthVisits[0]?.id]?.harbourMasterInfo;

      if (harbourMasterInfo != null) {
        harbourMasterInfo.eta = null;
      }
    }

    this.visit.additionalIncomingMovementInfo.etaPilotBoardingPlace = null;
  }

  static updateCalculatedEtas() {
    let portVisit = VisitContext.visit.visitDeclaration.portVisit;

		if (this.shouldUpdateEtas(portVisit)) {
      VisitContext.getTravelTimeToFirstBerth().subscribe(result => {
        if (result && result !== 0) {
          if (portVisit.portEntry.baseForPlanning === 'PILOT_BOARDING_PLACE' && portVisit.portEntry.etaPilotBoardingPlace) {
            portVisit.berthVisits[0].eta = moment(portVisit.portEntry.etaPilotBoardingPlace).add(result, 'minutes').toISOString();
          }
          if (portVisit.portEntry.baseForPlanning === 'FIRST_BERTH' && portVisit.berthVisits[0].eta) {
            portVisit.portEntry.etaPilotBoardingPlace = moment(portVisit.berthVisits[0].eta).subtract(result, 'minutes').toISOString();
          }
        } else {
          portVisit.berthVisits[0].eta = null;
          portVisit.portEntry.baseForPlanning = BaseForPlanning.PILOT_BOARDING_PLACE;
          AppContext.registerError('Travel time to first berth could not be calculated. ' +
            'Base for planning will be set to pilot boarding place. You can send the visit without an ETA on the first berth', 'warning');
        }
      });
    }

    return;
  }

  private static shouldUpdateEtas(portVisit: PortVisit) {
    return this.visit.orderIncomingMovement &&
			portVisit.berthVisits.length > 0 && portVisit.berthVisits[0].berth && portVisit.berthVisits[0].berth.code &&
      !this.visit.berthVisitInfos[portVisit.berthVisits[0]?.id]?.harbourMasterInfo?.eta &&
      portVisit.pilotStation && portVisit.pilotStation.code &&
      VisitContext.getVisitStatus() === 'EXPECTED';
  }

  private static getTravelTimeToFirstBerth(): Observable<number> {
    return sendQuery("com.portbase.bezoekschip.common.api.visit.GetTravelTimeToFirstBerth", <GetTravelTimeToFirstBerth>{
      pilotBoardingPlaceCode: VisitContext.visit.visitDeclaration.portVisit.pilotStation.code,
      berthCode: VisitContext.visit.visitDeclaration.portVisit.berthVisits[0].berth.code,
      vesselLength: VisitContext.visit.vessel.fullLength,
      vesselDraught: VisitContext.visit.visitDeclaration.portVisit.firstMovement.vesselDraft ? VisitContext.visit.visitDeclaration.portVisit.firstMovement.vesselDraft : 0,
      mooring: VisitContext.visit.visitDeclaration.portVisit.berthVisits[0].mooring
    });
  }

  static getIncomingOrder(visit: Visit): IncomingOrder {
    let firstMovement = visit.visitDeclaration.portVisit.firstMovement;
    let firstBerthVisit = visit.visitDeclaration.portVisit.berthVisits[0];
    let portVisit = visit.visitDeclaration.portVisit;
    return {
      ordered: firstMovement.order,
      passingThrough: firstBerthVisit == null,
      etaPilotBoardingPlace: portVisit.portEntry.etaPilotBoardingPlace,
      tugboatAtArrival: firstBerthVisit != null ? firstBerthVisit.tugboatAtArrival : null,
      boatmenAtArrival: firstBerthVisit != null ? firstBerthVisit.boatmenAtArrival : null,
      pilotStation: portVisit.pilotStation,
      pilotService: firstMovement.pilotService,
      passingThroughTugboats: firstBerthVisit == null ? portVisit.passingThroughTugboats : null
    };
  }

  static isStormPilotageInfoRequiredForPortEntry(visit: Visit): boolean {
		let portVisit = visit.visitDeclaration.portVisit;
		let isInboundForRotterdam = visit.portOfCall.port.locationUnCode === 'NLRTM'
      && visit.orderIncomingMovement
      && portVisit.portEntry.origin === 'SEA'
      && portVisit.portEntry.intention === 'REQUEST_FOR_ENTRY'
      && (!portVisit.ataPort && !portVisit.atdPort && !portVisit.berthVisits.find(v => !!v.ata));

		var vesselLength = visit.vessel.fullLength;
    let isStormPilotageApplicableForVessel =
      (visit.vessel.fullLength <= 125 && this.vesselDraftLessThan10Meters(portVisit.firstMovement)
        && AppContext.stormPilotageRotterdam === "HALTED_FOR_SMALL") || AppContext.stormPilotageRotterdam === "HALTED_FOR_ALL";
    let hasPilotOrdered = this.hasPilotOrdered(portVisit.firstMovement);

    return isInboundForRotterdam && hasPilotOrdered && (isStormPilotageApplicableForVessel
      || this.isLoaRequired(portVisit.firstMovement, vesselLength, true));
  }

  static isStormPilotageInfoRequiredForPortDeparture(visit: Visit): boolean {
    if (visit.visitDeclaration.portVisit.berthVisits.length < 1) {
      return false;
    }

    let lastBerthVisit = visit.visitDeclaration.portVisit.berthVisits[visit.visitDeclaration.portVisit.berthVisits.length - 1];
		var vesselLength = visit.vessel.fullLength;

    let isStormPilotageApplicableForVessel = (vesselLength <= 125 && this.vesselDraftLessThan10Meters(lastBerthVisit.nextMovement)
      && AppContext.stormPilotageRotterdam === "HALTED_FOR_SMALL") || AppContext.stormPilotageRotterdam === "HALTED_FOR_ALL";

    return lastBerthVisit && visit.portOfCall.port.locationUnCode === 'NLRTM'
      && this.hasPilotOrdered(lastBerthVisit.nextMovement)
      && !lastBerthVisit.atd
      && (isStormPilotageApplicableForVessel || this.isLoaRequired(lastBerthVisit.nextMovement, vesselLength, false));
  }

  static loaQuestionsShown(vesselLength: number, localMovement: LocalMovement, inbound: boolean): boolean {
    const isLoaApplicable = vesselLength <= this.maxLengthForLoa(inbound) && this.vesselDraftLessThan10Meters;

    return isLoaApplicable && (this.isTanker ? this.isLoaApplicableTanker(vesselLength, localMovement) : true);
  }

  static isLoaApplicableTanker(vesselLength: number, localMovement: LocalMovement) : boolean {
    return this.isTanker
      && (vesselLength < this.maximumTankerLengthLOA
        || localMovement.cargo == CargoDescription.BALLAST_CONDITION);
  }

  static isTanker(): boolean {
    return !VisitContext.visit.vessel.statCode5 || VisitContext.visit.vessel.statCode5.startsWith('A1');
  }

	static maxLengthForLoa(inbound: boolean): number {
		const inboundThreshold = 160;
		const outboundThreshold = 160;
		return inbound ? inboundThreshold : outboundThreshold;
	}

  static isFirstMovement(movement: LocalMovement) : boolean {
    return movement === VisitContext.visit.visitDeclaration.portVisit.firstMovement;
  }

  static isLastMovement(movement: LocalMovement) : boolean {
    const berthCount = VisitContext.visit.visitDeclaration.portVisit.berthVisits.length;
    if (berthCount > 0) {
      const lastBerth = VisitContext.visit.visitDeclaration.portVisit.berthVisits[berthCount - 1];
      if (lastBerth.etd && lastBerth.nextMovement === movement) {
        return true;
      }
    }
    return false;
  }

  static getLastMovement() {
    const berthCount = VisitContext.visit.visitDeclaration.portVisit.berthVisits.length;
    if (berthCount > 0) {
      const lastBerth = VisitContext.visit.visitDeclaration.portVisit.berthVisits[berthCount - 1];
      if (lastBerth.etd && lastBerth.nextMovement) {
        return lastBerth.nextMovement;
      }
    }
    return null
  }

	static isLoaRequired(localMovement: LocalMovement, vesselLength: number, inbound: boolean): boolean {
		return !!localMovement.order && ((AppContext.stormPilotageRotterdam === 'HALTED_FOR_ALL' && vesselLength <= this.maxLengthForLoa(inbound)
			&& this.vesselDraftLessThan10Meters(localMovement)) ||
			(AppContext.stormPilotageRotterdam === 'HALTED_FOR_SMALL' && vesselLength < 125 && this.vesselDraftLessThan10Meters(localMovement)));
	}

  static hasPilotOrdered(localMovement: LocalMovement): boolean {
    return localMovement.pilotService && localMovement.pilotService.required && localMovement.order;
  }

  static vesselDraftLessThan10Meters(localMovement: LocalMovement): boolean {
    return localMovement && localMovement.vesselDraft <= 10;
  }

  static hasVesselDependency(): boolean {
    let portEntry = (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry);
    return !!(portEntry && portEntry.entryDependency);

  }

  static autoOrderEnabled(): boolean {
    let portEntry = (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry);
    return this.hasVesselDependency() && portEntry.entryDependency.autoOrder;
  }

  static updateEtasForExchange() {
    const portEntry = (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry);
    const etdBerth = portEntry.entryDependency.estimatedTimeBerth;
    const smallVessel = this.visit.vessel.fullLength < 150;

    if (etdBerth) {
      let newEtaPbp = smallVessel ? moment(etdBerth).subtract(30, 'minutes') : moment(etdBerth);
      portEntry.etaPilotBoardingPlace = newEtaPbp.toISOString();
      this.getTravelTimeToFirstBerth().subscribe(result => {
        if (result && result > 0) {
          this.visit.visitDeclaration.portVisit.berthVisits[0].eta =
            moment(portEntry.etaPilotBoardingPlace).add(result, "minutes").toISOString();
        } else {
          this.visit.visitDeclaration.portVisit.berthVisits[0].eta = null;
        }
      });
    }
  }

  static updateEtasForEntryAfter() {
    const portEntry = (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry);
    const etaBerthFirstVessel = portEntry.entryDependency.estimatedTimeBerth;

    if (etaBerthFirstVessel) {
      let newEtaBerth = moment(etaBerthFirstVessel).add(1, "hour");
      this.visit.visitDeclaration.portVisit.berthVisits[0].eta = newEtaBerth.toISOString();

      this.getTravelTimeToFirstBerth().subscribe(result => {
        if (result && result > 0) {
          portEntry.etaPilotBoardingPlace =
            moment(newEtaBerth).subtract(result, "minutes").toISOString();
        } else {
          portEntry.etaPilotBoardingPlace = null;
        }
      });

    }
  }

  static shiftEtas() {
    if (this.hasEntryDependency() && this.autoOrderEnabled() && this.visit.visitDeclaration.portVisit.berthVisits.length > 0) {
      let portEntry = (<SeaEntry>this.visit.visitDeclaration.portVisit.portEntry);
      if (portEntry.entryDependency.dependencyType === "EXCHANGE") {
        this.updateEtasForExchange();
      }
      if (portEntry.entryDependency.dependencyType === "ENTRY_AFTER") {
        this.updateEtasForEntryAfter();
      }
    }
  }


  static exceedsVesselsDraught(vesselDraft: number) {
    return vesselDraft && vesselDraft > this.getVesselsDraught();
  }

  static getVesselsDraught() {
    return this.visit.vessel.maxDraught || this.maxVesselDraughtInHarbour;
  }

	static hasDeclaredOrAcceptedMdoh() {
		const healthStatus = this.declarationStatusOf(DeclarationType.HEALTH);
		return healthStatus !== null && (healthStatus === 'DECLARED' || healthStatus === 'ACCEPTED');
	}

  static isVisitingRotterdam() {
    return VisitContext.visit.portOfCall.port.locationUnCode === "NLRTM"
  }
}

export function reduceCargoDeclaration(declarations: ImportDeclaration[]): Declaration {
  const portOfCall: string = VisitContext.savedVisit.portOfCall.port.locationUnCode;
  return declarations.map(i => {
    const sdtByPort = lodash.groupBy(i.declarations.filter(d => d.type === 'SDT'), s => s.id);
    lodash.values(sdtByPort).forEach(declarations => {
      if (declarations.every(d => d.status === 'REJECTED'
        && !(i.consignments.some(c => c.portOfLoading.locationUnCode === d.id && c.portOfDischarge.locationUnCode === portOfCall)
          || i.containers.some(c => c.portOfLoading.locationUnCode === d.id && c.portOfDischarge.locationUnCode === portOfCall)))) {
        removeAll(i.declarations, declarations);
      }
    });
    removeAll(i.declarations, i.declarations.filter(d => d.type === 'ENS' && !i.consignments.some(c => c.consignmentNumber === d.id)));
    return i;
  }).map(d => {
    const byId = lodash.keyBy(d.declarations.filter(s => s.type === 'SDT' || s.type === 'ENS'), s => s.id);
    return lodash.values(byId).filter(v => !!v)
      .reduce((a, b) => b.status === 'REJECTED' ? b : a && a.status === 'REJECTED' ? a
        : b.status === 'DECLARED' ? b : a && a.status === 'DECLARED' ? a : b, null);
  }).filter(v => !!v).reduce((a, b) => b.status === 'REJECTED' ? b : a && a.status === 'REJECTED' ? a
    : b.status === 'DECLARED' ? b : a && a.status === 'DECLARED' ? a : b, null);
}

export class Shifter {
	on: boolean = false;

	constructor(private refHost: any, private refProperty: string, private delta: number) {
	}

	shiftTime(host: any, property: string) {
		if (this.on || this.isReferenceField(host, property)) {
			this.on = true;
			const currentTime = host[property];
			if (currentTime) {
				const timestamp = moment(currentTime);
				host[property] = timestamp.add(this.delta, 'ms').toISOString();
			}
		}
	}

	private isReferenceField(host: any, property: string) {
		return host === this.refHost && property === this.refProperty;
	}
}
