<div class="border-bottom container-fluid mw-100 bg-light">
  <div class="row px-3 py-2 gx-4 main-row" *ngIf="_consignment">
    <div class="col-auto d-none d-md-flex align-items-center" [style.width.px]="selectionWidth">
      <app-selection-checkbox *ngIf="selectable" [(ngModel)]="selectionState" (ngModelChange)="selectionState = $event"></app-selection-checkbox>
    </div>
    <div class="col-7 col-md-4 col-vlg-2 d-flex align-items-center flex-grow-1 flex-md-grow-0" [style.padding-left]="indentionDepth+'px'">
      <button type="button" class="btn text-end p-2 me-2 rounded-circle" (click)="collapsed = !collapsed"
              [ngClass]="collapsible ? '' : 'invisible'"
              data-bs-toggle="collapse" [attr.data-bs-target]="'#'+validSelector(_consignment.masterConsignment.consignmentNumber)"
              [attr.aria-controls]="validSelector(_consignment.masterConsignment.consignmentNumber)" aria-expanded="false">
        <span class="h1 m-0 align-top fa-light fa-fw text-primary" [ngClass]="collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"></span>
      </button>
      <span class="sub-text me-2">
        <i class="fa fa-fw fa-light fa-xl fa-file-lines"></i>
      </span>
      <div class="h-100 w-0 d-flex flex-grow-1 flex-column align-items-stretch justify-content-center">
        <div class="d-flex w-100 align-items-stretch">
          <span class="d-inline-block text-truncate mw-100 cursor-pointer underline-tertiary link-underline-tertiary link-primary text-body"
                (click)="editConsignment()" [title]="_consignment.masterConsignment.consignmentNumber">
            <app-highlight [term]="term" [result]="_consignment.masterConsignment.consignmentNumber"></app-highlight>
          </span>
          <app-tooltip *ngIf="_consignment.cancelled" placement="top" class="ms-2">
            <span class="fa text-danger fa-fw fa-ban"></span>
            <span class="tooltipContent">Cancelled</span>
          </app-tooltip>
          <app-tooltip *ngIf="_consignment.draft && !_consignment.cancelled" placement="top" class="ms-2">
            <span class="fa-light text-muted fa-fw fa-pencil"></span>
            <span class="tooltipContent">Consignment in draft, it has not been declared yet</span>
          </app-tooltip>
        </div>
        <span class="sub-text">{{utils.filingTypeFormatter(_consignment.filingType, _consignment.fromVisit)}}</span>
      </div>
    </div>

    <div class="col d-none d-md-flex flex-column align-items-stretch justify-content-center text-truncate">
      <ng-container *ngIf="_consignment.crn else noCrn">
        <a class="d-inline-block text-truncate mw-100 underline-tertiary link-underline-tertiary link-primary text-body"
           [routerLink]="'/details/'+_consignment.crn" target="_blank" [title]="_consignment.crn">
          <app-highlight [term]="term" [result]="_consignment.masterConsignment.voyageNumber || _consignment.crn" emptyText="n/a"></app-highlight>
        </a>
      </ng-container>
      <ng-template #noCrn>
        <span class="d-inline-block text-truncate mw-100" [title]="_consignment.masterConsignment.voyageNumber">
          <app-highlight [term]="term" [result]="_consignment.masterConsignment.voyageNumber" emptyText="n/a"></app-highlight>
        </span>
      </ng-template>
      <span class="sub-text">{{_consignment.masterConsignment.voyageNumber || !_consignment.crn ? "Voyage number" : "Call reference number"}}</span>
    </div>

    <div class="col d-none d-vlg-flex flex-column align-items-stretch justify-content-center text-truncate" [title]="terminalValue">
      <span class="d-inline-block text-truncate mw-100">
        <app-highlight [term]="term" [result]="terminalValue" emptyText="n/a"></app-highlight>
      </span>
      <span class="sub-text">{{_consignment.ataPort ? 'ATA' : 'ETA'}} Terminal</span>
    </div>

    <div class="col d-none d-vlg-flex flex-column align-items-stretch justify-content-center text-truncate" [title]="vehicleValue">
      <span class="d-inline-block text-truncate mw-100">
        <app-highlight [term]="term" [result]="vehicleValue" emptyText="n/a"></app-highlight>
      </span>
      <span class="sub-text">{{isVessel ? 'Vessel' : 'Vehicle'}}</span>
    </div>

    <div class="col d-none d-vlg-flex flex-column align-items-stretch justify-content-center text-truncate" [title]="portOfLoading">
      <span class="d-inline-block text-truncate mw-100">
        <app-highlight [term]="term" [result]="portOfLoading" emptyText="n/a"></app-highlight>
      </span>
      <span class="sub-text">Port of Loading</span>
    </div>

    <div class="col-auto col-xl-2 d-flex flex-column align-items-stretch justify-content-center">
      <div class="row d-flex align-items-stretch gx-2">
        <app-status-indicator *ngIf="_ensStatus && _ensStatus.taskStatus !== 'DISABLED'" class="col-auto" [messageStatus]="_ensStatus.taskStatus"
                              [messageStatuses]="_ensStatuses">
          <span>ENS</span>
        </app-status-indicator>
        <!-- we do not display the TS status when no crn is filled
        thus could happen when the place of unloading is outside the Netherlands
        or when the customer does not use the TS service-->
        <app-status-indicator *ngIf="_temporaryStorageStatus && _consignment.crn" class="col-auto"
                              [messageStatus]="_temporaryStorageSummaryStatus" [summarizeIcon]="true"
                              [messageStatuses]="_temporaryStorageStatuses">
          <span>TS</span>
        </app-status-indicator>
        <app-status-indicator *ngIf="_notificationOfArrivalStatus" class="col-auto"
                              [messageStatus]="_notificationOfArrivalStatus.taskStatus"
                              [messageStatuses]="_notificationOfArrivalStatuses">
          <span>NA</span>
        </app-status-indicator>
      </div>
      <span class="sub-text">Status</span>
    </div>

    <div class="col-auto d-none d-xl-flex flex-column align-items-stretch justify-content-center" [style.width.px]="80"
         [title]="equipmentCount | numberFormat">
      <span class="d-block text-truncate mw-100 text-end">{{equipmentCount | numberFormat}}</span>
      <span class="sub-text text-end">Equip.</span>
    </div>

    <div class="col-auto d-none d-xl-flex flex-column align-items-stretch justify-content-center" [style.width.px]="120"
         [title]="totalWeight | numberFormat">
      <span class="d-block text-truncate mw-100 text-end">{{totalWeight | numberFormat}}</span>
      <span class="sub-text text-end">Weight (kg)</span>
    </div>

    <div class="col-auto ms-auto d-flex flex-row align-items-stretch justify-content-center dropdown px-2">
      <a class="d-none d-md-block btn rounded-circle p-2 text-primary h1 mb-0 action-btn" type="button" (click)="editConsignment()">
        <i class="fa-light fa-angle-right"></i>
      </a>
      <button class="btn rounded-circle p-2 text-primary h1 mb-0 action-btn" type="button"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              *ngIf="appContext.isAdmin()">
        <i class="fa-light fa-ellipsis-vertical"></i>
      </button>
      <div class="dropdown-menu position-fixed">
        <a *ngIf="_consignment.crn"
           class="dropdown-item" [routerLink]="'/details/' + _consignment.crn" target="_blank">
          <i class="fa-light fa-fw fa-right-to-bracket"></i>
          Open visit
        </a>
        <button *ngIf="appContext.isAdmin()" class="btn dropdown-item" (click)="refreshSummary()">
          <i class="fa-light fa-arrows-rotate"></i>
          Refresh summary
        </button>
        <button class="btn dropdown-item" (click)="duplicate()">
          <i class="fa-light fa-copy"></i>
          Duplicate B/L
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="collapsible" class="collapse" [id]="validSelector(_consignment.masterConsignment.consignmentNumber)">
  <ng-container *ngFor="let equipment of utils.goodsItemsWithContainerForSummary(_consignment, _consignment.masterConsignment.equipments); let i = index; trackBy: utils.trackByGoodsItemAndEquipment">
    <app-consignment-equipment-overview-item [equipment]="equipment" [term]="term" [depth]="depth + 1"
                                             [consignmentProcessId]="_consignment.consignmentProcessId"
                                             [selectable]="false"></app-consignment-equipment-overview-item>
  </ng-container>
  <ng-container *ngFor="let goodsItem of utils.bulkGoodsItemsForSummary(_consignment); let i = index; trackBy: utils.trackByGoodsItemWithHouseConsignments">
    <app-consignment-bulk-goods-overview-item [goodsItem]="goodsItem" [term]="term" [depth]="depth + 1"
                                              [consignmentProcessId]="_consignment.consignmentProcessId"
                                              [inspections]="_consignment.masterConsignment.bulkInspections"
                                              [selectable]="false"></app-consignment-bulk-goods-overview-item>
  </ng-container>
</div>
