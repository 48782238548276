import {Injectable} from '@angular/core';
import {QueryHandler} from '../common/query-handler';
import {merge, Observable, of} from 'rxjs';
import {
  Berth,
  BezoekschipOrganisation,
  Bollards,
  Carrier,
  ChemicalData,
  ContainerGoodData,
  EnrichPreviousPorts,
  FindGuarantees,
  FindLocations,
  FindPorts,
  FindPortsOrWayPoints,
  FindTransitParties,
  FindTransitTemplates,
  FindVesselServices,
  FindWasteMaterials,
  GasData,
  GetBollardsOfTerminal,
  GetMyCarriers,
  GetPort,
  GetPortOrWayPoint,
  GetTerminals,
  GetTravelTimeToFirstBerth,
  GetVesselServices,
  GoodsClassification,
  Guarantee,
  Location,
  OilData,
  PilotExemption,
  Port,
  PortFacility,
  PortOfCall,
  SolidData,
  Terminal,
  Vessel,
  VesselService,
  WasteMaterial
} from '@portbase/bezoekschip-service-typescriptmodels';
import {delay} from 'rxjs/operators';
import {myParties, myWarehouseLicenses} from './bezoekschip-command-handler.mock';
import {AppContext} from "../app-context";

export const myTransitGuarantees = <Guarantee[]>[
  {
    guaranteeData: {
      type: {
        code: '0',
        description: 'Guarantee Waiver'
      },
      customsValue: {
        amount: 10.99,
        currency: {
          unCode: 'EUR',
          description: 'Euro'
        }
      },
      guaranteeReferenceNumber: '18NL00007400034R9',
      country: {
        code: "NL",
        name: "Nederland"
      },
      accessCode: '1244',
      validFrom: '2021-02-18T03:00:00Z',
      validTo: '2022-02-18T03:00:00Z',
    },
    id: "testG",
    ownerShortName: "CHA1",
    balance: {
      amount: 199.99,
      currency: {
        unCode: 'EUR',
        description: 'Euris'
      }
    },
    timestamp: '2021-02-18T03:00:00Z',
    balanceQueryCount: 8,
    status: "DECLARED"
  },
  {
    guaranteeData: {
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: 2.99,
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRUESOME',
      accessCode: '1244'
    },
    status: "ACCEPTED",
    id: "testA",
    ownerShortName: "CHA1",
    balance: {
      amount: 222.99,
      currency: {
        unCode: 'USD',
        description: 'Trumpies'
      }
    },
    timestamp: '2021-09-07T03:00:00Z',
    balanceQueryCount: 8
  },
  {
    guaranteeData: {
      type: {
        code: '1',
        description: 'Iets met een 1 erin'
      },
      customsValue: {
        amount: 3.99,
        currency: {
          unCode: 'EUR',
          description: 'Euris'
        }
      },
      country: {
        code: "NL",
        name: "Nederland"
      },
      guaranteeReferenceNumber: 'GRN123.123.123',
      accessCode: '010'
    },
    status: "REJECTED",
    id: "testQ",
    ownerShortName: "CHA1",
    balance: null,
    timestamp: '2021-09-07T03:00:00Z',
    balanceQueryCount: 2,
  },
  {
    guaranteeData: {
      type: {
        code: "5",
        description: "Comprehensive guarantee"
      },
      customsValue: {
        amount: 55.9,
        currency: {
          unCode: 'BZD',
          description: 'Frankies'
        }
      },
      country: {
        code: "BE",
        name: "Belgique"
      },
      guaranteeReferenceNumber: null,
      accessCode: null
    },
    id: "testJ",
    ownerShortName: "CHA1",
    status: null,
    timestamp: null,
    balanceQueryCount: 0
  }
];

export const adminGuarantees = <Guarantee[]>[
  {
    guaranteeData: {
      type: {
        code: '0',
        description: 'Guarantee Waiver'
      },
      customsValue: {
        amount: 10.99,
        currency: {
          unCode: 'EUR',
          description: 'Euro'
        }
      },
      guaranteeReferenceNumber: 'CROSSOCEANRTM',
      country: {
        code: "NL",
        name: "Nederland"
      },
      accessCode: '1244'
    },
    id: "admin",
    ownerShortName: "CROSSOCEANRTM",
    balance: {
      amount: 199.99,
      currency: {
        unCode: 'EUR',
        description: 'Euris'
      }
    },
    timestamp: '2021-02-18T03:00:00Z',
    balanceQueryCount: 8,
    status: null,
  }];

export const myTransitTemplates = [
  {
    id: '12345678901',
    name: 't2sm',
    simplifiedProcedure: true,
    templateData: {
      templateName: 't2sm',
      type: {
        "code": "T2SM",
        "description": "T2 procedure for San Marino"
      },
      departureOffice: {
        "name": "Rotterdam/Reeweg simplified proc.", "unCode": "NL000510"
      },
      destinationsOffice: {
        "name": "Bloep.", "unCode": "NL000511"
      },
      customsSubPlace: 'custSubPlace',
      placeOfLoading: 'Bergen op Zoom',
      seals: ['123', '456', '789', 'jkl'],
      transportTerm: 777600.000000000,
      countryOfExport: {"code": "NL", "name": "Netherlands"},
      dischargeTerminal: {
        "terminalName": "AMAZONEH ECT DDE",
        "terminalCode": "4810",
        "organisationShortName": "ECTDELTA",
        "organisationName": "ECT Delta Terminal B.V."
      },
      authorizedLocation: 'Autostrada 12',
      guarantees: [
        {
          id: "myId1",
          type: {
            code: '7',
            description: 'Guarantee not required (departure-transit)'
          },
          country: {
            code: "NL",
            name: "Nederland"
          },
          customsValue: {
            amount: '199.99',
            currency: {
              unCode: 'EUR',
              description: 'Euris'
            }
          },
          guaranteeReferenceNumber: '18NL00007400034R9',
          accessCode: '1244'
        }
      ],
      goodsItems: [
        {
          "description": "SLAC 274 PC FISHING EQUIPMENT Ships stores in transshipment to final destination F/V Jonas at Mombasa Fisheries, Jetty, Liwatoni Fishing equipment",
          "previousDocument": {
            "type": {
              code: "N705",
              description: 'whatever'
            },
            "documentNumber": "EIMUSKO130VESW009",
            "sequenceNumber": 1
          },
          "packageType": {
            "code": "PK",
            "name": "Package",
            "bulk": false
          },
          "marksAndNumbers": "Package",
          "weight": 15000.0,
          "numberOfPackages": 274,
          "producedDocuments": []
        }
      ],
      declarationPlace: 'plaatsnaam',
      representative: 'Lennie boy',
      transitRoute: 'kweenie',
      conveyanceReference: 'reffie1',
      departureTransport: {
        modeOfTransport: 'INLAND_WATER_TRANSPORT',
        nationality: {code: 'NL', description: 'Netherlands'},
        reference: 'stringetje'
      },
      borderTransport: {
        modeOfTransport: 'MARITIME_TRANSPORT',
        nationality: {code: 'BE', description: 'Belgium'},
        reference: 'reverenzie'
      },
      securityDetails: {
        circumstances: "victualling_of_ships_and_aircraft",
        paymentMethod: "cash_payment",
        placeOfUnloading: "Rotjeknor",
        commercialReference: "commercie"
      }
    }
  }, {
    id: '12345678902',
    name: 'tir',
    simplifiedProcedure: false,
    templateData: {
      templateName: 'tir',
      type: {
        code: "TIR",
        description: "TIR-carnet"
      },
      customsSubPlace: 'Maastricht',
      placeOfLoading: 'Hillegom',
      seals: ['abc', 'def', 'ghi', 'jkl'],
      authorizedLocation: 'Autostrada 1555',
      declarationPlace: 'Rotterdam',
      representative: 'Robert tha man',
      transitRoute: 'kweeniewa',
      conveyanceReference: 'ref2'
    }
  }, {
    "id": "123456789-54321-6789",
    "name": "Rail departure transport with nationality",
    "simplifiedProcedure": false,
    "templateData": {
      "conveyanceReference": null,
      "consignee": {
        "ownerShortName": "CMACGMRHN",
        "zipCode": "05-800",
        "address": "UL PRZYTOROWA 1",
        "city": "PRUSZKOW",
        "countryCode": "PL",
        "authorised": false,
        "name": "POLZUG TERMINAL KONTENEROWY",
        "state": null,
        "shortCode": null
      },
      "securityDetails": {
        "commercialReference": null,
        "circumstances": null,
        "paymentMethod": null,
        "placeOfUnloading": null
      },
      "departureOffice": {
        "unCode": "NL000511",
        "name": "Rotterdam/Reeweg normal proc."
      },
      "customsSubPlace": null,
      "transitRoute": null,
      "goodsItems": [],
      "countryOfDestination": {
        "code": "PL",
        "name": "Poland"
      },
      "transitOffices": [],
      "itinerary": [],
      "type": {
        "code": "T1",
        "description": "External Community Transit"
      },
      "transportTerm": null,
      "authorizedLocation": null,
      "securityActivated": false,
      "seals": [
        "1657831"
      ],
      "templateName": "POLZUG TERMINAL KONT",
      "placeOfLoading": null,
      "departureTransport": {
        "reference": ".",
        "nationality": {
          "code": "NL",
          "name": "Netherlands"
        },
        "modeOfTransport": "RAIL_TRANSPORT"
      },
      "guarantees": [
        {
          "guaranteeReferenceNumber": "05NL5663790000X88",
          "country": {
            "code": "NL",
            "name": "Netherlands"
          },
          "customsValue": {
            "amount": "5901.0",
            "currency": {
              "unCode": "EUR",
              "description": "EURO"
            }
          },
          "accessCode": "6646",
          "otherReference": null,
          "type": {
            "code": "1",
            "description": "Comprehensive guarantee"
          }
        }
      ],
      "declarationPlace": "Rhoon",
      "destinationsOffice": {
        "unCode": "PL445000",
        "name": "PRUSZKÃ“W UC"
      }
    }
  }, {
    "id": "123456789-54321-6789",
    "name": "CHA1 specific",
    "ownerShortName": "CHA1",
    "simplifiedProcedure": false,
    "templateData": {
      "conveyanceReference": null,
      "consignee": {
        "ownerShortName": "CMACGMRHN",
        "zipCode": "05-800",
        "address": "UL PRZYTOROWA 1",
        "city": "PRUSZKOW",
        "countryCode": "PL",
        "authorised": false,
        "name": "POLZUG TERMINAL KONTENEROWY",
        "state": null,
        "shortCode": null
      },
      "securityDetails": {
        "commercialReference": null,
        "circumstances": null,
        "paymentMethod": null,
        "placeOfUnloading": null
      },
      "departureOffice": {
        "unCode": "NL000511",
        "name": "Rotterdam/Reeweg normal proc."
      },
      "customsSubPlace": null,
      "transitRoute": null,
      "goodsItems": [],
      "countryOfDestination": {
        "code": "PL",
        "name": "Poland"
      },
      "transitOffices": [],
      "itinerary": [],
      "type": {
        "code": "T1",
        "description": "External Community Transit"
      },
      "transportTerm": null,
      "authorizedLocation": null,
      "securityActivated": false,
      "seals": [
        "1657831"
      ],
      "templateName": "POLZUG TERMINAL KONT",
      "placeOfLoading": null,
      "departureTransport": {
        "reference": ".",
        "nationality": {
          "code": "NL",
          "name": "Netherlands"
        },
        "modeOfTransport": "RAIL_TRANSPORT"
      },
      "guarantees": [
        {
          "guaranteeReferenceNumber": "05NL5663790000X88",
          "country": {
            "code": "NL",
            "name": "Netherlands"
          },
          "customsValue": {
            "amount": "5901.0",
            "currency": {
              "unCode": "EUR",
              "description": "EURO"
            }
          },
          "accessCode": "6646",
          "otherReference": null,
          "type": {
            "code": "1",
            "description": "Comprehensive guarantee"
          }
        }
      ],
      "declarationPlace": "Rhoon",
      "destinationsOffice": {
        "unCode": "PL445000",
        "name": "PRUSZKÃ“W UC"
      }
    }
  }];

export const myTransitParties = [
  {
    id: "1",
    shortCode: "RVW",
    eoriNumber: 't1',
    name: "Robert van Wingerden",
    address: "Nachtwaker 41",
    city: "Rhoon",
    countryCode: "NL",
    state: 'ZH',
    zipCode: "party321",
    authorised: true
  },
  {
    id: "2",
    shortCode: "LP",
    eoriNumber: 't2',
    name: "Lenski",
    address: "Ergens",
    city: "Rotterdam",
    countryCode: "NL",
    state: 'ZH',
    zipCode: "3085WL",
    authorised: false
  },
  {
    id: "3",
    shortCode: "D",
    eoriNumber: 't3',
    name: "Davidus",
    address: "Davidstraat 41",
    city: "Barcelona",
    countryCode: "ES",
    state: 'CA',
    zipCode: "party321"
  },
  {
    id: "4",
    shortCode: "CHA1_SPECIFIC",
    eoriNumber: 't3',
    name: "Raminos",
    address: "Davidstraat 41",
    city: "Barcelona",
    countryCode: "ES",
    state: 'CA',
    zipCode: "party321",
    ownerShortName: "CHA1"
  }
];

@Injectable()
export class RefdataQueryHandlerMock extends QueryHandler {

  'com.portbase.bezoekschip.common.api.visit.GetMyCarriers' = (query: GetMyCarriers) => {
    return <Carrier[]>[
      {
        name: "Carrier 1" + (query.cargoDeclarantShortName ? " - " + query.cargoDeclarantShortName : ''),
        smdgCode: "CA1",
        cargoDeclarantShortName: query.cargoDeclarantShortName || AppContext.userProfile.organisation?.shortName,
      },
      {
        name: "Carrier 2" + (query.cargoDeclarantShortName ? " - " + query.cargoDeclarantShortName : ''),
        smdgCode: "CA2",
        cargoDeclarantShortName: query.cargoDeclarantShortName || AppContext.userProfile.organisation?.shortName,
      }];
  }

  'com.portbase.bezoekschip.common.api.visit.GetPcsPorts' = () => {
    return <PortOfCall[]>[
      {
        "sortingKey": 0,
        "port": {
          "name": "Rotterdam",
          "locationUnCode": "NLRTM",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Rotterdam N.V.",
          "shortName": "HBRRTM",
          "portAuthorityId": "HbR",
          "emailAddress": "HCC@portofrotterdam.com",
          "address": "Wilhelminakade 909",
          "city": "Rotterdam",
          "zipCode": "3072 AP",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-10-2521195",
          "faxNumber": "+31-10-2521000",
          "contact": "Diana van Eijk",
          "customsEORINumber": "NL24354561",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Rotterdam Haven/Kantoor Maasvlakte",
          "unCode": "NL000396"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": true,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": true,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true,
        "portAuthorityClearance": true
      },
      {
        "sortingKey": 1,
        "port": {
          "name": "Amsterdam",
          "locationUnCode": "NLAMS",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Amsterdam",
          "shortName": "HBAAMS",
          "portAuthorityId": "NLAMSHBA",
          "emailAddress": "htc@amsterdam.nl",
          "address": "De Ruijterkade 7",
          "city": "Amsterdam",
          "zipCode": "1013 AA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-20-5234500",
          "faxNumber": "+31-20-6209821",
          "contact": "Centrale Wachtchef",
          "customsEORINumber": "NL002564440B05",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": true,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": true,
        "orderNauticalServicesEnabled": true,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true,
        "portAuthorityClearance": true
      },
      {
        "sortingKey": 1,
        "port": {
          "name": "Delfzijl",
          "locationUnCode": "NLDZL",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Groningen Seaports NV",
          "shortName": "GSP",
          "portAuthorityId": "000000",
          "emailAddress": "operations@groningen-seaports.com",
          "address": "Handelskade Oost 1",
          "city": "Delfzijl",
          "zipCode": "9930 PA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0596-640400",
          "faxNumber": "0596-630464",
          "contact": "Dhr. Ronald Smits",
          "customsEORINumber": "NL000000050",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 1,
        "port": {
          "name": "Den Helder",
          "locationUnCode": "NLDHR",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Den Helder",
          "shortName": "HBDDHR",
          "portAuthorityId": "NLDHRHBDH",
          "emailAddress": "havenmeester@podh.eu",
          "address": "Het Nieuwe Diep 33",
          "city": "Den Helder",
          "zipCode": "1781 AD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-223-613955",
          "faxNumber": "+31-223-627780",
          "contact": "Servicedesk",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 1,
        "port": {
          "name": "Harlingen",
          "locationUnCode": "NLHAR",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Harlingen",
          "shortName": "HBHHAR",
          "portAuthorityId": "NLHARHBH",
          "emailAddress": "d.klinkenberg@portofharlingen.nl",
          "address": "Waddenpromenade 9-3",
          "city": "Harlingen",
          "zipCode": "8861 NT",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0517-723333",
          "contact": "Dirk Klinkenberg",
          "customsEORINumber": "NL001608836",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Bergen op Zoom",
          "locationUnCode": "NLBZM",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Bergen op Zoom",
          "shortName": "HBRBZM",
          "portAuthorityId": "HbBzm",
          "emailAddress": "havendienst@bergenopzoom.nl",
          "address": "Wilhelminakade 909",
          "city": "Bergen op Zoom",
          "zipCode": "3072 AP",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-6-14317010",
          "faxNumber": "+31-10-2521000",
          "contact": "Nanda de Boer",
          "customsEORINumber": "NL24354561",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Moerdijk",
          "unCode": "NL000855"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": true,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Breskens",
          "locationUnCode": "NLBRS",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Rijkshavenmeester Westerschelde",
          "shortName": "WESTERSCHELDE",
          "portAuthorityId": "RHMWS",
          "emailAddress": "bert.trommelen@rws.nl",
          "address": "Postbus 5014",
          "city": "Middelburg",
          "zipCode": "4330 KA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-118-424732",
          "faxNumber": "+31-118-424735",
          "contact": "L.E.P. Trommelen",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Everingen (Ankerplaats)",
          "locationUnCode": "NLANK",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Rijkshavenmeester Westerschelde",
          "shortName": "WESTERSCHELDE",
          "portAuthorityId": "RHMWS",
          "emailAddress": "bert.trommelen@rws.nl",
          "address": "Postbus 5014",
          "city": "Middelburg",
          "zipCode": "4330 KA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-118-424732",
          "faxNumber": "+31-118-424735",
          "contact": "L.E.P. Trommelen",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Foxhol",
          "locationUnCode": "NLFOX",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Provincie Groningen",
          "shortName": "HBPGO",
          "portAuthorityId": "HBPGO",
          "emailAddress": "j.tegelaar@provinciegroningen.nl",
          "address": "Sint Jansstraat 4",
          "city": "Groningen",
          "zipCode": "9712 JN",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "050-3164911",
          "contact": "Koos Tegelaar",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Groningen",
          "locationUnCode": "NLGRQ",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Provincie Groningen",
          "shortName": "HBPGO",
          "portAuthorityId": "HBPGO",
          "emailAddress": "j.tegelaar@provinciegroningen.nl",
          "address": "Sint Jansstraat 4",
          "city": "Groningen",
          "zipCode": "9712 JN",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "050-3164911",
          "contact": "Koos Tegelaar",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Hansweert/Schore",
          "locationUnCode": "NLHAN",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Rijkshavenmeester Westerschelde",
          "shortName": "WESTERSCHELDE",
          "portAuthorityId": "RHMWS",
          "emailAddress": "bert.trommelen@rws.nl",
          "address": "Postbus 5014",
          "city": "Middelburg",
          "zipCode": "4330 KA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-118-424732",
          "faxNumber": "+31-118-424735",
          "contact": "L.E.P. Trommelen",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Kampen",
          "locationUnCode": "NLKAM",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Kampen",
          "shortName": "HBKAM",
          "portAuthorityId": "HBKAM",
          "emailAddress": "e.kuik@kampen.nl",
          "address": "Burgemeester Berghuisplein 1",
          "city": "Kampen",
          "zipCode": "8261 DD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "06-30063288",
          "contact": "Edward Kuik",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Lauwersoog",
          "locationUnCode": "NLLAN",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Groningen Seaports NV",
          "shortName": "GSP",
          "portAuthorityId": "000000",
          "emailAddress": "sw-lauwersoog@groningen-seaports.com",
          "address": "Handelskade Oost 1",
          "city": "Delfzijl",
          "zipCode": "9930 PA",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0596-640400",
          "faxNumber": "0596-630464",
          "contact": "Dhr. Ronald Smits",
          "customsEORINumber": "NL000000050",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Leeuwarden",
          "locationUnCode": "NLLWR",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Harlingen",
          "shortName": "HBHHAR",
          "portAuthorityId": "NLHARHBH",
          "emailAddress": "d.klinkenberg@portofharlingen.nl",
          "address": "Waddenpromenade 9-3",
          "city": "Harlingen",
          "zipCode": "8861 NT",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0517-723333",
          "contact": "Dirk Klinkenberg",
          "customsEORINumber": "NL001608836",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Maasbracht",
          "locationUnCode": "NLMSB",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Maasgouw",
          "shortName": "HBMSB",
          "portAuthorityId": "HBMSB",
          "emailAddress": "e.weijzen@gemeentemaasgouw.nl",
          "address": "Markt 36",
          "city": "Maasbracht",
          "zipCode": "6051 DZ",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0475-255023",
          "contact": "Erik Weijzen",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Eindhoven/Maastricht Aachen Airport",
          "unCode": "NL000204"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Maastricht",
          "locationUnCode": "NLITR",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Maastricht",
          "shortName": "HBMST",
          "portAuthorityId": "HBMST",
          "emailAddress": "Gerard.Wijnands@maastricht.nl",
          "address": "Mosae Forum 10",
          "city": "Maastricht",
          "zipCode": "6211 DW",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "043-3505242",
          "contact": "Gerard Wijnands",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Eindhoven/Maastricht Aachen Airport",
          "unCode": "NL000204"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Middelburg",
          "locationUnCode": "NLMID",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Zeeland Seaports",
          "shortName": "ZEELANDSEAPORTS",
          "portAuthorityId": "PORTXCS",
          "emailAddress": "port@zeeland-seaports.com",
          "address": "Schelpenpad 2",
          "city": "Terneuzen",
          "zipCode": "4531 PD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-115-647400",
          "faxNumber": "+31-115-647500",
          "contact": "Zeeland Seaports",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Roermond",
          "locationUnCode": "NLOMD",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Roermond",
          "shortName": "HBOMD",
          "portAuthorityId": "HBOMD",
          "emailAddress": "harryvandewetering@roermond.nl",
          "address": "Kazerneplein 7",
          "city": "Roermond",
          "zipCode": "6041 TG",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0475 359 229",
          "contact": "Harry van de Wetering",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Eindhoven/Maastricht Aachen Airport",
          "unCode": "NL000204"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Scheemda",
          "locationUnCode": "NLSMD",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Provincie Groningen",
          "shortName": "HBPGO",
          "portAuthorityId": "HBPGO",
          "emailAddress": "j.tegelaar@provinciegroningen.nl",
          "address": "Sint Jansstraat 4",
          "city": "Groningen",
          "zipCode": "9712 JN",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "050-3164911",
          "contact": "Koos Tegelaar",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Scheveningen",
          "locationUnCode": "NLSCE",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Scheveningen",
          "shortName": "HBSSCE",
          "portAuthorityId": "NLSCEHBS",
          "emailAddress": "roger.reubzaet@denhaag.nl",
          "address": "Visafslagweg 1",
          "city": "Den Haag",
          "zipCode": "2583 DM",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "070-3527711",
          "faxNumber": "070-3522151",
          "contact": "Roger Reubzaet",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Terneuzen",
          "locationUnCode": "NLTNZ",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Zeeland Seaports",
          "shortName": "ZEELANDSEAPORTS",
          "portAuthorityId": "PORTXCS",
          "emailAddress": "port@zeeland-seaports.com",
          "address": "Schelpenpad 2",
          "city": "Terneuzen",
          "zipCode": "4531 PD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-115-647400",
          "faxNumber": "+31-115-647500",
          "contact": "Zeeland Seaports",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": true,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Texel",
          "locationUnCode": "NLTEX",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Den Helder",
          "shortName": "HBDDHR",
          "portAuthorityId": "NLDHRHBDH",
          "emailAddress": "havenmeester@podh.eu",
          "address": "Het Nieuwe Diep 33",
          "city": "Den Helder",
          "zipCode": "1781 AD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-223-613955",
          "faxNumber": "+31-223-627780",
          "contact": "Servicedesk",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Urk",
          "locationUnCode": "NLURK",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Urk",
          "shortName": "HBURK",
          "portAuthorityId": "HBURK",
          "emailAddress": "e.devries@urk.nl",
          "address": "Singel 9",
          "city": "Urk",
          "zipCode": "8321 GT",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "06-51239046",
          "contact": "Eef de Vries",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Amsterdam/Kantoor Amsterdam",
          "unCode": "NL000854"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Veendam",
          "locationUnCode": "NLVDM",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Provincie Groningen",
          "shortName": "HBPGO",
          "portAuthorityId": "HBPGO",
          "emailAddress": "j.tegelaar@provinciegroningen.nl",
          "address": "Sint Jansstraat 4",
          "city": "Groningen",
          "zipCode": "9712 JN",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "050-3164911",
          "contact": "Koos Tegelaar",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Venlo",
          "locationUnCode": "NLVEN",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Venlo",
          "shortName": "HBVEN",
          "portAuthorityId": "HBVEN",
          "emailAddress": "p.wennekers@venlo.nl",
          "address": "Hanzeplaats 1",
          "city": "Venlo",
          "zipCode": "5912 AT",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "06-14354072",
          "contact": "Piet Wennekers",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Eindhoven/Maastricht Aachen Airport",
          "unCode": "NL000204"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": true,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Vlissingen",
          "locationUnCode": "NLVLI",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Zeeland Seaports",
          "shortName": "ZEELANDSEAPORTS",
          "portAuthorityId": "PORTXCS",
          "emailAddress": "port@zeeland-seaports.com",
          "address": "Schelpenpad 2",
          "city": "Terneuzen",
          "zipCode": "4531 PD",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-115-647400",
          "faxNumber": "+31-115-647500",
          "contact": "Zeeland Seaports",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Breda/Kantoor Vlissingen",
          "unCode": "NL000856"
        },
        "ataReported": true,
        "atdReported": true,
        "atdPortReported": true,
        "paDeclarationRequired": false,
        "swDeclarationRequired": false,
        "wasteEnabled": false,
        "dangerousGoodsEnabled": false,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": true
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Waterhuizen",
          "locationUnCode": "NLWHZ",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Provincie Groningen",
          "shortName": "HBPGO",
          "portAuthorityId": "HBPGO",
          "emailAddress": "j.tegelaar@provinciegroningen.nl",
          "address": "Sint Jansstraat 4",
          "city": "Groningen",
          "zipCode": "9712 JN",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "050-3164911",
          "contact": "Koos Tegelaar",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "Werkendam",
          "locationUnCode": "NLWKD",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Gemeente Werkendam",
          "shortName": "HBRWKD",
          "portAuthorityId": "HbWkd",
          "emailAddress": "havendienst@gemeentealtena.nl",
          "address": "Wilhelminakade 909",
          "city": "Werkendam",
          "zipCode": "3072 AP",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "+31-6-53183120",
          "faxNumber": "+31-10-2521000",
          "contact": "Jan Smit",
          "customsEORINumber": "NL24354561",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Rotterdam Haven/Kantoor Maasvlakte",
          "unCode": "NL000396"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": true,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      },
      {
        "sortingKey": 2,
        "port": {
          "name": "West-Terschelling",
          "locationUnCode": "NLWTE",
          "countryUnCode": "NL",
          "euPort": true
        },
        "portAuthority": {
          "fullName": "Havenbedrijf Harlingen",
          "shortName": "HBHHAR",
          "portAuthorityId": "NLHARHBH",
          "emailAddress": "d.klinkenberg@portofharlingen.nl",
          "address": "Waddenpromenade 9-3",
          "city": "Harlingen",
          "zipCode": "8861 NT",
          "countryUnCode": "NL",
          "countryName": "Netherlands",
          "phoneNumber": "0517-723333",
          "contact": "Dirk Klinkenberg",
          "customsEORINumber": "NL001608836",
          "ensEnabled": false,
          "cargoImportEnabled": false,
          "cargoImportEnabledByDefault": false
        },
        "customsOffice": {
          "name": "Groningen/Kantoor Eemshaven",
          "unCode": "NL000282"
        },
        "ataReported": false,
        "atdReported": false,
        "atdPortReported": false,
        "paDeclarationRequired": true,
        "swDeclarationRequired": true,
        "wasteEnabled": true,
        "dangerousGoodsEnabled": true,
        "harbourDuesEnabled": false,
        "orderNauticalServicesEnabled": false,
        "enableNotificationsToPa": false,
        "authoriseOrganisationsDisabled": false,
        "tugBoatsRequired": false
      }
    ];
  };

  'com.portbase.bezoekschip.common.api.dangerousgoods.FindDangerousGoods' = (query) => {
    let result = [];
    result = [
      <SolidData>{
        "stowageType": "hold",
        "unCode": null,
        "gdsCode": null,
        "name": "SolidData(hold) - Nickel Concentrate",
        "id": "ff11ff0b-4a93-4b80-a419-4b1b1167947e",
        "type": "solid",
        "goodCode": "402769",
        "hazardClass": "A"
      }, <GasData>{
        "stowageType": "tank",
        "unCode": null,
        "gdsCode": null,
        "name": "Gas(tank) - Ammonia, Anhydrous",
        "id": "8bae84c9-ddef-4389-9524-283bf2960f4d",
        "type": "gas",
        "goodCode": "300101"
      }, <ContainerGoodData>{
        "stowageType": "container",
        "emsSpillageCode": "S-A",
        "type": "containerGood",
        "subsidiaryRisk": null,
        "unCode": "3006",
        "segregationGroup": null,
        "gdsCode": null,
        "name": "Container(container) - Thiocarbamate Pesticide, Liquid, Toxic",
        "emsFireCode": "F-A",
        "id": "ffed854a-859e-4e09-a4cf-325af569017c",
        "goodCode": "4000005209",
        "packingGroup": "GREAT_DANGER",
        "properties": "Liquid pesticides which present a very wide range of toxic hazard. Miscibility with water depends upon the composition. Toxic if swallowed, by skin contact or by inhalation.",
        "hazardClass": "6.1"
      }, <ContainerGoodData>{
        "stowageType": "container",
        "emsSpillageCode": "S-M",
        "type": "containerGood",
        "subsidiaryRisk": null,
        "unCode": "2546",
        "segregationGroup": "SGG7, SGG15",
        "gdsCode": null,
        "name": "Container(container) - Titanium Powder, Dry",
        "emsFireCode": "F-G",
        "id": "ff906175-1b60-4592-b350-97a5626cdd88",
        "goodCode": "4000004653",
        "packingGroup": "MINOR_DANGER",
        "properties": "Grey powder. Liable to ignite spontaneously in air. Forms explosive mixtures with oxidizing substances.",
        "hazardClass": "4.2"
      }, <OilData>{
        "stowageType": "tank",
        "gdsCode": null,
        "name": "Oil(tank) - SOMENTOR (code) (Exxonmobil)",
        "id": "7676af69-24c4-4ff0-9c1f-5fa6d06109eb",
        "type": "oil",
        "goodCode": "503912"
      }, <ChemicalData>{
        "stowageType": "tank",
        "pollutionCategory": "Y",
        "gdsCode": null,
        "name": "Chemical(tank) - ALKYL (C9+)Benzenes-YES",
        "id": "ffec55ce-afd1-49e6-ba12-fdc78f30504f",
        "type": "chemical",
        "goodCode": "209887",
        "specOpRequirements": "15.19.6",
        "hazardClass": "S/P",
        "prewashRequired": true
      }, <ChemicalData>{
        "stowageType": "tank",
        "pollutionCategory": "Y",
        "gdsCode": null,
        "name": "Chemical(tank) - ALKYL (C9+)Benzenes-NO",
        "id": "ffec55ce-afd1-49e6-ba12-fdc78f30504f",
        "type": "chemical",
        "goodCode": "209888",
        "specOpRequirements": "15.19.6",
        "hazardClass": "S/P",
        "prewashRequired": false
      }, <ChemicalData>{
        "stowageType": "tank",
        "pollutionCategory": "Y",
        "gdsCode": null,
        "name": "Chemical(tank) - ALKYL (C9+)Benzenes-NONE",
        "id": "ffec55ce-afd1-49e6-ba12-fdc78f30504f",
        "type": "chemical",
        "goodCode": "209889",
        "specOpRequirements": "15.19.6",
        "hazardClass": "S/P"
      }
    ];
    return query.stowageType ? of(result.filter(value => value.stowageType == query.stowageType)) : of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.FindWasteMaterials' = (query: FindWasteMaterials) => {
    let result = <WasteMaterial[]>[{
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Monitor (p/stuk)",
      "start": "2023-01-01",
      "id": "ff8c4ac3-91c2-4814-81fb-8c0f038ad628",
      "nameEN": "Monitor (p/piece)",
      "euralCode": "20.01.35*",
      "iltCode": "7274",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7274"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Ongesorteerd machinekamerafval",
      "start": "2023-01-01",
      "id": "ff7070fe-2972-429a-bc20-6dfc4e2a1b9d",
      "nameEN": "Unsorted oily engineroom waste",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7055"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Rookgas Reinigingsresidu",
      "start": "2023-01-01",
      "id": "ff6c62b3-1b41-45ba-a6f6-94e682e36374",
      "nameEN": "Gas residence waste",
      "euralCode": "10.03.23*",
      "ssn": {
        "specificationRequired": false,
        "code": "602",
        "description": "Exhaust gas-cleaning residues",
        "marpolCategory": "Annex VI"
      },
      "typeCode": "Code_7303"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Hydraulic slangen (ex olie)",
      "start": "2023-01-01",
      "id": "fdca47ab-490c-494c-81fb-541e18f1fc3b",
      "nameEN": "Hydraulic hoses  (ex oil)",
      "euralCode": "16.07.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7049"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Poetsdoeken - niet met olie vervuild",
      "start": "2023-01-01",
      "id": "fd1c72fd-62da-454c-b5e6-7617e7db4b46",
      "nameEN": "Cleaning rags not contaminated with oil",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7056"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gasflessen (p/kg)",
      "start": "2023-01-01",
      "id": "fcfe0fd6-bdac-4caf-9fa5-e9986965e1b6",
      "nameEN": "Gasbottles (p/kg)",
      "euralCode": "16.05.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7112"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "CO2 vulling",
      "start": "2023-01-01",
      "id": "fbb18eb7-531b-43fc-a501-7cf57bc3d2b4",
      "nameEN": "CO2 Cartridge",
      "euralCode": "15.01.11*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7171"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "F. Operationeel afval (Niet vergoed)",
      "start": "2023-01-01",
      "id": "fa93c8d7-5100-4ede-9091-7eac05ced632",
      "nameEN": "F. Operational wastes (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7078"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Papier vermengd met afval",
      "start": "2023-01-01",
      "id": "f8fcac7d-fabf-4819-9804-ef9be13b767b",
      "nameEN": "Paper contaminated with waste",
      "euralCode": "20.01.01",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7024"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Schroot verontreinigd met olie",
      "start": "2023-01-01",
      "id": "f768591a-e29e-42aa-ae96-ea645c733b13",
      "nameEN": "Scrap contaminated with oil",
      "euralCode": "19.12.11*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7058"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Stuwhout",
      "start": "2023-01-01",
      "id": "f4b0a6d5-5170-4fbd-a73e-11b6185af4de",
      "nameEN": "Dunnage (wood)",
      "euralCode": "15.01.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7081"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gasmaskers",
      "start": "2023-01-01",
      "id": "f261abe7-1cfe-4dfe-a67a-a194fa82f7b3",
      "nameEN": "Gas masks",
      "euralCode": "16.05.05",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7187"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Koelvloeistof",
      "start": "2023-01-01",
      "id": "f24f4f7f-9545-4a61-b71f-694e63342d73",
      "nameEN": "Cooling liquid",
      "euralCode": "16.01.14*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7209"
    }, {
      "unitConversionToCubicMeters": 0.2,
      "unitOfMeasure": "units",
      "nameNL": "Plastic vat 200 ltr chemicalien (vol)",
      "start": "2023-01-01",
      "id": "f1f2bddd-eed5-4a30-9051-1bcae9024bae",
      "nameEN": "Plastic drum 200 ltr. chemicals (full)",
      "euralCode": "07.01.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7229"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "Reddingsvlot",
      "start": "2023-01-01",
      "id": "f005230b-5269-413a-99d8-8206ca2e0419",
      "nameEN": "Life-raft",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7140"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Stalen vaten (vol) per 200 ltr met poetslappen",
      "start": "2023-01-01",
      "id": "ecbbce88-2450-4f32-9415-59740da03c8d",
      "nameEN": "Drums full with rags per 200 ltr",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7059"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Wit- en bruingoed",
      "iltCode": "1234",
      "start": "2023-01-01",
      "id": "ec75350c-2ddd-406a-b87b-c18ce8e2681d",
      "nameEN": "Household equipment",
      "euralCode": "20.01.35*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7267"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vet van sewage behandeling",
      "start": "2023-01-01",
      "id": "ebe69396-66d0-4ae9-8365-9470f18bf81c",
      "nameEN": "Grease from sewage/black water treatment",
      "euralCode": "20.01.26*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7247"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Zout",
      "start": "2023-01-01",
      "id": "eba2016f-0e8f-4d59-8306-1d66a4113b74",
      "nameEN": "Solid Salt",
      "euralCode": "06.03.14",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7254"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "E. Verbrandingsassen (Niet vergoed)",
      "start": "2023-01-01",
      "id": "eb3bc6d2-fd37-4ea0-ac05-6a7545d39646",
      "nameEN": "E. Incinerator ashes (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "505",
        "description": "E. Incinerator ashes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7076"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Helicopter Brandstof",
      "start": "2023-01-01",
      "id": "ead807bf-b700-493e-8850-9056d1c29610",
      "nameEN": "Helicopter fuel",
      "euralCode": "13.07.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7200"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Sanitair afval",
      "start": "2023-01-01",
      "id": "e94631f5-9869-46aa-988a-a45fb2b7e07d",
      "nameEN": "Sewage",
      "euralCode": "20.03.04",
      "ssn": {
        "specificationRequired": false,
        "code": "401",
        "description": "Sewage",
        "marpolCategory": "Annex IV"
      },
      "typeCode": "Code_7021"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Glas vermengd met porcelein",
      "start": "2023-01-01",
      "id": "e91d87ca-5724-4cbb-943f-2fc6ece8c60b",
      "nameEN": "Glass mixed with crockery",
      "euralCode": "20.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7036"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Sludge",
      "start": "2023-01-01",
      "id": "e87961f4-4495-4e06-8b57-d2e831a896e1",
      "nameEN": "Oily Residues (Sludge)",
      "euralCode": "13.07.03",
      "ssn": {
        "specificationRequired": false,
        "code": "102",
        "description": "Oily Residues (Sludge)",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7002"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "m3",
      "nameNL": "Geperst Papier",
      "start": "2023-01-01",
      "id": "e7ee1146-c537-4ed8-b387-b5653e80b87f",
      "nameEN": "Compressed paper",
      "euralCode": "20.01.01",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7087"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "K. Waswater droge lading HME,",
      "start": "2023-01-01",
      "id": "e7d6344d-a921-4224-88db-759a64cefc31",
      "nameEN": "K. Washwater Cargo residues (HME)",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "511",
        "description": "K. Cargo residues (HME)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7298"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Emballage IBC (vol) olie (p/kg)",
      "start": "2023-01-01",
      "id": "e751a448-948c-40f7-bc5f-c2b8037fadd4",
      "nameEN": "Packaging IBC (full) with oil waste (p/kg)",
      "euralCode": "13.02.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7177"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Afvalwater",
      "start": "2023-01-01",
      "id": "e357a221-7819-4436-b5c8-b6430402d49b",
      "nameEN": "Waste water",
      "euralCode": "19.08.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7143"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ladinggebonden afval (p/m3)",
      "start": "2023-01-01",
      "id": "e3404481-45e8-42d9-bb0c-5aa9d2c99cd3",
      "nameEN": "Cargo associated waste (p/m3)",
      "euralCode": "15.01.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7079"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "H. Visgerei",
      "start": "2023-01-01",
      "id": "e30d0728-0119-46b6-96d6-88f11fde082b",
      "nameEN": "H. Fishing gear (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "508",
        "description": "H. Fishing gear",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7259"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "PVC-Tubes ex Fumigation",
      "start": "2023-01-01",
      "id": "e284d861-8954-48e2-9e45-dbc0b7dc451d",
      "nameEN": "PVC-Tubes ex Fumigation",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7029"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "Emballage IBC ex. chemicaliën (p/stuk)",
      "start": "2023-01-01",
      "id": "df958833-fd7e-4fe3-a7e7-2635bca782ed",
      "nameEN": "Packaging IBC - ex. chemicals (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7175"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Kwikhoudend afval",
      "start": "2023-01-01",
      "id": "ddcd11a9-18a6-41a0-82c1-93edba452047",
      "nameEN": "Wastes containing mercury",
      "euralCode": "06.04.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7210"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ladingrestanten Categorie Y",
      "start": "2023-01-01",
      "id": "d96020e2-898f-48a4-9d0b-5ccffbbe2960",
      "nameEN": "Cargo residue Category Y",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "202",
        "description": "Category Y substance",
        "marpolCategory": "Annex II"
      },
      "typeCode": "Code_7012"
    }, {
      "unitConversionToCubicMeters": 0.025,
      "unitOfMeasure": "units",
      "nameNL": "Oliehoudende emballage, staal per 20/25 ltr. (p/stuk)",
      "start": "2023-01-01",
      "id": "d8f8db9a-1db1-448c-bf78-ddb4e7df8d25",
      "nameEN": "Empty drums ex-oil per 20/25 ltr. (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7129"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gaspatronen",
      "start": "2023-01-01",
      "id": "d83673fb-e428-4da0-9db7-6144b1dbf41d",
      "nameEN": "Gas cartridges",
      "euralCode": "16.05.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7189"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "IBC (vol) (chemicalien) - (p/stuk)",
      "start": "2023-01-01",
      "id": "d7753256-2a3f-47f5-bf89-8ef1d7c6cd4b",
      "nameEN": "IBC full with chemicals waste (per piece)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7197"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Afvalolie",
      "start": "2023-01-01",
      "id": "d2e10692-f376-4e56-950f-401714abfa1a",
      "nameEN": "Wasteoil",
      "euralCode": "13.02.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7155"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Waterfilter",
      "start": "2023-01-01",
      "id": "d1bbab11-e2de-4476-8511-bfeffdf3d203",
      "nameEN": "Waterfilter",
      "euralCode": "16.01.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7250"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Lijm/Kit",
      "start": "2023-01-01",
      "id": "d09aa340-6fac-4f39-83d5-f97c1215fdf6",
      "nameEN": "Adhesives / sealants",
      "euralCode": "08.04.09*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7214"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Medisch afval  (naalden/mesjes etc)",
      "start": "2023-01-01",
      "id": "ced7eaf8-c1f6-4347-9021-503d2e3585ce",
      "nameEN": "Medical waste with special requirements  (sharps/razors etc)",
      "euralCode": "18.01.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7122"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Grafiet",
      "start": "2023-01-01",
      "id": "cdcaa36d-ee5e-4b00-80a9-6e8fa0fc2e7e",
      "nameEN": "Graphite",
      "euralCode": "16.07.09*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7193"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Trossen",
      "start": "2023-01-01",
      "id": "ccf60caa-3da4-44ed-b405-892fdc24ccdf",
      "nameEN": "Mooring Tails",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7095"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Verbranding as",
      "start": "2023-01-01",
      "id": "cc922bc0-6fe9-4373-b108-fbfd35d24af5",
      "nameEN": "Incinerator ashes",
      "euralCode": "19.01.12",
      "ssn": {
        "specificationRequired": false,
        "code": "505",
        "description": "E. Incinerator ashes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7077"
    }, {
      "unitConversionToCubicMeters": 0.2,
      "unitOfMeasure": "units",
      "nameNL": "Stalen vaten met rest (ex-olie) per 200 ltr.(p/stuk)",
      "start": "2023-01-01",
      "id": "cc20bd54-e6f2-41f0-9edf-2998cfa40471",
      "nameEN": "Empty drums (ex oil) per 200 ltr.(p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7138"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "IBC (vol) (olie)  (p/stuk)",
      "start": "2023-01-01",
      "id": "cc07dc0f-b88b-49c7-b37a-4b5cebb7e360",
      "nameEN": "IBC full with oil (p/piece)",
      "euralCode": "13.07.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7198"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Koolstof",
      "start": "2023-01-01",
      "id": "c8a174b8-8d3d-4ae2-9884-16c4de150ce7",
      "nameEN": "Carbon",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7167"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Bilgewater/lenswater",
      "start": "2023-01-01",
      "id": "c7af0c86-e20d-485b-bbb0-f880f613328b",
      "nameEN": "Oily Bilge water",
      "euralCode": "13.04.01",
      "ssn": {
        "specificationRequired": false,
        "code": "101",
        "description": "Oily Bilge water",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7001"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ladingrestanten Categorie OS",
      "start": "2023-01-01",
      "id": "c6e8a748-5816-4f78-996f-97bad9ca5aa3",
      "nameEN": "Cargo residue Category OS",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "204",
        "description": "OS - other substances",
        "marpolCategory": "Annex II"
      },
      "typeCode": "Code_7018"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vaten met sewage",
      "start": "2023-01-01",
      "id": "c61d33ff-9c5f-45b2-8a61-e51a9e411b4d",
      "nameEN": "Drums with sewage sludge",
      "euralCode": "19.08.05",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7246"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Papier",
      "start": "2023-01-01",
      "id": "c552e6ff-029a-4ab7-b995-db6dddcd34b0",
      "nameEN": "Paper",
      "euralCode": "20.01.01",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7041"
    }, {
      "unitConversionToCubicMeters": 0.02,
      "unitOfMeasure": "units",
      "nameNL": "Reddingsvest met gaspatroon",
      "start": "2023-01-01",
      "id": "c4d758b0-62fc-423c-9754-f3c6e64b32e0",
      "nameEN": "Life-jacket with gas cartridge",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7239"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Medicijnafval",
      "start": "2023-01-01",
      "id": "c44b32ec-dfa9-4149-a36c-baad9c49c9e9",
      "nameEN": "Medical waste",
      "euralCode": "20.01.32",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7121"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Brandblussers (p/kg)",
      "start": "2023-01-01",
      "id": "c25c8b7e-f529-470f-9375-7eb98f08ecd4",
      "nameEN": "Fire extinguishers (p/kg)",
      "euralCode": "16.05.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7102"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Matrassen (p/m3)",
      "start": "2023-01-01",
      "id": "c20a1628-63b3-4676-9837-fb7bc117a6b6",
      "nameEN": "Mattresses (p/m3)",
      "euralCode": "20.03.07",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7039"
    }, {
      "unitConversionToCubicMeters": 0.00050,
      "unitOfMeasure": "units",
      "nameNL": "Monsterflessen 0-0,5 liter (p/stuk)",
      "start": "2023-01-01",
      "id": "c2069107-e99f-4c46-a0fd-6a9a06981383",
      "nameEN": "Sample Bottles 0-0,5 liter (p/stuk)",
      "euralCode": "14.06.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7123"
    }, {
      "unitConversionToCubicMeters": 0.02,
      "unitOfMeasure": "units",
      "nameNL": "Jerrycans  ex chemie/olie (p/stuk)",
      "start": "2023-01-01",
      "id": "c1191f1f-04c0-47f2-90f5-706a91d12051",
      "nameEN": "Jerrycans  ex chemicals/oil (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7117"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Minerale vetten",
      "start": "2023-01-01",
      "id": "bc8db8bd-9c8f-426b-9f5b-76a290492d09",
      "nameEN": "Mineral grease",
      "euralCode": "20.01.26*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7221"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Plastic vermengd met voedselrestanten en/of gevaarlijk afval",
      "start": "2023-01-01",
      "id": "bbbf9158-e87b-4b5e-8bcc-70d069c53ac7",
      "nameEN": "Plastic contaminated with food and/or hazardous components",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7027"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Porcelein/aardewerk",
      "start": "2023-01-01",
      "id": "ba4cea33-3c2d-4ad7-bc85-c11f2c1d2ad7",
      "nameEN": "Crockery (China)",
      "euralCode": "20.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7042"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "m3",
      "nameNL": "Geperst schoon Plastic",
      "start": "2023-01-01",
      "id": "b72c2e0e-dfc0-4b6b-b812-a4ca73381230",
      "nameEN": "Plastic clean (compressed)",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7025"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ladingrestanten Categorie X",
      "start": "2023-01-01",
      "id": "b71296e9-b26e-491e-b51c-60cbf45292b7",
      "nameEN": "Cargo residue Category X",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "201",
        "description": "Category X substance",
        "marpolCategory": "Annex II"
      },
      "typeCode": "Code_7009"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Spuitbussen",
      "start": "2023-01-01",
      "id": "b70a4668-262f-4538-838d-75fed5d66fcf",
      "nameEN": "Aerosols various",
      "euralCode": "16.05.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7135"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Electronica schroot (apparatuur)",
      "start": "2023-01-01",
      "id": "b407c4da-49ee-4a76-bd4e-e0e9cefe7dbc",
      "nameEN": "Electronical scrap (equipment)",
      "euralCode": "16.02.14",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7263"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "BigBags scheeps/bedrijfsafval",
      "start": "2023-01-01",
      "id": "b3aeb4dd-134d-427c-a86c-6fa5bf58b51e",
      "nameEN": "Big bags pantry & galley waste",
      "euralCode": "20.03.01",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7085"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Kortlevend medisch afval (luiers/maandverband etc.)",
      "start": "2023-01-01",
      "id": "b3893496-42c1-4ba9-8183-21800e6832d4",
      "nameEN": "Medical without special requirements (dressings, plastercasts, linen, disposable clothing, diapers)",
      "euralCode": "18.01.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7120"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Asphalt/bitumen (p/kg)",
      "start": "2023-01-01",
      "id": "b37b49bf-8c4b-46c6-a17c-dfdb753a8df6",
      "nameEN": "Asphalt/bitumen (p/kg)",
      "euralCode": "17.03.02",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7159"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "D. Frituurvet (Niet vergoed)",
      "start": "2023-01-01",
      "id": "b26099c6-825f-4804-81e9-a98451f2699e",
      "nameEN": "D. Cooking oil  (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "504",
        "description": "D. Cooking oil",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7072"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Afgewerkte olie",
      "start": "2023-01-01",
      "id": "b0ea180b-c996-4e3d-afb1-6c856cb9c4f9",
      "nameEN": "Used Engine Oil",
      "euralCode": "13.02.04",
      "ssn": {
        "specificationRequired": false,
        "code": "102",
        "description": "Oily Residues (Sludge)",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7003"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Lasdraad",
      "start": "2023-01-01",
      "id": "ae3b70a3-863c-4907-adc1-97dba8861fdd",
      "nameEN": "Welding Wire",
      "euralCode": "17.04.05",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7066"
    }, {
      "unitConversionToCubicMeters": 1,
      "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
      "unitOfMeasure": "m3",
      "nameNL": "Marpol Annex I Overig",
      "start": "2023-01-01",
      "id": "ab57eac0-66b3-42f6-a409-2ed8c0ef63d2",
      "nameEN": "Marpol Annex I Other",
      "ssn": {
        "specificationRequired": true,
        "code": "999",
        "description": "Other",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7007"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Visnetten",
      "start": "2023-01-01",
      "id": "ab47e333-1a3d-44ed-a010-056752f48bee",
      "nameEN": "Fishing nets",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "508",
        "description": "H. Fishing gear",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7260"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "B. Voedsel restantanten (niet vergoed)",
      "start": "2023-01-01",
      "id": "a95885a7-d5fd-46ea-8ea2-14a66cae8d80",
      "nameEN": "B. Food waste  (Non refundable)",
      "euralCode": "20.01.08",
      "ssn": {
        "specificationRequired": false,
        "code": "502",
        "description": "B. Food waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7030"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Tapijt",
      "start": "2023-01-01",
      "id": "a908d8d7-2c1d-4901-af12-b31394f77737",
      "nameEN": "Carpet",
      "euralCode": "20.03.07",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7071"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "IBC vol met water/Ethanol",
      "start": "2023-01-01",
      "id": "a834760a-8e57-4bf6-b09c-9d373eeaa4f9",
      "nameEN": "IBC full with water/Ethanol",
      "euralCode": "16.10.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7152"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Luchtfilters (p/kg)",
      "start": "2023-01-01",
      "id": "a81e148c-26e8-4868-ba0e-6b158e2b9de3",
      "nameEN": "Airfilter (p/kg)",
      "euralCode": "15.02.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7217"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Asbest (p/kg)",
      "start": "2023-01-01",
      "id": "a7f55894-2cf9-4f0e-b8de-ed302f6650ec",
      "nameEN": "Asbestos (p/kg)",
      "euralCode": "17.06.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7158"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Wasmiddelen",
      "start": "2023-01-01",
      "id": "a508d4a4-f1f3-447a-9fa9-004706ac2503",
      "nameEN": "Washing liquids",
      "euralCode": "07.06.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7249"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Touwen/Netten",
      "start": "2023-01-01",
      "id": "a240a5e5-26ab-482c-ab65-783c4bf69060",
      "nameEN": "Ropes / Nets",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7096"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Electronica kabels (p/kg)",
      "start": "2023-01-01",
      "id": "a1e17fc6-be5a-4ee7-8ba3-c49e0aa88348",
      "nameEN": "Electrical cables (p/kg)",
      "euralCode": "17.04.11",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7270"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Anorganische zuren",
      "start": "2023-01-01",
      "id": "a06597fe-8c7b-455e-92cb-e10c5deda7e6",
      "nameEN": "Inorganic acids",
      "euralCode": "06.03.14",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7157"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Glasvezelkabel",
      "start": "2023-01-01",
      "id": "9d663304-4937-4030-988d-72c25f2e7a16",
      "nameEN": "Fiberglass cable",
      "euralCode": "17.04.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7191"
    }, {
      "unitConversionToCubicMeters": 0.5,
      "unitOfMeasure": "units",
      "nameNL": "Droger (p/stuk)",
      "start": "2023-01-01",
      "id": "9c404b18-48c2-420e-b824-2313bf830610",
      "nameEN": "Dryer (p/piece)",
      "euralCode": "16.02.14",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7262"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "AFF blusvloeistof",
      "start": "2023-01-01",
      "id": "99ad3003-2736-4bc4-a9bb-b0c5ec31ed0c",
      "nameEN": "AFF extinguishing liquid",
      "euralCode": "16.10.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7098"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "Emballage IBC ex. olie  (p/stuk)",
      "start": "2023-01-01",
      "id": "98eed2d2-e071-4dba-89c8-3a0a9d613e61",
      "nameEN": "Packaging IBC (ex. Oil)  (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7176"
    }, {
      "unitConversionToCubicMeters": 0.2,
      "unitOfMeasure": "units",
      "nameNL": "Plastic vat 200 ltr sediment (vol)",
      "start": "2023-01-01",
      "id": "97e5fde5-f9ca-4389-90d7-cd44bb6d3ac9",
      "nameEN": "Plastic drum 200 ltr. sediments (full)",
      "euralCode": "13.02.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7237"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Rubber/banden",
      "start": "2023-01-01",
      "id": "96200d82-c626-435c-b22a-2c99faa914ae",
      "nameEN": "Rubber/tyres",
      "euralCode": "16.01.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7084"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Isolatiemateriaal",
      "start": "2023-01-01",
      "id": "95df94cb-416d-4ed7-b1b3-d93239c95c19",
      "nameEN": "Isolationmaterials",
      "euralCode": "17.06.04",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7148"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Chemisch afval (kleinverpakking)",
      "start": "2023-01-01",
      "id": "95c86d6c-aef8-40c0-b521-99b8be66404c",
      "nameEN": "Chemical waste (packaging, small)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7108"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vetmengsel uit olie/waterscheiders die uitsluitend spijksolie en vetten bevatten",
      "start": "2023-01-01",
      "id": "9498195c-13a2-4d20-8633-cc1f18edb5a0",
      "nameEN": "Grease and oil mixture from oil/water separation containing edible oil and fats",
      "euralCode": "19.08.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "504",
        "description": "D. Cooking oil",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7075"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Algen (ex zwembad en dergelijke)",
      "start": "2023-01-01",
      "id": "936640de-4d8f-4fee-874c-49592c574b0c",
      "nameEN": "Algae (ex spa)",
      "euralCode": "20.01.15*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7061"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Behandeld hout (C-hout)",
      "start": "2023-01-01",
      "id": "91d17ebb-7271-49e9-b597-20441ebdf995",
      "nameEN": "Treated wood with dangerous substances (C-wood)",
      "euralCode": "20.01.37*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7086"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Passief opgevist afval",
      "start": "2023-01-01",
      "id": "9028282a-de17-4e22-bad3-01203a16d97a",
      "nameEN": "Passively fished waste",
      "ssn": {
        "specificationRequired": false,
        "code": "991",
        "description": "Passively fished waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7304"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Zaagsel met olie",
      "start": "2023-01-01",
      "id": "8e4204ae-7f13-4e1d-b174-ef6166e82522",
      "nameEN": "Oily residues mixed with sawdust",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7060"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Electronica schroot (p/kg)",
      "start": "2023-01-01",
      "id": "8d59aaa8-952b-4788-a4d7-1f363b42d2fe",
      "nameEN": "Electronical waste (p/kg)",
      "euralCode": "16.02.16",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7271"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Filterzakken",
      "start": "2023-01-01",
      "id": "8a2ec6b2-df75-4bb0-9596-0abb005a7f14",
      "nameEN": "Filter bags",
      "euralCode": "15.02.03",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7048"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Staaldraad",
      "start": "2023-01-01",
      "id": "87519fbc-1092-4ed7-83b2-b41fae514036",
      "nameEN": "Steel wire",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7093"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Afgewerkte olie (verpakt)",
      "start": "2023-01-01",
      "id": "84bbb5e4-4319-44e8-b709-65c5f8ad8406",
      "nameEN": "Used oil (packed)",
      "euralCode": "13.02.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7047"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "IBC Brandblusfoam (liquid) AFFF",
      "start": "2023-01-01",
      "id": "845a71fd-4e04-4aed-afc0-4ee9fc475b23",
      "nameEN": "IBC Fire extinguish foam (liquid) AFFF",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7199"
    }, {
      "unitConversionToCubicMeters": 0.07,
      "unitOfMeasure": "units",
      "nameNL": "Wasmachine (p/stuk)",
      "start": "2023-01-01",
      "id": "83a501de-f406-4352-a71d-d2fb720cb94a",
      "nameEN": "Washing machine (p/piece)",
      "euralCode": "20.01.36",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7266"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "C.Huisvuil (niet vergoed)",
      "start": "2023-01-01",
      "id": "81d7b6b7-55f5-4980-b8ee-94567f770583",
      "nameEN": "C. Domestic wastes   (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7034"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Pesticiden",
      "start": "2023-01-01",
      "id": "81842b98-4f15-45e7-85f0-ea9f775d7ea6",
      "nameEN": "Pesticides",
      "euralCode": "20.01.19*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7227"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Scheepsnoodsignalen",
      "start": "2023-01-01",
      "id": "7f019a79-f5f3-46c5-a552-50bbc2867d6a",
      "nameEN": "Smoke & light signals/life saving equipment",
      "euralCode": "16.04.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7240"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Polypropyleen Filterelement",
      "start": "2023-01-01",
      "id": "7cafa778-e4a9-462b-85be-ebafc5436ed8",
      "nameEN": "Polypropylene filter element",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7238"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Dierlijke karkassen",
      "start": "2023-01-01",
      "id": "7bcdebf9-956c-41de-898d-6ee378e50bdd",
      "nameEN": "Animal carcasses",
      "euralCode": "02.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "507",
        "description": "G. Animal carcasses",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7258"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "BigBags Biosludge (p/stuk)",
      "start": "2023-01-01",
      "id": "7b647982-63c1-41af-bdaa-11bc78bc051a",
      "nameEN": "Bigbags biosludge (p/pce)",
      "euralCode": "20.01.08",
      "ssn": {
        "specificationRequired": false,
        "code": "502",
        "description": "B. Food waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7033"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Karton",
      "start": "2023-01-01",
      "id": "7ac04a4e-5a01-46ad-b049-574958efdb09",
      "nameEN": "Cardboard",
      "euralCode": "20.01.01",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7038"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "units",
      "nameNL": "Calibratie flessen (p/stuk)",
      "start": "2023-01-01",
      "id": "7a91d422-da60-492c-8dfa-8b1f9f26b128",
      "nameEN": "Calibration bottles (p/stuk)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7103"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Verfresten / verfafval",
      "start": "2023-01-01",
      "id": "79b9fe89-8f5f-476e-9a0d-ceaaccced0d5",
      "nameEN": "Unsorted paint cans / paint waste",
      "euralCode": "20.01.27*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7139"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Hout",
      "start": "2023-01-01",
      "id": "77cebc90-c311-4c5f-8301-9e8b8ac81e1b",
      "nameEN": "Wood",
      "euralCode": "17.02.01",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7089"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Bouw - en sloopafval",
      "start": "2023-01-01",
      "id": "77c319b6-4479-4356-a2b3-e69b6ce276bd",
      "nameEN": "Building and demolishing waste",
      "euralCode": "17.09.04",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7145"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Drijfvuil / Veegvuil",
      "start": "2023-01-01",
      "id": "771b179b-0624-4c74-8ff0-9e9b785e24d2",
      "nameEN": "Floating debris / sweep residue",
      "euralCode": "20.03.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7146"
    }, {
      "unitConversionToCubicMeters": 0.00050,
      "unitOfMeasure": "units",
      "nameNL": "TL-buizen",
      "start": "2023-01-01",
      "id": "76c6af0c-cc10-43ed-af5e-f6a1964966ae",
      "nameEN": "TL-lamps",
      "euralCode": "20.01.21*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7277"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Bigbags (leeg)",
      "start": "2023-01-01",
      "id": "769c5bd7-9426-4cca-a379-7380f880e36e",
      "nameEN": "Bigbags (empty)",
      "euralCode": "15.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7144"
    }, {
      "unitConversionToCubicMeters": 0.002,
      "unitOfMeasure": "units",
      "nameNL": "Cartridges (p/stuk)",
      "start": "2023-01-01",
      "id": "76588420-2e53-4c6b-8e28-e648f3c0b552",
      "nameEN": "Cartridges (p/piece)",
      "euralCode": "08.03.18",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7106"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Waswater olie",
      "start": "2023-01-01",
      "id": "76052953-3cb0-4b96-b8f7-e6a591ba309f",
      "nameEN": "Oily tank washings (Slops)",
      "euralCode": "16.07.08",
      "ssn": {
        "specificationRequired": false,
        "code": "103",
        "description": "Oily tank washings (Slops)",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7004"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Olierestanten van schoonmaken",
      "start": "2023-01-01",
      "id": "75715d0f-8532-4d46-98b7-4c02cbc7f476",
      "nameEN": "Scale and sludge from tank cleaning",
      "euralCode": "16.07.08",
      "ssn": {
        "specificationRequired": false,
        "code": "105",
        "description": "Scale and sludge from tank cleaning",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7006"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Frituurvet",
      "start": "2023-01-01",
      "id": "7480567e-c2e4-459c-8f8c-440994403ddc",
      "nameEN": "Cooking oil",
      "euralCode": "20.01.25",
      "ssn": {
        "specificationRequired": false,
        "code": "504",
        "description": "D. Cooking oil",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7073"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Beddengoed, kussens, textiel etc (geen matrassen)",
      "start": "2023-01-01",
      "id": "71da12d6-8f03-4bcc-ac88-74b34750beb7",
      "nameEN": "Bed sheets, quilts, pillows etc (no mattresses)",
      "euralCode": "20.01.11",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7062"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "Touwladder",
      "start": "2023-01-01",
      "id": "714c643e-d9fd-486d-81a3-98c9e87e7d7c",
      "nameEN": "Pilot-ladder",
      "euralCode": "20.03.07",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7045"
    }, {
      "unitConversionToCubicMeters": 0.5,
      "unitOfMeasure": "units",
      "nameNL": "Koelkasten",
      "start": "2023-01-01",
      "id": "6e8ae73a-b4c1-42d1-9040-7e32507df0e8",
      "nameEN": "Refrigerator",
      "euralCode": "20.01.23*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7264"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Kopieermachine (p/stuk)",
      "start": "2023-01-01",
      "id": "6c9bc559-70e9-47d0-91c0-c20bfa1f1132",
      "nameEN": "Copymachine (p/piece)",
      "euralCode": "20.01.35*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7272"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ballast water olie",
      "start": "2023-01-01",
      "id": "6c097ef7-e0ae-4c12-9e4a-658943ea0fc4",
      "nameEN": "Dirty ballast water",
      "euralCode": "16.07.01",
      "ssn": {
        "specificationRequired": false,
        "code": "104",
        "description": "Dirty ballast water",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7005"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Meubelen",
      "start": "2023-01-01",
      "id": "6b9b5f42-bfe7-4ec4-af79-1b3f7a2e9c60",
      "nameEN": "Furniture",
      "euralCode": "20.03.07",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7040"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Jerrycans (vol) met olie)  (p/kg)",
      "start": "2023-01-01",
      "id": "6a0d1e6f-1dc6-45ac-a908-b32fffb7ca79",
      "nameEN": "Jerrycans full with oil (p/kg)",
      "euralCode": "13.02.08*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7208"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "J. Droge ladingrestanten niet HME,",
      "start": "2023-01-01",
      "id": "696849d6-7067-4514-a135-c3a9fcfdd5b8",
      "nameEN": "J. Cargo residues (Non-HME)",
      "euralCode": "16.03.03",
      "ssn": {
        "specificationRequired": true,
        "code": "510",
        "description": "J. Cargo residues (Non-HME))",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7281"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Separatie materiaal/kleden",
      "start": "2023-01-01",
      "id": "66866ef5-62d1-4e05-b50f-0af429513cba",
      "nameEN": "Separation materials",
      "euralCode": "15.01.09",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7142"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "I. Electronische afval (niet vergoed)",
      "start": "2023-01-01",
      "id": "662cb279-d8a1-4d94-98ad-e4ee1fb10797",
      "nameEN": "I. E-waste (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7261"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Oliefilters",
      "start": "2023-01-01",
      "id": "65414a9f-3d8f-439d-923b-128288de1e83",
      "nameEN": "Oilfilters",
      "euralCode": "16.01.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7050"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Anti-oxydanten (Baynox)",
      "start": "2023-01-01",
      "id": "62b1efa8-d7de-419d-8be4-db51f5c2887f",
      "nameEN": "Anti-oxydants (Baynox)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7163"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "G. Dierlijke karkassen (niet vergoed)",
      "start": "2023-01-01",
      "id": "629fd076-25db-4b69-9ff2-004d9371d953",
      "nameEN": "G. Animal carcasses (Non refundable)",
      "ssn": {
        "specificationRequired": false,
        "code": "507",
        "description": "G. Animal carcasses",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7257"
    }, {
      "unitConversionToCubicMeters": 1,
      "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
      "unitOfMeasure": "m3",
      "nameNL": "Voedselrestanten",
      "start": "2023-01-01",
      "id": "6265aef6-5216-4b0d-9cf6-3801f441375f",
      "nameEN": "Foodwaste",
      "euralCode": "20.01.08",
      "ssn": {
        "specificationRequired": false,
        "code": "502",
        "description": "B. Food waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7083"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Fotografisch afval",
      "start": "2023-01-01",
      "id": "5efed70b-e3bd-4ea8-89d8-f802226ae06f",
      "nameEN": "Photographic waste",
      "euralCode": "09.01.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7110"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "m3",
      "nameNL": "Geperst Voedselrestanten",
      "start": "2023-01-01",
      "id": "5e0bfc59-86cf-453e-a5ed-4b008f31623d",
      "nameEN": "Compressed Foodwaste",
      "euralCode": "20.01.08",
      "ssn": {
        "specificationRequired": false,
        "code": "502",
        "description": "B. Food waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7031"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Electronica Afval (p/kg)",
      "start": "2023-01-01",
      "id": "5c3e49a3-e0b7-4333-93e5-1dcb60626f19",
      "nameEN": "Electronical equipment (p/kg)",
      "euralCode": "16.02.15*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7269"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vuurwerk / noodseinmiddelen",
      "start": "2023-01-01",
      "id": "5a71aba6-c7fb-4b6a-8cb0-c94b7f180c08",
      "nameEN": "Fireworks / emergency signals",
      "euralCode": "16.04.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7248"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Scrubber restanten",
      "start": "2023-01-01",
      "id": "5a4e8ab1-eb46-477b-a068-0e4dfa890ee0",
      "nameEN": "Exhaust gas-cleaning residues",
      "euralCode": "10.01.18",
      "ssn": {
        "specificationRequired": false,
        "code": "602",
        "description": "Exhaust gas-cleaning residues",
        "marpolCategory": "Annex VI"
      },
      "typeCode": "Code_7302"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "K. Droge ladingrestanten HME,",
      "start": "2023-01-01",
      "id": "59bf9e4e-d978-4e8b-9bdb-4435f1bac5ef",
      "nameEN": "K. Cargo residues (HME)",
      "euralCode": "16.03.03",
      "ssn": {
        "specificationRequired": true,
        "code": "511",
        "description": "K. Cargo residues (HME)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7299"
    }, {
      "unitConversionToCubicMeters": 0.025,
      "unitOfMeasure": "units",
      "nameNL": "Oliehoudende emballage, plastic per 20/25 ltr. (p/stuk)",
      "start": "2023-01-01",
      "id": "593d17ce-f9e1-465f-87e2-f2c200e80bad",
      "nameEN": "Empty drums ex-chemicals per 20/25 ltr. (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7128"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Afval van sewagetank reiniging",
      "start": "2023-01-01",
      "id": "549d27dd-14b4-4143-ad2f-725226fca7b6",
      "nameEN": "Waste from sewage cleaning",
      "euralCode": "20.03.06",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7154"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Afgewerkte katalysatoren die gevaarlijke overgangsmetalen 3 of gevaarlijke verbindingen bevatten (p/kg)",
      "start": "2023-01-01",
      "id": "5343f82c-42e1-49e9-b0fc-a3f918ea8ba7",
      "nameEN": "Catalysts (p/kg)",
      "euralCode": "16.08.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7100"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Magnetron (p/stuk)",
      "start": "2023-01-01",
      "id": "518122d0-77b3-4df0-92ae-cda080831af9",
      "nameEN": "Microwave (p/piece)",
      "euralCode": "20.01.35*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7273"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "J. Waswater droge lading niet HME,",
      "start": "2023-01-01",
      "id": "51330424-d12d-4ae6-b5a9-8a64ac251e94",
      "nameEN": "J. Washwater Cargo residues (Non-HME)",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "510",
        "description": "J. Cargo residues (Non-HME))",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7280"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gasmaskfilters",
      "start": "2023-01-01",
      "id": "512ed103-df61-4cec-b151-839b0a556542",
      "nameEN": "Gasmask-filter",
      "euralCode": "16.01.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7188"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Accu's",
      "start": "2023-01-01",
      "id": "4fcdb2fa-8054-4faa-9e53-4f0a74dd5e95",
      "nameEN": "Accumulators/batteries",
      "euralCode": "16.06.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7099"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Oliehoudend afval",
      "start": "2023-01-01",
      "id": "4ecc6b89-0091-47fb-966d-77ebfbd7fe17",
      "nameEN": "Oily waste",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7052"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Plastic ladinggebonden",
      "start": "2023-01-01",
      "id": "4d774483-c96a-4b31-87b7-9b7dcfb9fc59",
      "nameEN": "Plastic cargo related",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7023"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Dry cleaning afval",
      "start": "2023-01-01",
      "id": "4b2c608c-a7b7-4201-9cc7-7deccbe70c38",
      "nameEN": "Dry cleaning waste",
      "euralCode": "14.06.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7174"
    }, {
      "unitConversionToCubicMeters": 0.02,
      "unitOfMeasure": "units",
      "nameNL": "Bigbags empty ex chemie/olie  (p/m3)",
      "start": "2023-01-01",
      "id": "49178c63-246b-4ca9-a059-1b484b301e9e",
      "nameEN": "Bigbags empty ex chemie/oil (p/m3)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7162"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Roest verontreinigd verfresten (p/kg)",
      "start": "2023-01-01",
      "id": "439e7ed9-80c4-4000-bbbc-71f58666093f",
      "nameEN": "Rust contaminated with hazardous waste (p/kg)",
      "euralCode": "08.01.11*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7132"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Ladingrestanten Categorie Z",
      "start": "2023-01-01",
      "id": "43711371-87f8-43d9-8ca4-64452eb899d8",
      "nameEN": "Cargo residue Category Z",
      "euralCode": "16.07.09",
      "ssn": {
        "specificationRequired": true,
        "code": "203",
        "description": "Category Z substance",
        "marpolCategory": "Annex II"
      },
      "typeCode": "Code_7015"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Brandbluspoeder",
      "start": "2023-01-01",
      "id": "42c1b115-0f6b-4d64-ad60-020ce4db589b",
      "nameEN": "Fire Extinghuish powder",
      "euralCode": "16.05.05",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7166"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Straalafval",
      "start": "2023-01-01",
      "id": "4267792d-9335-4efb-8bf7-3f86876608b3",
      "nameEN": "Used blasting grid",
      "euralCode": "12.01.16*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7137"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Absorbentia (ongebruikt)",
      "start": "2023-01-01",
      "id": "3e0b278e-bf46-4d5a-abfc-adfb5e64a7f4",
      "nameEN": "Absorbentia non contaminated",
      "euralCode": "15.02.03",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7046"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "units",
      "nameNL": "Rookmelders",
      "start": "2023-01-01",
      "id": "3df79107-ed28-492b-a163-ad585fefa98d",
      "nameEN": "Smokedetectors",
      "euralCode": "16.02.13*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7276"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "m3",
      "nameNL": "Geperst Scheepsafval",
      "start": "2023-01-01",
      "id": "3cc4083a-f2f9-48f6-b75f-8d6bbd4703cd",
      "nameEN": "Pantry & Gally waste (compressed)",
      "euralCode": "20.03.01",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7088"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Jerrycan per 25 ltr vol brandblusschuim/vloeistof (p/kg)",
      "start": "2023-01-01",
      "id": "3b4445fe-e489-4824-8fcc-072381cbf49c",
      "nameEN": "Jerrycan per 25 ltr full fire extinquishfoam/liquid (p/kg)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7207"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Laag calorisch vloeistoffen mix",
      "start": "2023-01-01",
      "id": "3a956a68-d95e-4e43-875e-fae432dbbe70",
      "nameEN": "Liquid waste contaminated with dangerous goods (low caloric)",
      "euralCode": "16.10.01*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7153"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Rubber slangen vuil  (p/kg)",
      "start": "2023-01-01",
      "id": "3a60c172-6bb6-4d1b-9ea9-02015be8332b",
      "nameEN": "Rubber hoses unclean (p/kg)",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7133"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Lege emballage met rest",
      "start": "2023-01-01",
      "id": "3a00dcb5-ec72-49bf-8a51-c09acb15dd5f",
      "nameEN": "Empty drums/cans",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7213"
    }, {
      "unitConversionToCubicMeters": 0.2,
      "unitOfMeasure": "units",
      "nameNL": "Plastic vaten met rest  (ex. chemie) per 200 ltr. (p/stuk)",
      "start": "2023-01-01",
      "id": "39d9a137-9176-474e-b94d-8526ea22eeda",
      "nameEN": "Empty drums ex-chemicals per 200 ltr. (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7131"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Stalen vaten (vol)-sediment per 200 ltr. (ex-fire extinguish foam) (p/kg)",
      "start": "2023-01-01",
      "id": "3866c880-049b-4ddf-a0ac-67a84256197e",
      "nameEN": "Steel drums (full) 200 ltr. with sediments (p/kg)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7242"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Ozon aantastende stoffen",
      "start": "2023-01-01",
      "id": "385e6b3c-b996-438f-bf51-d42680132279",
      "nameEN": "Ozone-depleting substances and equipment containing such substances",
      "ssn": {
        "specificationRequired": false,
        "code": "601",
        "description": "Ozone-depleting substances",
        "marpolCategory": "Annex VI"
      },
      "typeCode": "Code_7300"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Roet",
      "start": "2023-01-01",
      "id": "36855641-eeb6-4fcd-9b07-88af78f9ce3a",
      "nameEN": "Soot",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7241"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Waterfilter of -korrels",
      "start": "2023-01-01",
      "id": "35b98b18-f5f2-40ce-8f01-e43db5ab5e3f",
      "nameEN": "Water Purifier or - Granules",
      "euralCode": "15.02.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7253"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Schadepartij",
      "start": "2023-01-01",
      "id": "336838dc-d681-4047-97c8-e023c65dcd71",
      "nameEN": "Damaged cargoes",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7141"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Jerrycan (vol) met chemicaliën",
      "start": "2023-01-01",
      "id": "31a95373-4d7a-499c-ad8f-ae8f68a2b38c",
      "nameEN": "Jerrycan (full) with chemicals",
      "euralCode": "20.01.30",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7202"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "m3",
      "nameNL": "Geperst Plastic (vermengd met afval)",
      "start": "2023-01-01",
      "id": "317c15c1-18af-435e-bad2-9988e696a727",
      "nameEN": "Plastic compressed not clean (contaminated with waste)",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7094"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Grofvuil",
      "start": "2023-01-01",
      "id": "310411c1-6f4a-487d-9025-0000ae3fcab3",
      "nameEN": "Sized garbage",
      "euralCode": "20.03.07",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7037"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Staaldraad met vet",
      "start": "2023-01-01",
      "id": "2d5f0cae-5da3-4a98-89f7-a3901adb73ba",
      "nameEN": "Steel wire with grease",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7136"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Luik deksel rand rubber",
      "start": "2023-01-01",
      "id": "2d1b5fa7-6e80-4c98-9035-4232b6d50795",
      "nameEN": "Hatch cover sealing tape",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7196"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Hout/Pallets",
      "start": "2023-01-01",
      "id": "2c8fc4b0-96a4-4f5f-a1e5-81d0c288e84c",
      "nameEN": "Wood/pallets",
      "euralCode": "15.01.03",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7090"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "units",
      "nameNL": "Bigbag met restanten ex chem./olie (p/m3)",
      "start": "2023-01-01",
      "id": "2c5b6a62-5149-4206-88d3-ad0079356a72",
      "nameEN": "Bigbag with rest ex chem./oil (p/m3)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7161"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Overige stoffen Annex VI",
      "start": "2023-01-01",
      "id": "2b8142de-f2a1-4d7f-9589-eedb0f016e20",
      "nameEN": "Other substances Annex VI",
      "ssn": {
        "specificationRequired": false,
        "code": "602",
        "description": "Exhaust gas-cleaning residues",
        "marpolCategory": "Annex VI"
      },
      "typeCode": "Code_7301"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "@class": "com.fasterxml.jackson.databind.node.ObjectNode",
      "unitOfMeasure": "units",
      "nameNL": "Monsterflessen > 0,5 liter (p/stuk)",
      "start": "2023-02-01",
      "id": "29a4c6c3-cd12-4016-869e-58ea5e130629",
      "nameEN": "Sample Bottles > 0,5 liter (p/stuk)",
      "euralCode": "14.06.03*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7124"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Grijswater restanten",
      "start": "2023-01-01",
      "id": "290e6653-36bb-4827-b62d-79081285ee40",
      "nameEN": "Greywater residue",
      "euralCode": "16.10.04",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7194"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Printer (p/stuk)",
      "start": "2023-01-01",
      "id": "28a419c8-11d5-421c-a93d-18dde9d55bc4",
      "nameEN": "Printer (p/piece)",
      "euralCode": "20.01.35*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7275"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Olie water (vrijkomend van schoonmaken MK)",
      "start": "2023-01-01",
      "id": "21641136-bb6c-40b3-8029-e405aa5c6423",
      "nameEN": "Oil/Water (derived form cleaning E/R)",
      "euralCode": "13.04.01",
      "ssn": {
        "specificationRequired": false,
        "code": "101",
        "description": "Oily Bilge water",
        "marpolCategory": "Annex I"
      },
      "typeCode": "Code_7008"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Kunststof uit bouw- en sloopafval",
      "start": "2023-01-01",
      "id": "20850037-2628-41c9-b511-224e07ff91e8",
      "nameEN": "Plastic ex demolishing waste",
      "euralCode": "17.02.03",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7028"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Biologisch afbreekbaar kantineafval",
      "start": "2023-01-01",
      "id": "20716b21-837e-4021-8986-8d48899d839e",
      "nameEN": "Biodegradable foodwaste",
      "euralCode": "20.01.08",
      "ssn": {
        "specificationRequired": false,
        "code": "502",
        "description": "B. Food waste",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7032"
    }, {
      "unitConversionToCubicMeters": 0.01,
      "unitOfMeasure": "units",
      "nameNL": "Televisie",
      "start": "2023-01-01",
      "id": "1df9f416-2309-4041-a4a8-95b4817271e4",
      "nameEN": "Television (per piece)",
      "euralCode": "20.01.36",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7279"
    }, {
      "unitConversionToCubicMeters": 0.06,
      "unitOfMeasure": "units",
      "nameNL": "Oliehoudende emballage, staal per 60 ltr. (p/stuk)",
      "start": "2023-01-01",
      "id": "1cb1536a-b9f1-437f-99d0-c7db7c15fd2c",
      "nameEN": "Empty drums ex-oil per 60 ltr. (p/piece)",
      "euralCode": "15.01.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7130"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "units",
      "nameNL": "Gas testing tubes (Dräger buisjes) (p/kg)",
      "start": "2023-01-01",
      "id": "18f04baa-9d44-47c0-9f24-51db80a8373a",
      "nameEN": "Gas testing tubes (Dräger tubes) (p/kg)",
      "euralCode": "16.05.07*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7185"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vet van sewage behandeling (cruiseschip)",
      "start": "2023-01-01",
      "id": "17fc7625-ff80-463a-a534-863950e871d6",
      "nameEN": "Grease from sewage/black water treatment (cruiseship)",
      "euralCode": "20.01.26*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7347"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "A. Plastic (niet vergoed)",
      "start": "2023-01-01",
      "id": "17e96a80-823b-4364-a20e-9b786226a179",
      "nameEN": "A. Plastics (Non refundable)",
      "euralCode": "15.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7022"
    }, {
      "unitConversionToCubicMeters": 0.07,
      "unitOfMeasure": "units",
      "nameNL": "Vaatwasser (p/stuk)",
      "start": "2023-01-01",
      "id": "175e0946-8f41-4359-af8c-1c61279bd68e",
      "nameEN": "Dishwasher (p/piece)",
      "euralCode": "20.01.36",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7265"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Onstmettingsmiddelen",
      "start": "2023-01-01",
      "id": "16905cc1-8620-4e6b-aab5-bf88b2984ca9",
      "nameEN": "Fumigation tablets",
      "euralCode": "20.01.19*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7180"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Biologisch gemakkelijk afbreekbare hydraulische olie",
      "start": "2023-01-01",
      "id": "15e7d121-d6c7-492d-aa9d-ace0155e2c78",
      "nameEN": "Readily biodegradable hydraulic oils",
      "euralCode": "13.01.12*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7164"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Oliesedimenten",
      "start": "2023-01-01",
      "id": "156ec192-c01b-432e-b489-2527246cea00",
      "nameEN": "Oily sediment",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7222"
    }, {
      "unitConversionToCubicMeters": 0.00010,
      "unitOfMeasure": "units",
      "nameNL": "Bulblampen",
      "start": "2023-01-01",
      "id": "1521d76d-a39c-420f-a0c5-9562db3fbb84",
      "nameEN": "Bulblamp",
      "euralCode": "20.01.21*",
      "ssn": {
        "specificationRequired": false,
        "code": "509",
        "description": "I. E-waste (electronic waste)",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7268"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Vetafval",
      "start": "2023-01-01",
      "id": "112d55e1-9669-4d3b-9c96-af45b2f73862",
      "nameEN": "Grease",
      "euralCode": "20.01.26*",
      "ssn": {
        "specificationRequired": false,
        "code": "504",
        "description": "D. Cooking oil",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7074"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Schroot/Ferrometalen",
      "start": "2023-01-01",
      "id": "10bad1b2-fc2e-4ce1-bf06-513b905c860b",
      "nameEN": "Scrap",
      "euralCode": "20.01.40",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7092"
    }, {
      "unitConversionToCubicMeters": 0.2,
      "unitOfMeasure": "units",
      "nameNL": "Freon Gasflessen (p/kg)",
      "start": "2023-01-01",
      "id": "10679ec2-323a-4625-bba6-8b991451a548",
      "nameEN": "Freon Gasbottles  (p/kg)",
      "euralCode": "16.05.04*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7181"
    }, {
      "unitConversionToCubicMeters": 3,
      "unitOfMeasure": "units",
      "nameNL": "Radar / Satellietbol",
      "start": "2023-01-01",
      "id": "103abb6e-37b6-4a72-b7fd-791fb2778517",
      "nameEN": "Radar-equipment  / Satellite",
      "euralCode": "16.02.14",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7068"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Scheepsafval",
      "start": "2023-01-01",
      "id": "0e023520-ddc6-47ef-97bd-10245feb5028",
      "nameEN": "Pantry & Galleywaste",
      "euralCode": "20.03.01",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7044"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Niet brandbaar afval",
      "start": "2023-01-01",
      "id": "0da42f35-95d2-4e11-845b-c9ed6c8bbe97",
      "nameEN": "Incombustible waste",
      "euralCode": "20.03.99",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7091"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Batterijen",
      "start": "2023-01-01",
      "id": "0987e487-7c91-4909-b062-9f3a0a6c7b22",
      "nameEN": "Batteries",
      "euralCode": "20.01.33*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7101"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gemengde metalen",
      "start": "2023-01-01",
      "id": "09573f65-106d-408e-a529-e22ab6649283",
      "nameEN": "Scrap metal",
      "euralCode": "17.04.07",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7147"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Brandblusfoam AFFF (liquid)  (p/kg)",
      "start": "2023-01-01",
      "id": "05a54601-435c-4246-99ce-ba3b24958290",
      "nameEN": "Fire extinguishfoam AFFF (liquid) (p/kg)",
      "euralCode": "16.05.09",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7165"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Ladingslangen",
      "start": "2023-01-01",
      "id": "038589b3-9a26-4800-9bd8-6dac7d4611ee",
      "nameEN": "Cargo hoses unclean",
      "euralCode": "15.02.02*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7211"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Lithium batterijen",
      "start": "2023-01-01",
      "id": "0316d6cb-d4a4-4086-86e7-57763adb7221",
      "nameEN": "Lithium batteries",
      "euralCode": "20.01.33*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7215"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Plastic (schoon) niet lading gerelateerd",
      "start": "2023-01-01",
      "id": "02968216-57fe-4a56-b2f2-4643f8d45be8",
      "nameEN": "Plastic clean - not cargo related",
      "euralCode": "20.01.39",
      "ssn": {
        "specificationRequired": false,
        "code": "501",
        "description": "A. Plastics",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7026"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "units",
      "nameNL": "E-pirb",
      "start": "2023-01-01",
      "id": "01de14ae-964f-40d5-aac3-df07f642ebb4",
      "nameEN": "E-pirb",
      "euralCode": "16.02.14",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7109"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Fotolab afval - Bleek- en Bleekfixeervloeistof",
      "start": "2023-01-01",
      "id": "0154a918-12fd-4433-8fe9-67b169bdab09",
      "nameEN": "Photolab Bleach Solutions and Bleach Fixer Solutions",
      "euralCode": "09.01.05*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7111"
    }, {
      "unitConversionToCubicMeters": 0.001,
      "unitOfMeasure": "kg",
      "nameNL": "Gebruikte vetten",
      "start": "2023-01-01",
      "id": "00afcd7e-2759-410b-81d8-3c1750090b0a",
      "nameEN": "Used Grease",
      "euralCode": "19.08.10*",
      "ssn": {
        "specificationRequired": false,
        "code": "506",
        "description": "F. Operational wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7190"
    }, {
      "unitConversionToCubicMeters": 1,
      "unitOfMeasure": "m3",
      "nameNL": "Glas",
      "start": "2023-01-01",
      "id": "003e7235-b214-486b-98d2-2ea47690480d",
      "nameEN": "Glass",
      "euralCode": "20.01.02",
      "ssn": {
        "specificationRequired": false,
        "code": "503",
        "description": "C. Domestic wastes",
        "marpolCategory": "Annex V"
      },
      "typeCode": "Code_7035"
    }].filter(m => true);
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.cargo.FindSizeTypes' = (query) => {
    return of([
      {
        code: "SIZE1",
        name: "Big size"
      },
      {
        code: "SIZE2",
        name: "Small size"
      }
    ]);
  };

  'com.portbase.bezoekschip.common.api.cargo.FindGoodsClassifications' = (query) => {
    return <GoodsClassification[]>[
      {code: '0100', description: 'Live animals'},
      {code: '844520', description: 'Textile spinning machines'},
      {code: '41041951', description: 'Other, Of bovine (including buffalo) animals'},
    ];
  };

  'com.portbase.bezoekschip.common.api.cargo.FindMyWarehouseLicenses' = (query) => {
    return of(myWarehouseLicenses);
  };

  'com.portbase.bezoekschip.common.api.transit.FindGuarantees' = (query: FindGuarantees) => {
    return query.ownerShortName === 'test' ? of(adminGuarantees) : of(myTransitGuarantees);
  };

  'com.portbase.bezoekschip.common.api.transit.FindTransitTemplates' = (query: FindTransitTemplates) => {
    return of(myTransitTemplates.filter(t => (!t.ownerShortName || AppContext.isAdmin()) ||
      [query.ownerShortName, AppContext.userProfile.organisation?.shortName].includes(t.ownerShortName)));
  };

  'com.portbase.bezoekschip.common.api.transit.FindTransitParties' = (query: FindTransitParties) => {
    return of(myTransitParties.filter(t => (!t.ownerShortName || AppContext.isAdmin()) ||
      [query.ownerShortName, AppContext.userProfile.organisation?.shortName].includes(t.ownerShortName)));
  };

  'com.portbase.bezoekschip.common.api.cargo.FindMyParties' = (query) => {
    return of(myParties);
  };

  'com.portbase.bezoekschip.common.api.visit.GetPortFacilities' = (query) => {
    let result: PortFacility[] = [];
    switch (query.portUnCode) {
      case 'ESBCN' :
        result = [
          {
            'code': 'ESBCN-0001',
            'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.'
          },
          {
            'code': 'ESBCN-0003',
            'name': 'Barcelona, Spain  CENTRO DE CONTROL'
          },
          {
            'code': 'ESBCN-0005',
            'name': 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)'
          },
          {
            'code': 'ESBCN-0007',
            'name': 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.'
          },
          {
            'code': 'ESBCN-0009',
            'name': 'Barcelona, Spain  DECAL ESPAÑA S.A.'
          },

        ];
        break;
      case 'ITTUN' :
        result = [
          {
            'code': 'ITTUN-9999',
            'name': 'UNKNOWN'
          },
        ];
        break;
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.EnrichPreviousPorts' = (query: EnrichPreviousPorts) => {
    return query.previousPorts.map(previousPort => {
      let port = previousPort.port;
      switch (previousPort.port.locationUnCode) {
        case 'ESBCN':
          port.countryUnCode = 'ES';
          port.name = 'Barcelona';
          port.euPort = true;
          break;
        case 'ITTUN':
          port.countryUnCode = 'IT';
          port.name = 'Tunisi';
          port.euPort = true;
          break;
        default:
          throw 'Your upload contains an unrecognized port: ' + previousPort.port.locationUnCode;
      }
      for (let portFacility of previousPort.portFacilityVisits.map(v => v.portFacility)) {
        switch (portFacility.code) {
          case 'ESBCN-0001':
            portFacility.name = 'Barcelona, Spain  AUTOTERMINAL, S.A.';
            break;
          case 'ESBCN-0003':
            portFacility.name = 'Barcelona, Spain  CENTRO DE CONTROL';
            break;
          case 'ESBCN-0005':
            portFacility.name = 'Barcelona, Spain  COMPAÑÍA LOGISTICA DE HIDROCARBUROS (CLH)';
            break;
          case 'ESBCN-0007':
            portFacility.name = 'Barcelona, Spain  CORPORACIÓN DE PRÁCTICOS DEL PUERTO DE BARCELONA, S.L.P.';
            break;
          case 'ESBCN-0009':
            portFacility.name = 'Barcelona, Spain  DECAL ESPAÑA S.A.';
            break;
          default:
            portFacility.code = port.locationUnCode + '-9999';
            portFacility.name = 'UNKNOWN';
            break;
        }
      }
      return previousPort;
    });
  };

  'com.portbase.bezoekschip.common.api.visit.FindCarriers' = (query) => {
    let result: Carrier[] = [];
    if (query.term && query.term.toLowerCase().startsWith('c')) {
      result = [
        {
          name: 'Carrier 1',
          customsId: 'carrier1Id',
          scacCode: 'scac1',
          smdgCode: 'smdg1',
          cargoDeclarantShortName: "SO1"
        },
        {
          name: 'Carrier 2',
          customsId: 'carrier2Id',
          scacCode: 'scac2',
          smdgCode: 'smdg2',
          cargoDeclarantShortName: "SO1"
        },
        {
          name: 'Carrier 3',
          customsId: 'carrier3Id',
          scacCode: 'scac3',
          smdgCode: '',
          cargoDeclarantShortName: "SO1"
        },
        {
          name: 'Carrier 4',
          customsId: 'carrier4Id',
          scacCode: 'scac4',
          cargoDeclarantShortName: 'SO1'
        },
        {
          name: 'Carrier 5',
          cargoDeclarantShortName: 'SO1'
        }
      ];
    }
    return of(result).pipe(delay(200));
  };
  'com.portbase.bezoekschip.common.api.visit.FindPilotExemptions' = (query) => {
    let result: PilotExemption[] = [];
    if (query.term && query.term.toLowerCase().startsWith('p')) {
      result = [
        {holderName: 'Exemption holder 1', number: '12345'},
        {holderName: 'Exemption holder 2', number: '54321'}
      ];
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.FindBerthsAndBuoys' = (query) => {
    let result: Berth[] = [];

    result = [
      <any>{
        'name': 'HOLLAND AMERIKAKADE',
        'code': 'R1243',
        'terminalCode': '52090469',
        'berthGroupCode': 'HOLLAND AMERIKAKADE',
        'buoy': false,
        'partyToNotify': null,
        'portCode': 'NLRTM',
        'bollards': null
      },
      {
        'name': 'DORDR PWA KADE',
        'code': 'D4358',
        'terminalCode': '51433242',
        'berthGroupCode': 'DORDR PWA KADE',
        'buoy': false,
        'partyToNotify': null,
        'bollards': {
          'start': 21,
          'end': 25
        }
      },
      {
        "name": "DORDR WILHH BOEISPAN 1-2",
        "code": "O9748",
        "terminalCode": "47577196",
        "berthGroupCode": "DORDR WILHH BOEISPAN 3-4",
        "buoy": true,
        "partyToNotify": null,
        'bollards': {
          'start': 0,
          'end': 10
        }
      }, {
        "name": "DORDR WILHH BOEISPAN 3-4",
        "code": "O9749",
        "terminalCode": "47577196",
        "berthGroupCode": "DORDR WILHH BOEISPAN 3-4",
        "buoy": true,
        "partyToNotify": null,
        'bollards': {
          'start': 11,
          'end': 20
        }
      }, {
        "name": "DORDR WILHH BOEISPAN 5-6",
        "code": "O9750",
        "terminalCode": "47577196",
        "berthGroupCode": "DORDR WILHH BOEISPAN 3-4",
        "buoy": true,
        "partyToNotify": null,
        'bollards': {
          'start': 21,
          'end': 30
        }
      },
      {
        "name": "SEINEH HBR OPENBARE KADE",
        "code": "R5105",
        "terminalCode": "6123",
        "berthGroupCode": "SEINH OPENBARE LIGPL",
        "buoy": false,
        "partyToNotify": null,
        "bollards": null
      },
      {
        "name": "AMALIAH APM FABRIEK",
        "code": "R8412",
        "terminalCode": "4254",
        "berthGroupCode": "APMT",
        "buoy": false,
        "partyToNotify": null,
        'bollards': {
          'start': 10,
          'end': 20
        }
      }
    ];

    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.FindTerminals' = (query) => {
    let result: any[] = [
      <any>{
        'terminalName': 'HOLLAND AMERIKAKADE',
        'terminalCode': '52090469',
        'deprecatedSwLocationCode': true
      }, {
        "terminalName": "AMALIAH APM DILDO FABRIEK",
        "terminalCode": "4254",
        'deprecatedSwLocationCode': false
      }, {
        "terminalName": "BRITTANNIEH BROEKMAN AWT",
        "organisationName": "Broekman Distriport B.V.",
        "organisationShortName": "BROEKMANDISTRI",
        "terminalCode": "1866",
        "portCode": "NLRTM"
      }
    ];

    return of(result.filter(t => !query.organisationShortName || !t.organisationShortName || (t.organisationShortName && t.organisationShortName.includes(query.organisationShortName))));
  }

  'com.portbase.bezoekschip.common.api.visit.GetTerminalsOfOrganisation' = (query) => {
    let result: any[] = [
      {
        "terminalName": "AMAZONEH ECT DDE",
        "terminalCode": "4810",
        'organisationShortName': 'ECTDELTA',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "AMAZONEH ECT DDN",
        "terminalCode": "4808",
        'organisationShortName': 'ECTDELTA',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "ARIANEH RWG BF 1",
        "terminalCode": "4285",
        'organisationShortName': 'RWG',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "AMALIAH RWG DS 1",
        "terminalCode": "4284",
        'organisationShortName': 'RWG',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "Non existence",
        "terminalCode": "8124",
        'organisationShortName': 'SO1',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "Euromax T01",
        "terminalCode": "8134",
        'organisationShortName': 'EUROMAX',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "APMII T50",
        "terminalCode": "8464",
        'organisationShortName': 'APMII',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "RST DS01",
        "terminalCode": "9898",
        'organisationShortName': 'RST',
        'deprecatedSwLocationCode': false
      },
      {
        "terminalName": "RST DS02",
        "terminalCode": "9899",
        'organisationShortName': 'RST',
        'deprecatedSwLocationCode': false
      }
    ];

    return of(result.filter(t => !query.organisationShortName || (t.organisationShortName && t.organisationShortName.includes(query.organisationShortName))));
  }

  'com.portbase.bezoekschip.common.api.visit.GetBollardsOfTerminal' = (query: GetBollardsOfTerminal): Observable<Bollards> => {
    return of(<Bollards>{
      start: 1,
      end: 30
    });
  }

  'com.portbase.bezoekschip.common.api.visit.FindPorts' = (query: FindPorts) => {
    let result: Port[] = [
      {
        'name': 'Antwerpen',
        'locationUnCode': 'BEANR',
        'countryUnCode': 'BE',
        'euPort': true
      },
      {
        'name': 'El Iskandariya (= Alexandria)',
        'locationUnCode': 'EGALY',
        'countryUnCode': 'EG',
        'euPort': false
      },
      {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      }
    ];

    return result;
  };

  'com.portbase.bezoekschip.common.api.visit.FindPortsOrWayPoints' = (query: FindPortsOrWayPoints) => {
    let result: Port[] = [
      {
        'name': 'Antwerpen',
        'locationUnCode': 'BEANR',
        'countryUnCode': 'BE',
        'euPort': true
      },
      {
        'name': 'El Iskandariya (= Alexandria)',
        'locationUnCode': 'EGALY',
        'countryUnCode': 'EG',
        'euPort': false
      },
      {
        'name': 'Rotterdam',
        'locationUnCode': 'NLRTM',
        'countryUnCode': 'NL',
        'euPort': true
      }
    ];

    return result;
  };

  'com.portbase.bezoekschip.common.api.visit.FindLocations' = (query: FindLocations) => {
    let result: Location[] = [];
    if (query.term) {
      result = [
        {
          'name': 'Antwerpen',
          'locationUnCode': 'BEANR',
          'countryUnCode': 'BE',
          'euLocation': true
        },
        {
          'name': 'El Iskandariya (= Alexandria)',
          'locationUnCode': 'EGALY',
          'countryUnCode': 'EG',
          'euLocation': false
        }
      ];
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.FindPortFacilities' = (query: any) => {
    let result: PortFacility[] = [];
    result = [
      <any>{
        'code': 'ESBCN-0001',
        'name': 'Barcelona, Spain  AUTOTERMINAL, S.A.',
        'portCode': 'ESBCN'
      },
      <any>{
        'code': 'ESBCN-0002',
        'name': 'Barcelona, Spain  Blah, S.A.',
        'portCode': 'ESBCN'
      }
    ];
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.GetPort' = (query: GetPort) => {
    switch (query.portUnCode.toUpperCase()) {
      case 'BEANR':
        return of({
          'name': 'Antwerpen',
          'locationUnCode': 'BEANR',
          'countryUnCode': 'BE',
          'euPort': true
        });
      case 'EGALY':
        return of({
          'name': 'El Iskandariya (= Alexandria)',
          'locationUnCode': 'EGALY',
          'countryUnCode': 'EG',
          'euPort': false
        });
    }
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.GetPortOrWayPoint' = (query: GetPortOrWayPoint) => {
    switch (query.portUnCode.toUpperCase()) {
      case 'BEANR':
        return of({
          'name': 'Antwerpen',
          'locationUnCode': 'BEANR',
          'countryUnCode': 'BE',
          'euPort': true
        });
      case 'EGALY':
        return of({
          'name': 'El Iskandariya (= Alexandria)',
          'locationUnCode': 'EGALY',
          'countryUnCode': 'EG',
          'euPort': false
        });
    }
    return of(null);
  };

  'com.portbase.bezoekschip.common.api.visit.FindVessels' = (query) => {
    let result: Vessel[] = [];
    if (query.term && query.term.toLowerCase().startsWith('d')) {
      result = [
        {
          'imoCode': '8758457',
          'name': 'DAVE BLACKBURN',
          'radioCallSign': 'WB6043',
          'motUnCode': '1729',
          'motName': 'Passenger ship',
          'summerDeadWeight': 0,
          'maxWidth': 15.84,
          'flagCountryUnCode': 'US',
          'netTonnage': 1338,
          'grossTonnage': 1338,
          'registrationPlaceUnloCode': 'USMSY',
          'registrationPlaceName': 'New Orleans',
          'registrationDate': '1944-02-01',
          'mmsiNumber': null,
          'emailAddress': 'ship@portbase.com',
          'fullLength': 110,
          'flagCode': 'USA',
          'statCode5': 'A1'
        },
        {
          'flagCode': 'KOR',
          'flagCountryUnCode': 'KP',
          'fullLength': 34.85,
          'grossTonnage': 294,
          'emailAddress': 'ship@portbase.com',
          'imoCode': '8130784',
          'maxWidth': 9.61,
          'mmsiNumber': '441582000',
          'motUnCode': '1602',
          'motName': 'Fishing boat',
          'name': 'DEOK BONG',
          'netTonnage': 107,
          'radioCallSign': 'DSQH8',
          'registrationDate': '1982-07-20',
          'registrationPlaceName': 'Busan (ex Pusan)',
          'registrationPlaceUnloCode': 'KRPUS',
          'summerDeadWeight': 116,
          'statCode5': 'B3'
        }
      ];
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.authorisation.GetShipOperatorsForCurrentUser' = (query) => {
    let result: BezoekschipOrganisation[] = [];
    switch (query.portUnCode) {
      case 'NLRTM' :
        result = <BezoekschipOrganisation[]>[
          {
            fullName: 'Len: de vuilnisman van Rotterdam',
            portAuthorityId: '',
            shortName: 'LEN_DA_MEN',
            emailAddress: 'l.paalvast@portbase.com',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: 'john smith',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          },
          {
            fullName: 'Ship Operator 1',
            portAuthorityId: '',
            shortName: 'SO1',
            emailAddress: 'tester@SO1.nl',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: '',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          },
          {
            fullName: 'Back Office 1',
            portAuthorityId: '',
            shortName: 'BO1',
            emailAddress: 'tester@BO1.nl',
            address: 'Back Office 1',
            city: 'BackOffice dorp',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '4200NG',
            phoneNumber: '',
            faxNumber: '',
            contact: '',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          }
        ];
        break;
      case 'NLAMS' :
        result = <BezoekschipOrganisation[]>[
          {
            fullName: 'David Onreindier',
            portAuthorityId: '',
            shortName: 'SO1',
            emailAddress: 'tester@SO1.nl',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: '',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          }
        ];
        break;
      case 'NLVLI' :
        result = <BezoekschipOrganisation[]>[
          {
            fullName: 'David Onrein',
            portAuthorityId: '',
            shortName: 'DIKKIE',
            emailAddress: 'david.hondenhond@portbase.com',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: 'pak m bij zijn staart',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          },
          {
            fullName: 'Ship Operator 1',
            portAuthorityId: '',
            shortName: 'SO1',
            emailAddress: 'tester@SO1.nl',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: '',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          }
        ];
        break;
      case 'NLTNZ' :
        result = <BezoekschipOrganisation[]>[
          {
            fullName: 'David Onrein',
            portAuthorityId: '',
            shortName: 'DIKKIE',
            emailAddress: 'david.hondenhond@portbase.com',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: 'pak m bij zijn staart',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          },
          {
            fullName: 'Ship Operator 1',
            portAuthorityId: '',
            shortName: 'SO1',
            emailAddress: 'tester@SO1.nl',
            address: 'Ship Operatorlaan 1',
            city: 'Ship Operatorstad',
            countryUnCode: 'NL',
            countryName: 'Nederland',
            zipCode: '8080GO',
            phoneNumber: '',
            faxNumber: '',
            contact: '',
            customsEORINumber: '',
            ean: 'ean1',
            chamberOfCommerceNumber: ''
          }
        ];
        break;
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.authorisation.FindCargoDeclarants' = (query) => {
    let result: BezoekschipOrganisation[] = [];
    if (query.term && query.term.toLowerCase().startsWith('s')) {
      result = <BezoekschipOrganisation[]>[
        {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        },
        {
          'fullName': 'Ship Operator 2',
          'portAuthorityId': 'SO2_R',
          'shortName': 'SO2',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 2',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        },
      ]
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.authorisation.FindOrganisations' = (query) => {
    return merge(this['com.portbase.bezoekschip.common.api.authorisation.FindShipOperators'](query), of(<BezoekschipOrganisation[]>[
      {
        fullName: "Cargo agent 1",
        portAuthorityId: "CHA1",
        shortName: "CHA1",
        emailAddress: "longjohn.silver@portbase.com",
        address: "Some Street 12",
        city: "Rotterdam",
        countryUnCode: "NL",
        countryName: "Nederland",
        zipCode: "2023AA",
        phoneNumber: "-",
        faxNumber: undefined,
        contact: "contact me now",
        customsEORINumber: "NL10354684",
        chamberOfCommerceNumber: "kvk12345",
        ean: "ean"
      }
    ]));
  }

  'com.portbase.bezoekschip.common.api.authorisation.FindShipOperators' = (query) => {
    let result: BezoekschipOrganisation[] = [];
    if (query.term && query.term.toLowerCase().startsWith('s')) {
      result = <BezoekschipOrganisation[]>[
        {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        },
        {
          'fullName': 'Ship Operator 2',
          'portAuthorityId': 'SO2_R',
          'shortName': 'SO2',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 2',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        }, {
          'fullName': 'Ship Operator 1 - Terminal planning',
          'portAuthorityId': 'STPO1_R',
          'shortName': 'STPO1',
          'emailAddress': 'r.esteve.cuevas@portbase.com',
          'address': 'Terminalplannerstraat 1',
          'city': 'Terminaldam',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111TP',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'REC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        }, {
          'fullName': 'ECT Delta Terminal B.V.',
          'portAuthorityId': 'ECTDELTA_R',
          'shortName': 'ECTDELTA',
          'emailAddress': 'r.esteve.cuevas@portbase.com',
          'address': 'Terminalplannerstraat 1',
          'city': 'Terminaldam',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111TP',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'REC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        }, {
          'fullName': 'Rotterdam WG',
          'portAuthorityId': 'RWG_R',
          'shortName': 'RWG',
          'emailAddress': 'r.esteve.cuevas@portbase.com',
          'address': 'Terminalplannerstraat 10',
          'city': 'Terminaldam',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '2222TP',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'REC',
          'customsEORINumber': 'NL987654321',
          'ean': null,
          'chamberOfCommerceNumber': ''
        }
      ]
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.authorisation.FindFinancialDeclarants' = (query) => {
    let result: BezoekschipOrganisation[] = [];
    if (query.term && query.term.toLowerCase().startsWith('s')) {
      result = <BezoekschipOrganisation[]>[
        {
          'fullName': 'Ship Operator 1',
          'portAuthorityId': 'SO1_R',
          'shortName': 'SO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 1',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        },
        {
          'fullName': 'Financial declarant 1',
          'portAuthorityId': 'FO1_R',
          'shortName': 'FO1',
          'emailAddress': 'd.reijnders@portbase.com',
          'address': 'Ship Operatorlaan 2',
          'city': 'Ship Operatorstad',
          'countryUnCode': 'NL',
          'countryName': 'Netherlands',
          'zipCode': '1111SO',
          'phoneNumber': '0102522288',
          'faxNumber': null,
          'contact': 'TC',
          'customsEORINumber': 'NL123456789',
          'ean': null,
          'chamberOfCommerceNumber': ''
        },
      ]
    }
    return of(result);
  };

  'com.portbase.bezoekschip.common.api.visit.GetTravelTimeToFirstBerth' = (query: GetTravelTimeToFirstBerth) => {
    return of(45);
  };

  'com.portbase.bezoekschip.common.api.visit.GetVesselServices' = (query: GetVesselServices) => {
    return of(<VesselService[]>[{
      code: 'FEED',
      name: 'Feed Me ECT1',
      stevedoreOrganisationShortName: 'ECTDELTA',
      carrierOrganisationsShortNames: ['AGENT16', 'SO1'],
    }, {
      code: 'FEED ECT',
      name: 'Feed Me ECT2',
      stevedoreOrganisationShortName: 'ECTDELTA',
      carrierOrganisationsShortNames: ['AGENT16', 'SO1'],
    }, {
      code: 'FEED RWG',
      name: 'Feed Me',
      stevedoreOrganisationShortName: 'RWG',
      carrierOrganisationsShortNames: ['AGENT16'],
    }].filter(v => v.stevedoreOrganisationShortName === query.stevedoreOrganisationShortName)
      .filter(v => v.carrierOrganisationsShortNames.includes(AppContext.userProfile.organisation?.shortName)));
  };

  'com.portbase.bezoekschip.common.api.visit.FindVesselServices' = (query: FindVesselServices) => {
    if (query.term && query.term.toLowerCase().startsWith('f')) {
      return of([{
        id: '85bb4bce-cf11-4957-9478-d63553e5d223',
        code: 'FEED',
        name: 'Feed Me',
        stevedoreOrganisationShortName: 'ECTDELTA',
        carrierOrganisationsShortNames: ['CMACGMRHN', 'MSCRTM'],
      }]);
    } else {
      return of([]);
    }
  };

  'com.portbase.bezoekschip.common.api.visit.GetTerminals' = (query: GetTerminals) => {
    return query.terminalCodes.map(c =>
      <Terminal>{
        terminalCode: c,
        terminalName: "name" + c
      })
  };

  'com.portbase.bezoekschip.common.api.messaging.GetProcessId' = (query: { entityId: string }) => {
    return of("process-for-" + query.entityId).pipe(delay(500));
  }
}
