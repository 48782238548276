<div class="d-block mb-3">
  <ng-container *ngIf="tooltip else labelRef">
    <ng-container [ngTemplateOutlet]="labelRef"></ng-container>
    <app-tooltip [placement]="'top'">
      <i class="ms-1 fa-light fa-circle-info info-icon text-black"></i>
      <div class="tooltipContent">
        <div class="tooltip-card">
          <div class="tooltip-card-body">
            {{tooltip}}
          </div>
        </div>
      </div>
    </app-tooltip>
  </ng-container>
  <ng-template #labelRef>
    <span class="fw-regular" [ngClass]="required && editMode ? 'required' : ''">{{label}}</span>
    <span *ngIf="!required && editMode" class="form-field-optional text-secondary ms-1">(optional)</span>
  </ng-template>
</div>
<ng-container *ngIf="party || editMode else noParty">
  <div *ngIf="editMode" class="row mb-3">
    <div class="col-6">
      <app-search [required]="required" [(ngModel)]="addressBook" [searchFunction]="findParties" [minCharacters]="0"
                  [resultFormatter]="utils.addressBookFormatter" [inputFormatter]="utils.addressBookFormatter"
                  (ngModelChange)="onPartyChange($event)" placement="Select a party from your address book"
                  [newValueFromInputFunction]="newParty">
      </app-search>
    </div>
  </div>
  <div *ngIf="party" class="card form-group">
    <div class="card-body p-4">
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Name" [includeMargin]="false" [(value)]="party.name" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.name" required/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Type of person" [includeMargin]="false" [(value)]="party.typeOfPerson" [editMode]="editMode && partyEditMode"
                          [formatter]="portVisitUtils.enumFormatter">
            <app-select [(ngModel)]="party.typeOfPerson" [options]="utils.typeOfPartyPersons"
                        [formatter]="portVisitUtils.enumFormatter" required></app-select>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-country-field [(countryCode)]="party.address.country" [editMode]="editMode && partyEditMode"
                             [includeMargin]="false" (countryCodeChange)="onCountryChange($event)"
                             [required]="true"></app-country-field>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Street" [includeMargin]="false" [(value)]="party.address.street" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.address.street"
                   [required]="streetRequired"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Number" [includeMargin]="false" [(value)]="party.address.number" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.address.number"
                   [required]="streetRequired"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Post office box" [includeMargin]="false" [(value)]="party.address.poBox" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.address.poBox"
                   [required]="poBoxRequired"/>
          </app-form-field>
        </div>
      </div>
      <div class="row gy-3 mb-3">
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="City" [includeMargin]="false" [(value)]="party.address.city" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.address.city" required/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Postal code" [includeMargin]="false" [(value)]="party.address.postCode" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="party.address.postCode"
                   [required]="['POSTAL_CODE', 'POSTAL_CODE_AND_STREET'].includes(countryValidation?.validationCode)"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Telephone" [includeMargin]="false" [(value)]="telephone.identifier" [editMode]="editMode && partyEditMode"
                          [tooltipTemplateRef]="telephoneTooltip">
            <input class="input-group rounded form-control" [(ngModel)]="telephone.identifier" [required]="communicationRequired && !email.identifier"/>
          </app-form-field>
        </div>
      </div>
      <div class="row gy-3">
        <div class="col-12 col-lg-6 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Email address" [includeMargin]="false" [(value)]="email.identifier" [editMode]="editMode && partyEditMode">
            <input class="input-group rounded form-control" [(ngModel)]="email.identifier" [required]="communicationRequired && !telephone.identifier"/>
          </app-form-field>
        </div>
        <div class="col-12 col-lg-6 d-flex flex-column align-items-stretch justify-content-center">
          <app-form-field label="Identification number" [includeMargin]="false" [(value)]="party.identificationNumber"
                          [editMode]="editMode && partyEditMode" tooltip="Fill with EORI number of the party">
            <input class="input-group rounded form-control" [(ngModel)]="party.identificationNumber"/>
          </app-form-field>
        </div>
      </div>
      <div *ngIf="editMode && partyEditMode && addressBook" class="row mt-3">
        <div class="col d-flex align-items-center">
          <div class="form-check form-check-inline m-0 p-0">
            <input [(ngModel)]="addressBook['saveInAddressBook']" class="always-enabled form-check-input m-0" type="checkbox"
                   [id]="'save'+label+'ToAddressBook'">
            <label [for]="'save'+label+'ToAddressBook'">Save {{label.toLowerCase()}} to address book</label>
          </div>
        </div>
        <div class="col-auto ms-auto">
          <button type="button" class="btn btn-outline-secondary" aria-label="Cancel" (click)="cancel()">Cancel</button>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-primary" (click)="save()">Save</button>
        </div>
      </div>
      <div class="position-absolute end-0 top-0 me-2 mt-2">
        <button *ngIf="deletionAllowed" type="button" class="btn btn-tertiary p-1" (click)="deleteParty()">
          <i class="text-danger fa fa-fw fa-light fa-trash-can"></i>
        </button>
        <button *ngIf="editMode && !partyEditMode" type="button" class="btn btn-tertiary p-1"
                (click)="toggleEditMode()">
          <i class="fa fa-fw fa-light fa-pencil"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noParty>
  <span class="d-block mb-3">N/A</span>
</ng-template>
<ng-template #telephoneTooltip>
  <div class="text-start">
    <h6>Telephone Number</h6>
    <p>The number should have format : +31886252500</p>
  </div>
</ng-template>
