import {Injectable} from '@angular/core';
import {BerthVisit} from '@portbase/bezoekschip-service-typescriptmodels';
import moment from 'moment';
import {VisitContext} from '../../visit-context';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class OrderMovementService {

  context = VisitContext;

  constructor() {
  }

  orderMovement(berthVisit: BerthVisit) {
    berthVisit.nextMovement.order = true;
    this.triggerValidityCheck();
    gtag('event', this.context.visit.crn + '_' + moment().toISOString() + '_' + berthVisit.berth?.code + '_ordered', {
      'event_category': this.context.visit.crn,
      'event_label': 'ordered',
      'value': berthVisit.berth?.code
    });
  };

  cancelMovement(berthVisit: BerthVisit) {
    berthVisit.nextMovement.order = false;
    this.triggerValidityCheck();
    gtag('event', this.context.visit.crn + '_' + moment().toISOString() + '_' + berthVisit.berth?.code + '_cancelled', {
      'event_category': this.context.visit.crn,
      'event_label': 'canceled',
      'value': berthVisit.berth?.code
    });
  };

  private triggerValidityCheck = () => {
    let event;
    if (typeof (Event) === 'function') {
      event = new Event('change');
    } else {
      event = document.createEvent('Event');
      event.initEvent('change', true, true);
    }
    // setTimeout(() => this.elementRef.nativeElement.dispatchEvent(event, 0));
  };
}
