import {PortOfCall, Role, StormPilotage, UserProfile} from '@portbase/bezoekschip-service-typescriptmodels';
import {Alert, AlertLevel} from './common/status-alert/alert';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {VisitContext} from './visit-details/visit-context';
import * as Sentry from '@sentry/browser';
import {RouteData} from "./route-data";
import lodash from "lodash";
import newCargoScreenSettings from "./visit-details/new-cargo-screen-settings.json";

export class AppContext {

  static userProfile: UserProfile;
  static alerts: Alert[] = [];
  static stormPilotageRotterdam: StormPilotage;
  static stormPilotageAmsterdam: StormPilotage;
  static pendingProcesses: any[] = [];
  static environment: string = AppContext.detectEnvironment();
  static version: string;
  static wasteOnly: boolean;
  static supportPage = 'https://support.portbase.com';
  static supportPageForTask: string = AppContext.supportPage;
  static supportPageTooltip: string = 'Portbase services';

  static isSystemlink: boolean;
  static isNewRoute: boolean;
  static routeData: RouteData;

  static useNewLookAndFeel = (): boolean => {
    const useNewLookAndFeel = localStorage.getItem("useNewLookAndFeel");
    return useNewLookAndFeel === "true" || lodash.isNull(useNewLookAndFeel) || lodash.isUndefined(useNewLookAndFeel);
  }

  static showNewLookAndFeelSwitch = (): boolean => {
    const environmentSettings: { [key: string]: string[] } = newCargoScreenSettings[AppContext.environment] || {};
    return environmentSettings[AppContext.userProfile?.organisation?.shortName] == null
  }

  static ics2ActivatedForOrganisationOrItsCrn = (shortName, crn): boolean => {
    const environmentSettings: { [key: string]: string[] } = newCargoScreenSettings[AppContext.environment] || {};
    const organisationSettings = environmentSettings[shortName];
    return organisationSettings == null ? false : organisationSettings.length == 0 ? true : organisationSettings.indexOf(crn) > -1;
  }

  static navigateWithHistory(router: Router, url: string) {
    router.navigate([url], {queryParams: {eventId: VisitContext.eventId}});
  }

  static isDouaneHaven(portOfCall: PortOfCall) {
    return portOfCall.swDeclarationRequired && !portOfCall.paDeclarationRequired;
  }

  static isAdmin() {
    return AppContext.userProfile.roles.indexOf(Role.VisitPortProcessAdministrator) > -1;
  }

  static isCustoms() {
    return AppContext.userProfile.organisation?.shortName === 'DOUAAPELD';
  }

  static isAdminOrCustoms() {
    return AppContext.isCustoms() || AppContext.isAdmin();
  }

  static isWasteCollector() {
    return AppContext.userProfile.roles.indexOf(Role.PortVisitWasteCollector) > -1;
  }

  static isBunkerOperator() {
    return AppContext.userProfile.roles.indexOf(Role.BunkerOperator) > -1;
  }

  static isTerminalPlanner(): boolean {
    return this.hasRole(Role.TerminalPlanner);
  }

  static isPortAuthority() {
    return AppContext.userProfile.roles.indexOf(Role.PortAuthority) > -1;
  }

  static isCargoTerminal() {
    return AppContext.userProfile.roles.indexOf(Role.ImportCargoDischargeListReceiver) > -1
      || AppContext.userProfile.roles.indexOf('sve.dischargelist.receiver.terminaloperator') > -1;
  }

  static isTerminalOperatorViewer() {
    return AppContext.userProfile.roles.indexOf('sve.ecs.trackandtrace.terminaloperator.view') > -1;
  }

  static isCargoDeclarant() {
    return AppContext.userProfile.roles.indexOf(Role.DangerousGoodsDeclarantCargoHandlingAgent) > -1
      || this.isCargoImportViewer();
  }

  static isDangerousGoodsReporter() {
    return AppContext.userProfile.roles.indexOf(Role.DangerousGoodsReporterShipOperator) > -1;
  }

  static isVisitDeclarant() {
    return AppContext.userProfile.roles.indexOf(Role.VisitDeclarant) > -1;
  }

  static isCargoImportEditor() {
    return AppContext.userProfile.roles.indexOf('eri.em.cargo.declarant.cargohandlingagent.cargo') > -1;
  }

  static isCargoImportViewer() {
    return this.isCargoImportEditor() ||
      AppContext.userProfile.roles.indexOf('eri.em.cargo.declarant.cargohandlingagent.cargo.view') > -1;
  }

  static isDischargeListSender() {
    return AppContext.userProfile.roles.indexOf(Role.ImportCargoDischargeListSender) > -1
      || AppContext.userProfile.roles.indexOf('sve.dischargelist.reporter.cargohandlingagent') > -1;
  }

  static isInspectionHandler() {
    return AppContext.userProfile.roles.indexOf(Role.IPHandler) > -1;
  }

  static isInspectionViewer() {
    return AppContext.userProfile.roles.indexOf(Role.IPViewer) > -1;
  }

  static isTranshipmentDeclarant() {
    return AppContext.userProfile.roles.indexOf(Role.TranshipmentDeclarant) > -1;
  }

  static isTransitViewer() {
    return AppContext.isAdminOrCustoms() || this.isTransitEditor() ||
      this.hasRole("eri.ncts.transitdeclaration.declarant.cargohandlingagent.overview.view") ||
      this.hasRole("eri.ncts.transitdeclaration.declarant.forwarder.overview.view") ||
      this.hasRole("eri.ncts.transitdeclaration.declarant.backoffice.overview.view");
  }

  static isTransitEditor() {
    return this.hasRole("eri.ncts.transitdeclaration.declarant.cargohandlingagent.overview.edit") ||
      this.hasRole("eri.ncts.transitdeclaration.declarant.forwarder.overview.edit") ||
      this.hasRole("eri.ncts.transitdeclaration.declarant.backoffice.overview.edit");
  }

  static isTransitGuaranteeEditor() {
    return AppContext.userProfile.roles.indexOf('eri.npcs.external.vesselnotification2.transitguaranteedeclarant') > -1;
  }

  static isHinterlandTerminal() {
    return AppContext.userProfile.roles.indexOf(Role.HinterlandTerminal) > -1;
  }

  static isCommercialReleaseDeclarant() {
    return AppContext.hasRole(Role.CommercialReleaseDeclarant);
  }

  static isCommercialReleaseEditor() {
    return AppContext.hasRole(Role.CommercialReleaseEditor);
  }

  static isCommercialReleaseViewer() {
    return AppContext.hasRole(Role.CommercialReleaseViewer);
  }

  static isProduction() {
    return this.environment === 'pcs';
  }

  static isServiceDesk() {
    return AppContext.userProfile?.userName?.toLowerCase().indexOf('sd@') === 0;
  }

  static isOrganisationAdmin() {
    return AppContext.userProfile.roles.indexOf("sve.platform.management.accountmanagement.orgadmin") > -1
      || this.isServiceDesk();
  }

  static isClearancesActive() {
    return true;
  }

  static isControllingAtaAtdByPortAuthorityActive() {
    return this.isProduction();
  }

  static showFirstLine() {
    return true;
  }

  static showPortCallOptimization() {
    return true;
  }

  static hasRole(role: string) {
    return this.isAdmin() || AppContext.userProfile.roles.indexOf(role) > -1;
  }

  // This code is a replacement of the code that did a negative check on the role WasteCollector.
  // Probably too many roles are included.
  // So, if you are certain that a role does not have to be included, please remove it.
  static isAllowedToViewVisits() {
    return this.isAdmin() ||
      this.isCustoms() ||
      this.isTerminalPlanner() ||
      this.isPortAuthority() ||
      this.isCargoTerminal() ||
      this.isTerminalOperatorViewer() ||
      this.isCargoDeclarant() ||
      this.isDangerousGoodsReporter() ||
      this.isVisitDeclarant() ||
      this.isCargoImportEditor() ||
      this.isCargoImportViewer() ||
      this.isDischargeListSender() ||
      this.isInspectionHandler() ||
      this.isInspectionViewer() ||
      AppContext.hasRole(Role.PaxDeclarant) ||
      AppContext.hasRole(Role.ShipSuppliesDeclarant) ||
      AppContext.hasRole(Role.Government) ||
      AppContext.hasRole(Role.CargoDeclarant) ||
      AppContext.hasRole(Role.CargoDataViewer) ||
      AppContext.hasRole(Role.VisitReader) ||
      AppContext.hasRole(Role.ImportCargoDeclarant) ||
      AppContext.hasRole(Role.DangerousGoodsDeclarantCargoHandlingAgent) ||
      AppContext.hasRole(Role.ImportCargoProcessAdministrator) ||
      AppContext.hasRole(Role.TerminalPlanner) ||
      AppContext.hasRole(Role.VisitPortProcessAdministrator)
  }

  static waitForProcess = (process: Observable<any>): Observable<any> => {
    AppContext.pendingProcesses.push(process);
    const removeProcess = () => AppContext.pendingProcesses.splice(AppContext.pendingProcesses.indexOf(process), 1);
    return process.pipe(tap(removeProcess, removeProcess));
  };

  static registerError(error: any, level: AlertLevel = 'danger'): Alert {
    this.alerts = this.alerts.filter(a => a.level === level);
    if (error instanceof HttpErrorResponse) {
      if (String(error.status).startsWith('4') && (error.error.fullPathError || error.error.error)) {
        error = error.error.fullPathError || error.error.error;
        const splitErrors = (<string>error).split('\n');
        if (splitErrors.length > 1) {
          splitErrors.forEach(e => this.registerError(e));
          return;
        }
      } else {
        const errorId = Sentry.captureException(error);
        error = 'An unexpected error occurred (' + error.status
          + '). Please contact Portbase Customer Service and mention error id: ' + errorId;
      }
    } else if (error instanceof Error) {
      error = error.message;
    } else if (typeof error !== 'string') {
      error = JSON.stringify(error);
    }
    const alert = <Alert>{content: error, level: level, type: 'visit'};
    this.addAlert(alert);
    return alert;
  }

  static hasErrors() {
    return this.alerts.filter(a => a.level === 'danger' && a.type === 'visit').length > 0;
  }

  static registerSuccess(success: string, msShowTime?: number) {
    const alert = <Alert>{content: success, level: 'success', msShowTime: msShowTime == null ? 2000 : msShowTime};
    this.addAlert(alert);
  }

  static addAlert(alert: Alert) {
    if (this.alerts.filter(value => value.content === alert.content).length === 0) {
      this.alerts.push(alert);
    }
  }

  static closeAlerts(...alerts: Alert[]) {
    alerts.forEach(alert => {
      if (AppContext.alerts.indexOf(alert) >= 0) {
        AppContext.alerts.splice(AppContext.alerts.indexOf(alert), 1);
      }
    });
  }

  static clearVisitAlerts() {
    this.alerts.filter(value => value.type === "visit").forEach(alert => {
      this.closeAlerts(alert);
    });
  }

  static clearAlerts() {
    this.alerts = [];
  }

  static getUrlPath() {
    return window.location.pathname;
  }

  static dangerousGoodsContextActive() {
    return window.location.pathname.indexOf('dangerousGoods') > 0;
  }

  static setSupportPageForTask() {
    this.supportPageTooltip = 'Portbase services';
    this.supportPageForTask = this.supportPage;

    let urlPath = this.getUrlPath();
    if (urlPath.indexOf('/waste') > -1) {
      this.toSupportPage('/services/melding-afvalstoffen', 'waste collection declaration');
      return;
    }
    let task = this.detectTask(urlPath);
    switch (task) {
      case 'visit':
        this.toSupportPage('/services/melding-schip', 'visit declaration');
        return;
      case 'shipStores':
        this.toSupportPage('/services/melding-scheepsvoorraden', 'ship stores declaration');
        return;
      case 'cargoImport':
        this.toSupportPage('/services/melding-lading-import', 'cargo import declaration');
        return;
      case 'dangerousGoods':
        this.toSupportPage('/services/melding-gevaarlijke-stoffen', 'dangerous goods declaration');
        return;
      case 'passengers':
        this.toSupportPage('/services/melding-bemanning-passagiers', 'crew and passengers declaration');
        return;
    }
  }

  private static toSupportPage(servicePage: string, supportPageTooltip: string) {
    this.supportPageForTask = this.supportPage.concat(servicePage);
    this.supportPageTooltip = supportPageTooltip;
  }

  private static detectTask(urlPath: string): string {
    const match = urlPath.match(/^\/details\/\w+\/(\w+)\/?$/);
    return match ? match[1] : '';
  }

  private static detectEnvironment(): string {
    const environmentMatch = window.location.href.match(/https:\/\/portvisit\.(.*)\.portbase\.com.*/);
    const localHostMatch = window.location.href.match(/http:\/\/(localhost)(.*)/);

    if (environmentMatch) {
      return environmentMatch[1].toLowerCase();
    } else if (localHostMatch) {
      return localHostMatch[1].toLocaleLowerCase();
    }
    return null;
  }

  static getDocument() {
    return document;
  }

  static isVlissingenOrTerneuzenVisit(portUnCode: string) {
    return ['NLVLI', 'NLTNZ'].includes(portUnCode);
  }

  static adminAllowedToUpdateAtaAtd(portUnloCode: string): boolean {
    return this.isAdmin() && !(this.ataAtdIsControlledByPortAuthorityOfPort(portUnloCode));
  }

  static ataAtdIsControlledByPortAuthorityOfPort(portUnloCode: string): boolean {
    return this.isControllingAtaAtdByPortAuthorityActive() && this.portsThatControlsAtaAtd().includes(portUnloCode);
  }

  static portsThatControlsAtaAtd(): string[] {
    return ['NLRTM', 'NLAMS'];
  }

  static cargoDeclarantUsesNewCargoScreen(cargoDeclarantShortName: string): boolean {
    const environmentSettings: { [key: string]: string[] } = newCargoScreenSettings[AppContext.environment] || {};
    const crns = environmentSettings[cargoDeclarantShortName];
    return crns != null && crns.length === 0;
  }
}
